import axiosInstance from "src/Modules/Core/Service/Api";
import api from "src/Modules/Core/Service/Api";
import { queryKey } from "@tanstack/react-query/build/lib/__tests__/utils";

export const CustomerApiQuery = {
  organization: {
    current: () => ({
      queryKey: ["organization", "current"],
      queryFn: () => axiosInstance.get("/api/organizations/v1/current"),
    }),
    details: {
      set: (values: object) =>
        axiosInstance.post("/api/organizations/v1/current/details", values),
      uploadLogo: (values: object) =>
        axiosInstance.post(
          "/api/organizations/v1/current/details/logo",
          values,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        ),
      removeLogo: (values: object) =>
        axiosInstance.delete(
          "/api/organizations/v1/current/details/logo",
          values
        ),
    },
    id: (organizationId) => ({
      get: () => ({
        queryKey: ["organizations", "details", organizationId],
        queryFn: () =>
          axiosInstance.get(`/api/organizations/v1/${organizationId}`),
      }),
    }),
  },
  memberships: {
    invite: (values) =>
      axiosInstance.post("/api/organizations/v1/current/members", values),
    remove: (memberId) =>
      axiosInstance.delete(`/api/organizations/v1/current/members/${memberId}`),
    index: () => ({
      queryKey: ["organization", "current", "members"],
      queryFn: () => axiosInstance.get(`/api/organizations/v1/current/members`),
    }),
  },
  survey: {
    get: () => ({
      queryKey: ["organization", "current", "signup-survey"],
      queryFn: () =>
        axiosInstance.get("/api/organizations/v1/current/signup-survey"),
    }),
    next: (values: object) =>
      axiosInstance.post(
        "/api/organizations/v1/current/signup-survey/next",
        values
      ),
    back: (values: object) =>
      axiosInstance.post(
        "/api/organizations/v1/current/signup-survey/back",
        values
      ),
  },
  jobs: {
    list: () => ({
      queryKey: ["jobs", "list"],
      queryFn: () => axiosInstance.get("/api/organizations/v1/current/jobs"),
    }),
    get: (id: string) => ({
      queryKey: ["jobs", "details", id],
      queryFn: () => axiosInstance.get(`/api/matching/v1/jobs/${id}`),
    }),
    id: (id: string) => ({
      update: (values: object) =>
        axiosInstance.post(`/api/matching/v1/jobs/${id}`, values),
      get: () => ({
        queryKey: ["jobs", "details", id],
        queryFn: () =>
          axiosInstance.get(`/api/organizations/v1/current/jobs/${id}`),
      }),
      updateStatus: (values) =>
        axiosInstance.put(`/api/matching/v1/jobs/${id}/status`, values),
      shortlist: () => ({
        queryKey: ["jobs", "details", id, "shortlist"],
        queryFn: () =>
          axiosInstance.get(
            `/api/organizations/v1/current/jobs/${id}/shortlist`
          ),
      }),
      post: (values) =>
        axiosInstance.post(`/api/matching/v1/jobs/${id}/post`, values),
      duplicate: () =>
        axiosInstance.post(`/api/matching/v1/jobs/${id}/duplicate`),
      delete: () => axiosInstance.delete(`/api/matching/v1/jobs/${id}`),
    }),
    listActive: () => ({
      queryKey: ["jobs", "list", "active"],
      queryFn: () => axiosInstance.get("/api/organizations/v1/current/jobs"),
    }),
    create: () => axiosInstance.post("/api/organizations/v1/current/jobs"),
  },
  candidates: {
    id: (candidateId) => ({
      action: (values) =>
        axiosInstance.post(
          `/api/matching/v1/shortlisted-candidates/${candidateId}/action`,
          values
        ),
    }),
  },
  calendlyLink: () => ({
    queryFn: () =>
      axiosInstance.get(`/api/matching/v1/shortlisted-candidates/calendlyLink`),
    queryKey: ["special", "calendlyLink"],
  }),
};

export const CustomerApi = CustomerApiQuery;
