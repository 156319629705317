import React from "react";
import {
  sidebarLabelStyle,
  sidebarLinkActiveSign,
  sidebarLinkContainerStyle,
  sidebarLinkIconStyle,
} from "./sidebar.css";
import { NavLink } from "react-router-dom";

interface SidebarNavLinkProps {
  label: string;
  icon?: React.ReactElement;
  to: string;
  locked?: boolean;
  soon?: boolean;
  out?: boolean;
  target?: string;
}

const SidebarNavLink: React.FC<SidebarNavLinkProps> = (props) => {
  return (
    <NavLink
      to={props.to}
      className={sidebarLinkContainerStyle}
      end={true}
      target={props.target}
    >
      <div className={sidebarLinkActiveSign} />
      <div className={sidebarLinkIconStyle}>{props.icon}</div>
      <span className={sidebarLabelStyle}>{props.label}</span>
    </NavLink>
  );
};

export default SidebarNavLink;
