import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div<CompanyLogoProps>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 6px;
  contain: paint;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #ededf0;

  svg {
  }

  img {
    width: 100%;
    height: auto;
  }
`;

type CompanyLogoProps = {
  src?: string;
  size: number;
};

const CompanyLogo: React.FC<CompanyLogoProps> = (props) => {
  let [loading, setLoading] = useState(true);
  let [found, setFound] = useState(false);
  let [image, setImage] = useState<HTMLImageElement | null>(null);
  let [debounce, setDebounce] = useState<any>(null);
  useEffect(() => {
    if (props.src == undefined) {
      return;
    }

    if (debounce != undefined) {
      window.clearTimeout(debounce);
    }

    if (props.src !== image?.src) {
      setLoading(true);
    }

    let timeoutHandler = window.setTimeout(() => {
      if (props.src == undefined) {
        return;
      }

      let image = new Image();

      image.onload = () => {
        setLoading(false);
        setFound(true);
        setImage(image);
      };

      image.onerror = () => {
        setLoading(false);
        setFound(false);
      };

      image.src = props.src;

      setImage(image);
    }, 800);

    setDebounce(timeoutHandler);

    return () => window.clearTimeout(debounce);
  }, [props.src]);

  if (loading) {
    return <Container {...props} />;
  }

  if (!found) {
    return (
      <Container {...props}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="current"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.50001 15.8334V4.75006C2.49996 4.57899 2.55255 4.41206 2.65065 4.27192C2.74875 4.13178 2.8876 4.02523 3.04834 3.96672L11.1075 1.03672C11.1705 1.01381 11.238 1.00642 11.3044 1.01517C11.3709 1.02392 11.4342 1.04855 11.4891 1.08699C11.5439 1.12542 11.5887 1.17652 11.6196 1.23595C11.6506 1.29538 11.6667 1.3614 11.6667 1.42839V5.55589L16.93 7.31006C17.096 7.36535 17.2404 7.47149 17.3427 7.61343C17.445 7.75537 17.5001 7.92592 17.5 8.10089V15.8334H19.1667V17.5001H0.833344V15.8334H2.50001ZM4.16668 15.8334H10V3.21256L4.16668 5.33422V15.8334ZM15.8333 15.8334V8.70172L11.6667 7.31255V15.8334H15.8333Z"
            fill="#B8BCC7"
          />
        </svg>
      </Container>
    );
  }

  if (!found) {
    return <pre style={{ color: "red" }}>Error</pre>;
  }

  return (
    <Container {...props}>
      <img src={image?.src} />
    </Container>
  );
};

export default CompanyLogo;
