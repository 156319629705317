import React, { useState } from "react";
import styled from "styled-components";
import { Field, Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import axios from "axios";
import {
  FormLabel,
  PrimaryButtonWide,
} from "src/Modules/Shared/Components/Temp/ApplicationFlowComponents";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import LinksFillIcon from "remixicon-react/LinksFillIcon";
import AddFillIcon from "remixicon-react/AddFillIcon";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";
import CompanyDetailsEditCard from "src/Modules/Customer/Components/CompanyDetailsEditCard";
import PopupOverlay from "src/Modules/Core/Components/Experimental/PopupOverlay";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ca } from "date-fns/locale";
import { CustomerApi } from "src/Modules/Customer/Services/CustomerApi";

const Container = styled.div``;

const CardTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.04em;
  color: #1c274a;
`;

const CardButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  margin-top: 16px;
`;

const CardContent = styled.div`
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const CardInfoText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const Profile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  img {
    width: 30px;
    aspect-ratio: 1;
    border-radius: 999px;
  }

  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.03em;
    color: #1c274a;
  }
`;

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 200;
  width: 100vw;
  height: 100dvh;
  top: 0;
  left: 0;
  padding: 60px 8px;
  overflow-y: auto;
`;

const Card = styled.div`
  max-width: 544px;
  margin: 0 12px;
  padding: 24px;
  background: white;
  border-radius: 12px;
  position: relative;
  gap: 12px;
`;

const AddCalendarLink = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
`;

const CalendarEditContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  gap: 8px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const CalendarSaveButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  gap: 8px;

  width: 64px;
  height: 48px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: -0.03em;

  /* Notch/Primary/Blue/600 */

  color: #105cf7;

  background: white;

  border-radius: 6px;

  flex: none;
  order: 1;
  flex-grow: 0;
`;

const CalendarViewContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;

const CalendarValue = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px 4px 12px;
  gap: 4px;
  background: #f8faff;
  border: 1px solid #e6eefe;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;

  height: fit-content;
  text-wrap: wrap;
  line-break: anywhere;
`;

const CalendarChangeButton = styled.button`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;

  letter-spacing: -0.03em;
  background: white;
  color: #9fa4b3;

  outline: none;
  border: none;

  &:hover {
    border: none;
    outline: none;
  }
`;

const CandidateActionScheduleInterview: React.FC = (props) => {
  const [schedulePopup, setSchedulePopup] = useState(false);
  const [calendarLink, setCalendarLink] = useState(false);
  const [calendarEdit, setCalendarEdit] = useState(false);
  const queryClient = useQueryClient();
  let data = props.data;

  let calendlyLinkQuery = useQuery(CustomerApi.calendlyLink());

  let actionMutation = useMutation({
    mutationFn: CustomerApi.candidates.id(data.id).action,
    onSuccess: () => {
      queryClient.invalidateQueries(["jobs"]);
      setSchedulePopup(false);
    },
  });

  if (calendlyLinkQuery.isLoading) {
    return null;
  }

  let calendlyLinkPreset = calendlyLinkQuery.data?.data.calendlyLink;

  return (
    <>
      <PrimaryButtonWide
        disabled={props.disabled}
        onClick={() => setSchedulePopup(true)}
      >
        Schedule interview
      </PrimaryButtonWide>
      <PopupOverlay
        active={schedulePopup}
        onClose={() => setSchedulePopup(false)}
      >
        {({ onClose }) => (
          <Card>
            <Form
              mutators={{ ...arrayMutators }}
              onSubmit={(values) => {
                values.action = "Schedule";
                actionMutation.mutate(values);
              }}
              initialValues={{ calendlyLink: calendlyLinkPreset }}
              render={({ handleSubmit, values, form }) => (
                <form onSubmit={handleSubmit}>
                  <CardContent>
                    <CardTitle>Schedule Interview</CardTitle>
                    <Profile>
                      <img src={data.legacyProfile.profileBase} />
                      <span>{data.overwrites.Name}</span>
                    </Profile>

                    <CardInfoText>
                      We will coordinate and schedule the call with your team
                      and the developer. Please share your calendar link to
                      schedule faster.
                    </CardInfoText>

                    {calendarEdit ? (
                      <CalendarEditContainer>
                        <FieldContainer>
                          <FormLabel>Enter your Calendar URL</FormLabel>
                          <Field
                            name="calendlyLink"
                            placeholder="Ex: calendly.com/company/30mins"
                            component={TextInputField}
                          />
                        </FieldContainer>
                        <CalendarSaveButton
                          onClick={() => setCalendarEdit(false)}
                        >
                          Save
                        </CalendarSaveButton>
                      </CalendarEditContainer>
                    ) : (
                      <>
                        {values.calendlyLink ? (
                          <>
                            <CalendarViewContainer>
                              <CalendarValue>
                                <LinksFillIcon color={"#105cf7"} size={18} />
                                {values.calendlyLink}
                              </CalendarValue>
                              <CalendarChangeButton
                                onClick={() => setCalendarEdit(true)}
                              >
                                Change
                              </CalendarChangeButton>
                            </CalendarViewContainer>
                          </>
                        ) : (
                          <AddCalendarLink
                            onClick={() => {
                              setCalendarEdit(true);
                            }}
                          >
                            <AddFillIcon /> Add Calendar Link
                          </AddCalendarLink>
                        )}
                      </>
                    )}

                    <CardButtonGroup>
                      <Button
                        variant="outline"
                        size="fullwidth"
                        onClick={(event) => {
                          setSchedulePopup(false);
                          event.preventDefault();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button disabled={!values.calendlyLink} size="fullwidth">
                        Confirm
                      </Button>
                    </CardButtonGroup>
                  </CardContent>
                </form>
              )}
            />
          </Card>
        )}
      </PopupOverlay>
    </>
  );
};

export default CandidateActionScheduleInterview;
