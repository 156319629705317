import React, { useState } from "react";
import styled from "styled-components";
import {
  BackButton,
  FormCard,
  FormGroupDynamic,
  FormLabel,
  FormSubtitle,
  FormTitle,
  PrimaryButton,
  Spacer,
} from "src/Modules/Shared/Components/Temp/ApplicationFlowComponents";
import { Form, Field } from "react-final-form";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import SelectField from "src/Modules/Shared/Components/Temp/SelectField";
import countries from "src/Modules/Shared/Components/Temp/countries";
import BlockSelectField from "src/Modules/Shared/Components/Temp/BlockSelectField";
import axios from "axios";
import * as yup from "yup";
import JsonDump from "src/Modules/Shared/Utils/JsonDump";
import CustomerSignupCompanyField from "src/Modules/Shared/Components/Temp/CustomerSignupCompanyField";
import LocationInputField from "src/Modules/Shared/Components/Temp/LocationInputField";
import PhoneNumberField from "src/Modules/Shared/Components/Temp/PhoneNumberField";
import InformationFillIcon from "remixicon-react/InformationFillIcon";
import CompanySearchField from "src/Modules/Shared/Components/Temp/CompanySearchField";
const Container = styled.div``;

const HalfSizeWidth = 286;

const ArrowLeftIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 20px;
  height: 20px;
`;

const ArrowRightIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  width: 20px;
  height: 20px;
`;

const ButtonGroup = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const CustomCompanyFormContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 24px;

  border: 1px solid #ededf0;
  border-radius: 6px;
  width: 100%;
`;

const CustomCompanyFormSave = styled.button`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;
  margin-left: auto;
  background: none;
`;

const ButtonGroupSpace = styled.div`
  width: auto;
  height: 48px;
  margin-left: auto;
`;

const AThirdSize = "182px";

type Props = {
  setStep: Function;
};

const formSchema = yup.object({
  company_name: yup.string().required(),
  company_website: yup.string(),
  companySize: yup.string().required(),
  hqLocation: yup.object().required(),
});

const HoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;
`;

const HoverText = styled.div`
  width: 260px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.03em;

  color: #ffffff;
  padding: 8px 12px;
  background: #1c274a;
  border-radius: 6px;

  bottom: 28px;
  position: absolute;
`;

const ApplicationFlowStepCompanyDetails: React.FC<Props> = (props) => {
  let [customCompanyForm, setCustomCompanyForm] = useState(false);
  let [info, setInfo] = useState(false);

  return (
    <Container>
      <Form
        onSubmit={(values) => {
          axios
            .post("/api/customers/v1/application-flow", values)
            .then((response) => {
              props.setData(response.data);
            });
        }}
        initialValues={props.data}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit}>
            <FormTitle>Company Details</FormTitle>
            <FormSubtitle>
              Provide a few more basic details about your company to start
              matching candidates.
            </FormSubtitle>
            <FormCard>
              {customCompanyForm && (
                <CustomCompanyFormContainer>
                  <FormGroupDynamic>
                    <FormLabel>Company Name</FormLabel>
                    <Field
                      name={"company_name"}
                      component={TextInputField}
                      placeholder="Acme, Inc."
                    />
                  </FormGroupDynamic>

                  <FormGroupDynamic>
                    <FormLabel>Company Website</FormLabel>
                    <Field
                      name={"company_website"}
                      component={TextInputField}
                      placeholder="yourcompany.com"
                    />
                  </FormGroupDynamic>

                  <CustomCompanyFormSave
                    onClick={() => {
                      form.change("company_logo", null);
                      setCustomCompanyForm(false);
                    }}
                  >
                    Save
                  </CustomCompanyFormSave>
                </CustomCompanyFormContainer>
              )}

              {!customCompanyForm && (
                <FormGroupDynamic>
                  <FormLabel>Company Name</FormLabel>
                  <Field
                    name="company_autocomplete"
                    setCustomCompanyForm={(value) =>
                      setCustomCompanyForm(value)
                    }
                    customValue={{
                      label: values.company_name,
                      value: values.company_name,
                      logo:
                        values.company_logo ??
                        "https://logo.clearbit.com/" + values.company_website,
                      domain: values.company_website,
                    }}
                    form={form}
                    component={CompanySearchField}
                  />
                </FormGroupDynamic>
              )}

              <FormGroupDynamic width={650}>
                <FormLabel>Company Size</FormLabel>
                <Field
                  multiple={false}
                  name={"companySize"}
                  component={BlockSelectField}
                  options={[
                    {
                      value: "1 - 10",
                      label: "1-10 employees",
                      width: AThirdSize,
                    },
                    {
                      value: "11 - 50",
                      label: "11-50 employees",
                      width: AThirdSize,
                    },
                    {
                      value: "51 - 200",
                      label: "51-200 employees",
                      width: AThirdSize,
                    },
                    {
                      value: "201 - 1000",
                      label: "201-1000 employees",
                      width: "280px",
                    },
                    {
                      value: "1000+",
                      label: "1000+ employees",
                      width: "280px",
                    },
                  ]}
                ></Field>
              </FormGroupDynamic>

              <FormGroupDynamic width={HalfSizeWidth * 2}>
                <FormLabel>HQ Location</FormLabel>
                <Field
                  name={"hqLocation"}
                  component={LocationInputField}
                  options={countries}
                />
              </FormGroupDynamic>

              {/*
              <FormGroupDynamic width={HalfSizeWidth * 2}>
                <FormLabel>Phone Number<span className="optional">(optional)</span></FormLabel>
                <Field name={'phone_number'} component={TextInputField}  placeholder="+1 555 555 55 55"/>
              </FormGroupDynamic>
                        */}

              <FormGroupDynamic>
                <FormLabel>
                  <span>Phone number</span>
                  <HoverContainer
                    onClick={() => setInfo(!info)}
                    onMouseEnter={() => setInfo(true)}
                    onMouseLeave={() => setInfo(false)}
                  >
                    {info && (
                      <HoverText>
                        Sharing your phone number helps us connect with you
                        faster about candidate updates. We'll never share your
                        information
                      </HoverText>
                    )}
                    <InformationFillIcon size={18.7} color={"#D2D4DB"} />
                  </HoverContainer>
                </FormLabel>
                <Field name="phone_number" component={PhoneNumberField} />
              </FormGroupDynamic>
            </FormCard>
            <div style={{ height: 24 }} />
            <FormCard>
              <FormGroupDynamic>
                <FormLabel>How did you find out about Notch</FormLabel>

                <div style={{ width: 300 }}>
                  <Field
                    name={"heardFrom"}
                    options={[
                      { value: "customer", label: "Customer / Company" },
                      { value: "talent", label: "Talent / Community" },
                      { value: "referred", label: "Referred / Recommended" },
                      { value: "email", label: "Email invite" },
                      { value: "partner", label: "Notch partner" },
                      { value: "google", label: "Google" },
                      { value: "facebook", label: "Facebook" },
                      { value: "twitter", label: "Twitter" },
                      { value: "linkedin", label: "Linkedin" },
                      { value: "friend", label: "Friend / Word of mouth" },
                      {
                        value: "none",
                        label: "I hadn't heard of Notch before",
                      },
                      { value: "other", label: "Other" },
                    ]}
                    component={SelectField}
                  />
                </div>
              </FormGroupDynamic>

              {values.heardFrom?.value === "referred" && (
                <FormGroupDynamic>
                  <FormLabel>Referrer</FormLabel>
                  <div style={{ width: 300 }}>
                    <Field name="heardFromExtra" component={TextInputField} />
                  </div>
                </FormGroupDynamic>
              )}

              {values.heardFrom?.value === "partner" && (
                <FormGroupDynamic>
                  <FormLabel>Partner</FormLabel>
                  <div style={{ width: 300 }}>
                    <Field name="heardFromExtra" component={TextInputField} />
                  </div>
                </FormGroupDynamic>
              )}

              {values.heardFrom?.value === "other" && (
                <FormGroupDynamic>
                  <FormLabel>Other</FormLabel>
                  <div style={{ width: 300 }}>
                    <Field name="heardFromExtra" component={TextInputField} />
                  </div>
                </FormGroupDynamic>
              )}
            </FormCard>
            <ButtonGroup>
              <BackButton
                onClick={(event) => {
                  axios
                    .post("/api/customers/v1/application-flow-back", values)
                    .then((response) => {
                      props.setData(response.data);
                    });
                  event.preventDefault();
                }}
              >
                <ArrowLeftIcon>
                  <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.02331 6.16664H14.1666V7.83331H4.02331L8.49331 12.3033L7.31498 13.4816L0.833313 6.99998L7.31498 0.518311L8.49331 1.69664L4.02331 6.16664Z"
                      fill="#105CF7"
                    />
                  </svg>
                </ArrowLeftIcon>
                Back
              </BackButton>
              <ButtonGroupSpace />
              <PrimaryButton
                type="submit"
                disabled={!formSchema.isValidSync(values)}
              >
                Submit{" "}
              </PrimaryButton>
            </ButtonGroup>
          </form>
        )}
      />
    </Container>
  );
};

export default ApplicationFlowStepCompanyDetails;
