import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FieldRenderProps } from "react-final-form";
import skillOptions from "src/Modules/Shared/Components/Temp/skillOptions";
import Select from "react-select";
import JsonDump from "src/Modules/Shared/Utils/JsonDump";
import { fi } from "date-fns/locale";

const stylesOption = {
  container: (provided: any, state: any) => ({
    ...provided,
    width: "100%",
    height: 48,
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    color: "red",
    display: "none",
  }),
  clearIndicator: (provided: any, state: any) => ({
    ...provided,
    display: state.isMulti ? "none" : "default",
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    margin: 0,
    top: 60,
    border: "1px solid #eceff6",
    borderTop: "none",
    zIndex: 1000,
    maxHeight: 100,

    background: "#FFFFFF",
    boxShadow: "0px 2px 16px rgba(28, 39, 74, 0.06)",
    borderRadius: 6,
  }),
  menuPortal: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    margin: 0,
    top: 48,
    zIndex: 1,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: 48,
    fontFamily: "Inter",
    paddingRight: 12,
    margin: 0,
    color: "#1C274A",
    border: state.isFocused ? "#105cf7 1px solid" : "1px solid #EDEDF0",
    "&:hover": {
      borderColor: "#7aa0ff 1px solid",
    },
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    fontSize: 14,
    color: state.isDisabled ? "#999" : "#1C274A",
    letterSpacing: "0",
    fontStyle: "normal",
    fontFamily: "Inter",
    fontWeight: 400,
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    borderRadius: 6,
    padding: "2px 8px",
    backgroundColor: "#F4FBFD",
    height: 24,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  }),
  multiValueRemove: (provided: any, state: any) => ({
    ...provided,
    color: "#1D7C93",
    backgroundColor: "none",
    width: 18,
    height: 18,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      width: 18,
      height: 18,
    },
    "&:hover": {
      backgroundColor: "transparent",
      color: "pink",
      cursor: "pointer",
    },
  }),
  multiValueLabel: (provided: any, state: any) => ({
    ...provided,
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "Inter",
    color: "#1D7C93",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: 20,
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontSize: 14,
    height: 20,
    fontFamily: "Inter",
    fontWeight: 400,
    marginLeft: 0,
    color: "#858B9D",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: 12,
    fontWeight: 500,
    display: "flex",
    alignItems: "center",

    height: 48,
    backgroundColor: state.isSelected
      ? "#F8FAFF"
      : state.isFocused
      ? "#F8FAFF"
      : "white",
    color: state.isSelected
      ? "#1C274A"
      : state.isFocused
      ? "#1C274A"
      : "#1C274A",
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
  }),
};
const ContainerSkillField = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 660px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
    height: auto;
    min-height: 48px;
  }
`;

const CustomDropdownIndicator = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 12.5001L6.46417 8.96422L7.64334 7.78589L10 10.1434L12.3567 7.78589L13.5358 8.96422L10 12.5001Z"
        fill="#9FA4B3"
      />
    </svg>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (min-width: 660px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    height: auto;
    min-height: 48px;
  }
`;

const ItemContainer = styled.div`
  padding: 12px;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #ededf0;
  border-radius: 6px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 100%;

  @media screen and (min-width: 660px) {
    padding: 12px;
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #ededf0;
    border-radius: 6px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: auto;
  }

  &.active {
    border: 1px solid #105cf7;
  }

  &:hover {
    border: 1px solid #105cf7;
  }
`;

const ContainerFixed = styled(Container)`
  ${ItemContainer} {
    margin-bottom: 12px;
  }

  @media screen and (min-width: 660px) {
    ${ItemContainer} {
      margin-right: 12px;
    }
  }
`;

const Icon = styled.div`
  width: 20px;
  height: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

const Label = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: #1c274a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BlockFieldItem = styled.div`
  padding: 12px;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #ededf0;
  border-radius: 6px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  text-align: left;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  letter-spacing: -0.03em;
  color: #1c274a;
  padding-left: 35px;

  @media screen and (min-width: 660px) {
    padding: 12px;

    cursor: pointer;
    background: #ffffff;
    border: 1px solid #ededf0;
    border-radius: 6px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: ${(props: { size: any }) => props.size};
  }

  &.active {
    border: 1px solid #105cf7;
  }

  &:hover {
    border: 1px solid #105cf7;
  }
`;

const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 2px;
  margin-right: 12px;

  img {
    width: 20px;
    height: 20px;
  }
`;

type Item = { value: string; label: string };

type Props = FieldRenderProps<string[], any> & { spacing: "fixed" | "full" };

const isSelected = (currentItem: string, currentSelection: Set<string>) => {
  return currentSelection.has(currentItem);
};

const RoleToLogo = {
  fullstack: <></>,
  backend: <></>,
  frontend: <></>,
  devops: <></>,
  mobile: <></>,
  data: <></>,
};

const MultipleBlockSelectField: React.FC<Props> = (props) => {
  const [filteredOptions, setFilteredOptions] = useState<any>([]);

  useEffect(() => {
    if (filteredOptions.length != 0) {
      return;
    }

    const x = skillOptions.filter((i: any) => {
      const found = props.options.find((j: any) => {
        if (j.value == i.value) {
          return true;
        } else {
          return false;
        }
      });

      if (found == undefined) {
        return true;
      } else {
        return false;
      }
      return false;
    });
    setFilteredOptions(x);
  }, [props.options]);

  let Element = Container;
  if (props.spacing === "fixed") {
    Element = ContainerFixed;
  }

  let selectedOptions = [];

  const set = new Set(props.input.value);

  selectedOptions = filteredOptions.filter((i) => {
    if (set.has(i.value)) {
      return true;
    } else {
      return false;
    }
  });

  return (
    <Element>
      {props.options.map((Item: any) => {
        return (
          <BlockFieldItem
            key={Item.value}
            className={isSelected(Item.value, set) ? "active" : ""}
            onClick={() => {
              if (set.has(Item.value)) {
                set.delete(Item.value);
                props.input.onChange(Array.from(set));
              } else {
                set.add(Item.value);
                props.input.onChange(Array.from(set));
              }
            }}
            size={Item.width}
          >
            <IconContainer>
              <img src={`/images/skills/${Item.logoPath}`} />
            </IconContainer>
            <span>{Item.label}</span>
          </BlockFieldItem>
        );
      })}
      <ContainerSkillField>
        <Select
          components={{ DropdownIndicator: CustomDropdownIndicator }}
          styles={stylesOption}
          options={filteredOptions}
          value={selectedOptions}
          placeholder={"+ Search 100+ additional skills..."}
          isDisabled={false}
          isMulti={true}
          onChange={(newValue, actionMeta) => {
            const nv = newValue.map((i) => i.value ?? i);
            const s = new Set(nv);
            props.input.onChange(Array.from(s));
          }}
        />
      </ContainerSkillField>
    </Element>
  );
};

export default MultipleBlockSelectField;
