import React, { useState } from "react";
import styled from "styled-components";
import { FieldRenderProps } from "react-final-form";
import skillOptions from "src/Modules/Shared/Components/Temp/skillOptions";
import Select, { GroupBase } from "react-select";

const stylesOption = {
  container: (provided: any, state: any) => ({
    ...provided,
    width: "100%",
    height: 48,
  }),
  ///////
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    color: "red",
    display: "none",
  }),
  clearIndicator: (provided: any, state: any) => ({
    ...provided,
    display: state.isMulti ? "none" : "default",
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    margin: 0,
    top: 60,
    border: "1px solid #eceff6",
    borderTop: "none",
    zIndex: 1000,
    maxHeight: 100,

    background: "#FFFFFF",
    boxShadow: "0px 2px 16px rgba(28, 39, 74, 0.06)",
    borderRadius: 6,
  }),
  menuPortal: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    margin: 0,
    top: 48,
    zIndex: 1,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: 48,
    fontSize: 14,
    fontFamily: "Inter",
    paddingRight: 12,
    margin: 0,
    color: "#1C274A",
    border: state.isFocused ? "#105cf7 1px solid" : "1px solid #EDEDF0",
    "&:hover": {
      borderColor: "#7aa0ff 1px solid",
    },
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    fontSize: 14,
    color: state.isDisabled ? "#999" : "#1C274A",
    letterSpacing: "0",
    fontStyle: "normal",
    fontFamily: "Inter",
    fontWeight: 400,
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    fontSize: 10,
    color: "#ffffff",
    backgroundColor: "#136afb",
  }),
  multiValueLabel: (provided: any, state: any) => ({
    ...provided,
    fontSize: 10,
    color: "#ffffff",
    backgroundColor: "#136afb",
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontSize: 14,
    height: 20,
    marginLeft: 8,
    fontWeight: 400,
    color: "#858B9D",
    zIndex: 100,
    fontFamily: "Inter",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: 12,
    fontWeight: 500,
    display: "flex",
    alignItems: "center",

    height: 48,
    backgroundColor: state.isSelected
      ? "#F8FAFF"
      : state.isFocused
      ? "#F8FAFF"
      : "white",
    color: state.isSelected
      ? "#1C274A"
      : state.isFocused
      ? "#1C274A"
      : "#1C274A",
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
  }),
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 660px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
    height: auto;
    min-height: 48px;
  }
`;

const CustomDropdownIndicator = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 12.5001L6.46417 8.96422L7.64334 7.78589L10 10.1434L12.3567 7.78589L13.5358 8.96422L10 12.5001Z"
        fill="#9FA4B3"
      />
    </svg>
  );
};

const SkillSelectorContainer = styled.div`
  width: 100%;
  height: 48px;

  @media screen and (min-width: 660px) {
    width: 65%;
    height: 48px;
  }
`;

const YearSelectorContainer = styled.div`
  width: 100%;
  height: 48px;

  @media screen and (min-width: 660px) {
    width: 31%;
  }
`;

const Spacing = styled.div`
  width: 16px;
  height: 16px;
`;

const SkillList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  margin-top: 16px;
`;

const SkillItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px 8px;
  gap: 8px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #ededf0;
  border-radius: 6px;

  align-items: center;
  justify-content: center;

  margin-top: 8px;
  margin-right: 8px;
`;

const SkillName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: -0.03em;

  /* Notch/Primary/Blue/600 */

  color: #105cf7;
`;

const SkillYears = styled.div`
  width: 33px;
  height: 24px;

  background: #105cf7;
  border-radius: 999px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  letter-spacing: -0.03em;

  color: #ffffff;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SkillDelete = styled.div`
  width: 20px;
  height: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

type Props = FieldRenderProps<object[], any>;

const yearsOption = [
  { label: "1 year", value: 1 },
  { label: "2 years", value: 2 },
  { label: "3 years", value: 3 },
  { label: "4 years", value: 4 },
  { label: "5 years", value: 5 },
  { label: "6 years", value: 6 },
  { label: "7 years", value: 7 },
  { label: "8 years", value: 8 },
  { label: "9 years", value: 9 },
  { label: "10 years", value: 10 },
  { label: "11 years", value: 11 },
  { label: "12 years", value: 12 },
  { label: "13 years", value: 13 },
  { label: "14 years", value: 14 },
  { label: "15+ years", value: 15 },
];

const MainSkillsField: React.FC<Props> = (props) => {
  let [sSkill, setSskill] = useState<any | null>(null);
  let [sYear, setSyear] = useState<any | null>(null);
  let skills = props.input.value ?? [];
  let set = new Set(props.input.value);
  let x = skillOptions;

  return (
    <Container>
      <SkillSelectorContainer>
        <Select
          components={{ DropdownIndicator: CustomDropdownIndicator }}
          styles={stylesOption}
          options={x}
          value={sSkill}
          placeholder={"Type to add your skills"}
          isDisabled={set.size > 4}
          onChange={(v) => {
            setSskill(v);
          }}
        />
      </SkillSelectorContainer>
      <Spacing />
      <YearSelectorContainer>
        <Select
          components={{ DropdownIndicator: CustomDropdownIndicator }}
          styles={stylesOption}
          options={yearsOption}
          placeholder={"Select years"}
          isDisabled={sSkill == null}
          value={sYear}
          onChange={(v) => {
            set.add({ skill: sSkill.label, years: v.value });
            props.input.onChange(Array.from(set));
            setSskill("");
            setSyear(null);
          }}
        />
      </YearSelectorContainer>
      <SkillList>
        {Array.from(set).map((i: any) => {
          return (
            <SkillItem>
              <SkillName>{i.skill}</SkillName>{" "}
              <SkillYears>{i.years}y</SkillYears>
              <SkillDelete
                onClick={() => {
                  let a = Array.from(set);

                  let newArray = [];

                  newArray = a.filter((item: any) => {
                    return item.skill != i.skill;
                  });

                  props.input.onChange(newArray);
                }}
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.99999 4.82178L10.125 0.696777L11.3033 1.87511L7.17832 6.00011L11.3033 10.1251L10.125 11.3034L5.99999 7.17844L1.87499 11.3034L0.696655 10.1251L4.82166 6.00011L0.696655 1.87511L1.87499 0.696777L5.99999 4.82178Z"
                    fill="#B8BCC7"
                  />
                </svg>
              </SkillDelete>
            </SkillItem>
          );
        })}
      </SkillList>
    </Container>
  );
};

export default MainSkillsField;
