import {
  components,
  DropdownIndicatorProps,
  OptionProps,
  ValueContainerProps,
} from "react-select";
import React from "react";
import styled from "styled-components";

export const DropdownIndicator: React.FC<DropdownIndicatorProps> = (
  props: DropdownIndicatorProps
) => {
  props.selectProps.menuIsOpen;

  return (
    <components.DropdownIndicator {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.9997 12.4993L6.46387 8.96349L7.64303 7.78516L9.9997 10.1427L12.3564 7.78516L13.5355 8.96349L9.9997 12.4993Z"
          fill="#9FA4B3"
        />
      </svg>
    </components.DropdownIndicator>
  );
};

const OptionLabel = styled.div``;

const OptionSelectedCheckmark = styled.div`
  width: 20px;
  height: 20px;
  margin-left: auto;
`;

export const Option: React.FC<OptionProps> = (props: OptionProps) => {
  return (
    <components.Option {...props}>
      <OptionLabel>{props.label}</OptionLabel>
      {props.isSelected && (
        <OptionSelectedCheckmark>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_149_15692)">
              <path
                d="M9.99996 18.3333C5.39746 18.3333 1.66663 14.6025 1.66663 10C1.66663 5.3975 5.39746 1.66667 9.99996 1.66667C14.6025 1.66667 18.3333 5.3975 18.3333 10C18.3333 14.6025 14.6025 18.3333 9.99996 18.3333ZM9.16913 13.3333L15.0608 7.44083L13.8825 6.2625L9.16913 10.9767L6.81163 8.61917L5.63329 9.7975L9.16913 13.3333Z"
                fill="#105CF7"
              />
            </g>
            <defs>
              <clipPath id="clip0_149_15692">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </OptionSelectedCheckmark>
      )}
    </components.Option>
  );
};

const MaxValuesReached = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  height: 24px;
  background: #f8faff;
  border-radius: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #09348b;
  margin-left: 12px;
  flex-wrap: wrap;
`;

export const ValueContainer: React.FC<ValueContainerProps> = (
  props: ValueContainerProps
) => {
  if (props.isMulti && props.selectProps.maxValuesLength != null) {
    let maxValuesLength = props.selectProps.maxValuesLength;

    if (props.getValue().length > maxValuesLength) {
      return (
        <MaxValuesReached {...props}>{`${props.getValue().length} ${
          props.selectProps.maxValuesLengthLabel
        }`}</MaxValuesReached>
      );
    }
  }

  return <components.ValueContainer {...props} />;
};
