import React from "react";
import styled from "styled-components";
import {
  BackButton,
  FormCard,
  FormGroupDynamic,
  FormGroupHalfWidth,
  FormLabel,
  FormSubtitle,
  FormTitle,
  PrimaryButton,
  Spacer,
} from "src/Modules/Shared/Components/Temp/ApplicationFlowComponents";
import { Form, Field } from "react-final-form";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import SelectField from "src/Modules/Shared/Components/Temp/SelectField";
import countries from "src/Modules/Shared/Components/Temp/countries";
import { timezoneOptions } from "src/Modules/Shared/Components/Temp/timezones";
import PrefixTextInputField from "src/Modules/Shared/Components/Temp/PrefixTextInputField";
import MultipleBlockSelectField, {
  BlockFieldItem,
} from "src/Modules/Shared/Components/Temp/MultipleBlockSelectField";
import BlockSelectField from "src/Modules/Shared/Components/Temp/BlockSelectField";
import * as yup from "yup";
import axios from "axios";
import CustomerSkillRequirementsField from "src/Modules/Customer/Pages/OrganicSignupFlow/CustomerSkillRequirementsField";
import CustomerApplicationFlowOptions from "src/Modules/Shared/Components/Temp/CustomerApplicationFlowOptions";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { CustomerApiQuery } from "src/Modules/Customer/Services/CustomerApi";
import { scheduler } from "timers/promises";
const Container = styled.div``;

const HalfSizeWidth = 286;

const ArrowRightIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  width: 20px;
  height: 20px;
`;

const ArrowLeftIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 20px;
  height: 20px;
`;

const ButtonGroup = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ButtonGroupSpace = styled.div`
  width: auto;
  height: 48px;
  margin-left: auto;
`;

const AThirdSize = "188px";

const GreetingWithName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const GreetingText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.04em;
  color: #1c274a;
  margin-bottom: 32px;
`;

type Props = {
  setStep: (step: number) => void;
  step: number;
};

const formSchema = yup.object({
  roles: yup.array().required().min(1),
  skills: yup.array().required().min(1),
  requiredDevelopersNumber: yup.string().required(),
});

const ApplicationFlowStepHiringDetails: React.FC<Props> = (props) => {
  let queryClient = useQueryClient();
  let surveyQuery = useQuery(CustomerApiQuery.survey.get());
  let surveyMutation = useMutation({
    mutationFn: CustomerApiQuery.survey.post,
    onSuccess: (data, variables, context) =>
      queryClient.invalidateQueries(CustomerApiQuery.survey.get().queryKey),
  });

  if (surveyQuery.isLoading) {
    return null;
  }

  let data = surveyQuery.data?.data;

  return (
    <Container>
      <Form
        onSubmit={(values) => {
          surveyMutation.mutate(values);
        }}
        initialValues={data.form}
        render={({ form, handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <GreetingWithName>Hi, {values.firstName}</GreetingWithName>
            <GreetingText>
              Let’s start matching the best remote developers for your team
            </GreetingText>
            <FormTitle>Hiring Details</FormTitle>
            <FormSubtitle>
              Let us know what you’re looking for in your next hire(s) so we can
              find your ideal candidates.
            </FormSubtitle>
            <FormCard>
              <FormGroupDynamic width={650}>
                <FormLabel>
                  Roles<span className="optional">(Select all that apply)</span>{" "}
                </FormLabel>
                <Field
                  multiple={true}
                  name={"roles"}
                  component={MultipleBlockSelectField}
                  options={CustomerApplicationFlowOptions.roles}
                ></Field>
              </FormGroupDynamic>

              <FormGroupDynamic width={650}>
                <FormLabel>Skills</FormLabel>
                <Field
                  multiple={true}
                  name={"skills"}
                  component={CustomerSkillRequirementsField}
                  options={CustomerApplicationFlowOptions.mainSkills}
                ></Field>
              </FormGroupDynamic>

              <FormGroupDynamic width={640}>
                <FormLabel>Number of developers looking to hire</FormLabel>
                <Field
                  name="requiredDevelopersNumber"
                  component={BlockSelectField}
                  options={
                    CustomerApplicationFlowOptions.requiredDevelopersNumber
                  }
                />
              </FormGroupDynamic>
            </FormCard>

            <ButtonGroup>
              <ButtonGroupSpace />
              <PrimaryButton
                type="submit"
                disabled={!formSchema.isValidSync(values)}
              >
                Continue{" "}
                <ArrowRightIcon>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.4767 6.16664L6.00668 1.69664L7.18501 0.518311L13.6667 6.99998L7.18501 13.4816L6.00668 12.3033L10.4767 7.83331H0.333344V6.16664H10.4767Z" />
                  </svg>
                </ArrowRightIcon>
              </PrimaryButton>
            </ButtonGroup>
          </form>
        )}
      />
    </Container>
  );
};

export default ApplicationFlowStepHiringDetails;
