import React from "react";
import styled from "styled-components";

const Container = styled.div`
  background: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #ededf0;
  border-radius: 12px;
`;

const Header = styled.div`
  background: #f4fbfd;
  border-radius: 12px 12px 0px 0px;
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 0;
  min-height: 84px;

  border-bottom: 1px solid #ededf0;

  &:last-child {
    border: none;
  }
`;

const Number = styled.div`
  width: 24px;
  height: 24px;
  background: #1d7c93;
  border-radius: 999px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;

  flex: 1;
`;

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
  margin-bottom: 4px;
`;

const Body = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #565e78;
`;

const JobEditSideCard: React.FC = (props) => {
  return (
    <Container>
      <Header>What happens next</Header>
      <List>
        <Item>
          <Number>1</Number>
          <Text>
            <Title>Complete your job post</Title>
            <Body>
              Tell us anything and everything you’re looking for in your next
              developer hire.
            </Body>
          </Text>
        </Item>

        <Item>
          <Number>2</Number>
          <Text>
            <Title>Only the best developers apply</Title>
            <Body>
              Our platform puts your job in front of the candidates that fit the
              role exactly.{" "}
            </Body>
          </Text>
        </Item>

        <Item>
          <Number>3</Number>
          <Text>
            <Title>Review a shortlist of top candidates</Title>
            <Body>
              We match you with the best 3-5 (or more) candidates within days.
            </Body>
          </Text>
        </Item>

        <Item>
          <Number>4</Number>
          <Text>
            <Title>Interview and hire your way 🎉</Title>
            <Body>
              Schedule interviews and hire directly or let us manage payroll and
              everything else.{" "}
            </Body>
          </Text>
        </Item>
      </List>
    </Container>
  );
};

export default JobEditSideCard;
