import React from "react";
import styled from "styled-components";
import { FieldRenderProps } from "react-final-form";

const Container = styled.textarea`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;

  width: 100%;
  height: 148px;

  background: #ffffff;
  /* Notch/Neutral/100 */

  border: 1px solid #ededf0;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
  resize: vertical;

  &:focus {
    box-shadow: 0px 0px 0px 2px #e6eefe;
    border: 1px solid #105cf7;
    outline: none;
  }

  &::placeholder {
    color: #9fa4b3;
  }

  &:placeholder-shown {
    color: #9fa4b3;
  }
`;

type Props = FieldRenderProps<string, any>;

const AreaInputField: React.FC<Props> = (props) => {
  return <Container {...props.input} {...props}></Container>;
};

export default AreaInputField;
