import React from "react";
import styled from "styled-components";
import {
  BackButton,
  FormCard,
  FormGroupDynamic,
  FormGroupFullWidth,
  FormLabel,
  FormSubtitle,
  FormTitle,
  PrimaryButton,
} from "src/Modules/Shared/Components/Temp/ApplicationFlowComponents";
import { Field, Form } from "react-final-form";
import SelectField from "src/Modules/Shared/Components/Temp/SelectField";
import * as yup from "yup";
import axios from "axios";
import BlockSelectField from "src/Modules/Shared/Components/Temp/BlockSelectField";
import PreferredRoleField from "src/Modules/Shared/Components/Temp/PreferredRoleField";
import EnglishSelectField from "src/Modules/Shared/Components/Temp/EnglishSelectField";
import MainSkillsField from "src/Modules/Shared/Components/Temp/MainSkillsField";

const Container = styled.div``;

const HalfSizeWidth = 286;

const ArrowRightIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  width: 20px;
  height: 20px;
`;

const ArrowLeftIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 20px;
  height: 20px;
`;

const ButtonGroup = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ButtonGroupSpace = styled.div`
  width: auto;
  height: 48px;
  margin-left: auto;
`;

type Props = {
  setStep: (step: number) => void;
  setData: (values: any) => void;
  data: any;
};

const yearsOption = [
  { label: "1 year", value: 1 },
  { label: "2 years", value: 2 },
  { label: "3 years", value: 3 },
  { label: "4 years", value: 4 },
  { label: "5 years", value: 5 },
  { label: "6 years", value: 6 },
  { label: "7 years", value: 7 },
  { label: "8 years", value: 8 },
  { label: "9 years", value: 9 },
  { label: "10 years", value: 10 },
  { label: "11 years", value: 11 },
  { label: "12 years", value: 12 },
  { label: "13 years", value: 13 },
  { label: "14 years", value: 14 },
  { label: "15+ years", value: 15 },
];

const LabelMini = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #b8bcc7;
`;

const formSchema = yup.object({
  preferredRole: yup.string().required(),
  mainSkills: yup.array().required().min(1),
  totalExperience: yup.object().required(),
  preferredRoleExperience: yup.object().required(),
  remoteWorkExperience: yup.string().required(),
  english: yup.string().required(),
  remoteWorkYears: yup.object().when("remoteWorkExperience", {
    is: (value: any) => value == "y",
    then: (schema: any) => schema.object().required(),
    otherwise: (schema: any) => schema.object().notRequired(),
  }),
});

const ApplicationFlowStepSkillExperience: React.FC<Props> = (props) => {
  return (
    <Container>
      <Form
        onSubmit={(values) => {
          axios
            .post("/api/customers/v1/application-flow", values)
            .then((response) => {
              props.setData(response.data);
            });
        }}
        initialValues={props.data}
        render={({ handleSubmit, values, submitting }) => (
          <form onSubmit={handleSubmit}>
            <FormTitle>Skills & Experiences</FormTitle>
            <FormSubtitle>
              Tell us about your professional background and areas of expertise.
            </FormSubtitle>

            <FormCard>
              <FormGroupDynamic width={FormGroupFullWidth}>
                <FormLabel>Preferred Role</FormLabel>
                <Field
                  name={"preferredRole"}
                  component={PreferredRoleField}
                  variant={"3x"}
                  options={[
                    { label: "Fullstack Engineer", value: "a" },
                    { label: "Backend Engineer", value: "b" },
                    { label: "Frontend Engineer", value: "c" },
                    { label: "DevOps Engineer", value: "d" },
                    { label: "Mobile Engineer", value: "g" },
                    { label: "Data Engineer", value: "e" },
                  ]}
                />
              </FormGroupDynamic>

              <FormGroupDynamic width={FormGroupFullWidth}>
                <FormLabel>
                  Add your main skills <LabelMini>( up to 5 )</LabelMini>
                </FormLabel>
                <Field name={"mainSkills"} component={MainSkillsField} />
              </FormGroupDynamic>

              <FormGroupDynamic width={HalfSizeWidth}>
                <FormLabel>Total Experience</FormLabel>
                <Field
                  name={"totalExperience"}
                  component={SelectField}
                  options={yearsOption}
                />
              </FormGroupDynamic>
              <FormGroupDynamic width={16} className="hide-mb" />
              <FormGroupDynamic width={HalfSizeWidth}>
                <FormLabel>Preferred Role Experience</FormLabel>
                <Field
                  name={"preferredRoleExperience"}
                  component={SelectField}
                  options={yearsOption}
                />
              </FormGroupDynamic>

              <FormGroupDynamic width={HalfSizeWidth}>
                <FormLabel>Remote Work Experience</FormLabel>
                <Field
                  name={"remoteWorkExperience"}
                  component={BlockSelectField}
                  size={44}
                  options={[
                    { value: "y", label: "Yes", width: "46%" },
                    { value: "n", label: "No", width: "45%" },
                  ]}
                />
              </FormGroupDynamic>
              <FormGroupDynamic width={16} className="hide-mb" />
              <FormGroupDynamic width={HalfSizeWidth}>
                {values.remoteWorkExperience === "y" && (
                  <>
                    <FormLabel>Years</FormLabel>
                    <Field
                      name={"remoteWorkYears"}
                      component={SelectField}
                      options={yearsOption}
                    />
                  </>
                )}
              </FormGroupDynamic>

              <FormGroupDynamic width={FormGroupFullWidth}>
                <FormLabel>English level</FormLabel>
                <Field name={"english"} component={EnglishSelectField} />
              </FormGroupDynamic>
            </FormCard>

            <ButtonGroup>
              <BackButton
                onClick={(event) => {
                  axios
                    .post("/api/customers/v1/application-flow-back", values)
                    .then((response) => {
                      props.setData(response.data);
                    });
                  event.preventDefault();
                }}
              >
                <ArrowLeftIcon>
                  <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.02331 6.16664H14.1666V7.83331H4.02331L8.49331 12.3033L7.31498 13.4816L0.833313 6.99998L7.31498 0.518311L8.49331 1.69664L4.02331 6.16664Z"
                      fill="#105CF7"
                    />
                  </svg>
                </ArrowLeftIcon>
                Back
              </BackButton>
              <ButtonGroupSpace />
              <PrimaryButton
                type="submit"
                disabled={!formSchema.isValidSync(values) || submitting}
              >
                Continue{" "}
                <ArrowRightIcon>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.4767 6.16664L6.00668 1.69664L7.18501 0.518311L13.6667 6.99998L7.18501 13.4816L6.00668 12.3033L10.4767 7.83331H0.333344V6.16664H10.4767Z" />
                  </svg>
                </ArrowRightIcon>
              </PrimaryButton>
            </ButtonGroup>
          </form>
        )}
      />
    </Container>
  );
};

export default ApplicationFlowStepSkillExperience;
