import styled from "styled-components";

export const FormTitle = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.04em;
  color: #1c274a;
  max-width: 100%;
`;

export const FormSubtitle = styled.p`
  height: 24px;
  max-width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  /* identical to box height, or 171% */

  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  margin-top: 8px;
  /* Notch/Neutral/900 */

  color: #1c274a;
  margin-bottom: 24px;
`;

export const FormCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 100%;

  padding: 24px;

  background: #ffffff;
  /* Notch/Neutral/100 */

  border: 1px solid #ededf0;
  border-radius: 6px;
`;

export const FormGroupFullWidth = styled.div``;

export const FormGroupHalfWidth = styled.div`
  display: flex;
  flex-direction: column;
  width: 292px;
`;

interface WithWidth {
  width: number;
  props: {
    width?: number;
  };
}

export const FormGroupDynamic = styled.div<{ width?: any }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  min-height: 8px;
  width: 100%;

  &.hide-mb {
    display: none;
  }

  @media screen and (min-width: 660px) {
    width: ${(props: any) => props.width}px;
    &.hide-mb {
      display: flex;
    }
  }
`;

export const FormGroupFull = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  min-height: 8px;
  width: 100%;
`;

export const FormLabel = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: #1c274a;
  margin-bottom: 8px;

  display: flex;
  flex-direction: row;
  gap: 8px;

  span.optional {
    margin-left: 4px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.03em;
    color: #b8bcc7;
  }
`;

export const EmptyFormLabel = styled.div`
  height: 32px;
`;

export const Spacer = styled.div``;

export const BackButton = styled.button`
  height: 48px;
  padding: 12px 32px;
  background: none;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid transparent;
  }
`;

export const PrimaryButton = styled.button`
  height: 48px;
  padding: 12px 32px;
  background: #105cf7;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #ffffff;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    fill: #ffffff;
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    background: #ededf0;
    color: #9fa4b3;
    cursor: default;
    border: none;
    svg {
      fill: #9fa4b3;
    }
  }
`;

export const PrimaryButtonWide = styled(PrimaryButton)`
  width: 100%;
`;

export const PrimaryButtonOutline = styled.button`
  height: 48px;
  padding: 12px 32px;
  background: none;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;
  border-color: #105cf7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    fill: #ffffff;
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    background: #ededf0;
    color: #9fa4b3;
    cursor: default;
    border: none;
    svg {
      fill: #9fa4b3;
    }
  }
`;
