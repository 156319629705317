import React from "react";
import styled from "styled-components";
import CandidateActionScheduleInterview from "src/Modules/Shared/Components/Shortlist/CandidateCard/CandidateActionScheduleInterview";
import CandidateDeclineAction from "src/Modules/Shared/Components/Shortlist/CandidateCard/CandidateDeclineAction";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const CustomerActions: React.FC = (props) => {
  let status = props.data.status;

  let state = null;

  if (["New", "Not Viewed", "Viewed"].includes(status)) {
    state = "noActionTaken";
  }

  if (state == "noActionTaken") {
    return (
      <Container>
        <CandidateActionScheduleInterview {...props} />
        <CandidateDeclineAction {...props} />
      </Container>
    );
  }

  if (status == "Not Hired") {
    return (
      <Container>
        <CandidateActionScheduleInterview {...props} disabled={true} />
      </Container>
    );
  }

  if (status == "Interviewing") {
    return (
      <Container>
        <CandidateActionScheduleInterview {...props} disabled={true} />
      </Container>
    );
  }

  return <Container>{status}</Container>;
};

export default CustomerActions;
