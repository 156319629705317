import React from "react";
import styled from "styled-components";
import { CircleFlag } from "react-circle-flags";
import countries from "src/Modules/Shared/Components/Temp/countries";
import MapPin2FillIcon from "remixicon-react/MapPin2FillIcon";
import MapPin2LineIcon from "remixicon-react/MapPin2LineIcon";
import CloseLineIcon from "remixicon-react/CloseLineIcon";
import Clockwise2FillIcon from "remixicon-react/Clockwise2FillIcon";
import Timer2LineIcon from "remixicon-react/Timer2LineIcon";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { CustomerApi } from "src/Modules/Customer/Services/CustomerApi";
import { JobDetailsHelper } from "src/Modules/Core/Service/JobDetailsService";
import BuildingLineIcon from "remixicon-react/BuildingLineIcon";
import MoneyDollarCircleLineIcon from "remixicon-react/MoneyDollarCircleLineIcon";
import Briefcase2LineIcon from "remixicon-react/Briefcase2LineIcon";

const Container = styled.div``;

const borderColor = "#EDEDF0";

const ProfilePartAContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const ProfilePartBContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SalaryLabel = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #1c274a;
  margin-bottom: 12px;
`;

const Salary = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const SalaryType = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #858b9d;
  margin-left: auto;
`;

const ProfileInfoLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const InfoLineIcon = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  svg {
    color: #111;
  }
`;

const InfoLineText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: -0.03em;

  /* Notch/Neutral/900 */

  color: #1c274a;
`;

const ProfileImageContainer = styled.img`
  border: 1px solid #ffffff;
  border-radius: 999px;
  width: 56px;
  height: 56px;
  margin-right: 20px;
`;

const ProfileNameContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const Name = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;
const Role = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;
const Place = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: #1c274a;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const GrayLine = styled.div`
  border-top: 1px solid ${borderColor};
  width: 100%;
  margin: 16px 0;
`;

const mapToLabel = (value, options) => {
  let f = options.find((i) => {
    return i.value == value;
  });

  return f?.label ?? value;
};

const isDirectHire = (data) => {
  let isDirectHire = false;

  if (data?.job_type == "Employee") {
    isDirectHire = true;
  } else {
    if (data?.hiring_model == "Direct Hire") {
      isDirectHire = true;
    }
  }

  return isDirectHire;
};

const CandidateCardProfileCard: React.FC = (props) => {
  let draftOverwrites = props.data.dataOverwrites;

  let params = useParams();

  let jobId = params.jobId;
  const jobQuery = useQuery(CustomerApi.jobs.get(jobId));

  if (jobQuery.isLoading) {
    return null;
  }

  let showCloudSalary = false;
  let showDirectSalary = false;

  let data = jobQuery.data?.data;
  let directHire = JobDetailsHelper.isDirectHire(data.jobDetails);

  if (directHire) {
    showDirectSalary = true;
  } else {
    showCloudSalary = true;
  }

  const hiringModel = data.jobDetails.hiringModel;
  const jobType = data.jobDetails.jobType;

  if (hiringModel == "Open to either") {
    showCloudSalary = true;
    showDirectSalary = true;
  }

  return (
    <Container>
      <ProfilePartAContainer>
        <ProfileImageContainer src={props.data.legacyProfile.profileBase} />
        <ProfileNameContainer>
          <Name>{draftOverwrites.Name}</Name>
          <Role>{draftOverwrites.Title}</Role>
          <Place>
            <CircleFlag
              countryCode={draftOverwrites.Country?.toLowerCase()}
              height={16}
            />
            {mapToLabel(draftOverwrites.Country, countries)}
          </Place>
        </ProfileNameContainer>
      </ProfilePartAContainer>
      <GrayLine />

      <ProfilePartBContainer>
        <ProfileInfoLine>
          <InfoLineIcon>
            <MapPin2LineIcon size={18} />
          </InfoLineIcon>
          <InfoLineText>{draftOverwrites.City}</InfoLineText>
        </ProfileInfoLine>

        <ProfileInfoLine>
          <InfoLineIcon>
            <BuildingLineIcon size={18} color={"#1C274A"} />
          </InfoLineIcon>
          <InfoLineText>{draftOverwrites.Timezone}</InfoLineText>
        </ProfileInfoLine>

        <ProfileInfoLine>
          <InfoLineIcon>
            <Briefcase2LineIcon size={18} color={"#1C274A"} />
          </InfoLineIcon>
          <InfoLineText>
            {draftOverwrites.YearsEngineeringExperience} of engineering
            experience
          </InfoLineText>
        </ProfileInfoLine>

        {draftOverwrites.preferredRate && (
          <ProfileInfoLine>
            <InfoLineIcon>
              <MoneyDollarCircleLineIcon size={18} color="#1C274A" />
            </InfoLineIcon>
            <InfoLineText>{draftOverwrites.preferredRate}</InfoLineText>
          </ProfileInfoLine>
        )}
      </ProfilePartBContainer>

      {/*
      <GrayLine />

      <ProfilePartBContainer>
        <SalaryLabel>Expected Salary</SalaryLabel>
        {showDirectSalary && (
          <>
            <ProfileInfoLine>
              <Salary>{draftOverwrites.DirectHireSalary}</Salary>
              <SalaryType>Direct Hire</SalaryType>
            </ProfileInfoLine>
          </>
        )}

        {showCloudSalary && (
          <ProfileInfoLine>
            <Salary>{draftOverwrites.CloudHireSalary}</Salary>
            <SalaryType>Cloud Hire</SalaryType>
          </ProfileInfoLine>
        )}
      </ProfilePartBContainer>

      */}
      <GrayLine />
    </Container>
  );
};

export default CandidateCardProfileCard;
