import React from "react";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import axiosInstance from "src/Modules/Core/Service/Api";

const Container = styled.div``;

const ProfilePicture = styled.div`
  width: 32px;
  aspect-ratio: 1;
  border-radius: 999px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-family: Inter;
  font-weight: 700;

  font-size: 14px;

  background: #c4b5fd;
  color: #9333ea;
  border: 1px solid #c4b5fd;
`;

const ProfileImage = styled.img`
  width: 32px;
  aspect-ratio: 1;
  border-radius: 999px;

  border: 1px solid #f3f3f3;
`;

const CurrentUserProfilePicture: React.FC = (props) => {
  const authQuery = useQuery({
    queryKey: ["auth"],
    queryFn: () => axiosInstance.get("/api/auth/me"),
  });

  if (authQuery.isLoading) {
    return null;
  }

  let size = 32;

  if (props.size != undefined) {
    size = props.size;
  }

  let data = authQuery.data?.data;

  if (data.profilePictureUrl != null) {
    return (
      <ProfileImage
        style={{ width: size }}
        src={data.profilePictureUrl}
      ></ProfileImage>
    );
  }

  return (
    <ProfilePicture style={{ width: size, fontSize: size / 2.46 }}>
      {data.name[0]}
    </ProfilePicture>
  );
};

export default CurrentUserProfilePicture;
