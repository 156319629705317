export default [
  {
    country: "US",
    value: "1",
    label: "+1",
  },
  {
    country: "AC",
    value: "247",
    label: "+247",
  },
  {
    country: "AD",
    value: "376",
    label: "+376",
  },
  {
    country: "AE",
    value: "971",
    label: "+971",
  },
  {
    country: "AF",
    value: "93",
    label: "+93",
  },
  {
    country: "AG",
    value: "1",
    label: "+1",
  },
  {
    country: "AI",
    value: "1",
    label: "+1",
  },
  {
    country: "AL",
    value: "355",
    label: "+355",
  },
  {
    country: "AM",
    value: "374",
    label: "+374",
  },
  {
    country: "AO",
    value: "244",
    label: "+244",
  },
  {
    country: "AR",
    value: "54",
    label: "+54",
  },
  {
    country: "AS",
    value: "1",
    label: "+1",
  },
  {
    country: "AT",
    value: "43",
    label: "+43",
  },
  {
    country: "AU",
    value: "61",
    label: "+61",
  },
  {
    country: "AW",
    value: "297",
    label: "+297",
  },
  {
    country: "AX",
    value: "358",
    label: "+358",
  },
  {
    country: "AZ",
    value: "994",
    label: "+994",
  },
  {
    country: "BA",
    value: "387",
    label: "+387",
  },
  {
    country: "BB",
    value: "1",
    label: "+1",
  },
  {
    country: "BD",
    value: "880",
    label: "+880",
  },
  {
    country: "BE",
    value: "32",
    label: "+32",
  },
  {
    country: "BF",
    value: "226",
    label: "+226",
  },
  {
    country: "BG",
    value: "359",
    label: "+359",
  },
  {
    country: "BH",
    value: "973",
    label: "+973",
  },
  {
    country: "BI",
    value: "257",
    label: "+257",
  },
  {
    country: "BJ",
    value: "229",
    label: "+229",
  },
  {
    country: "BL",
    value: "590",
    label: "+590",
  },
  {
    country: "BM",
    value: "1",
    label: "+1",
  },
  {
    country: "BN",
    value: "673",
    label: "+673",
  },
  {
    country: "BO",
    value: "591",
    label: "+591",
  },
  {
    country: "BQ",
    value: "599",
    label: "+599",
  },
  {
    country: "BR",
    value: "55",
    label: "+55",
  },
  {
    country: "BS",
    value: "1",
    label: "+1",
  },
  {
    country: "BT",
    value: "975",
    label: "+975",
  },
  {
    country: "BW",
    value: "267",
    label: "+267",
  },
  {
    country: "BY",
    value: "375",
    label: "+375",
  },
  {
    country: "BZ",
    value: "501",
    label: "+501",
  },
  {
    country: "CA",
    value: "1",
    label: "+1",
  },
  {
    country: "CC",
    value: "61",
    label: "+61",
  },
  {
    country: "CD",
    value: "243",
    label: "+243",
  },
  {
    country: "CF",
    value: "236",
    label: "+236",
  },
  {
    country: "CG",
    value: "242",
    label: "+242",
  },
  {
    country: "CH",
    value: "41",
    label: "+41",
  },
  {
    country: "CI",
    value: "225",
    label: "+225",
  },
  {
    country: "CK",
    value: "682",
    label: "+682",
  },
  {
    country: "CL",
    value: "56",
    label: "+56",
  },
  {
    country: "CM",
    value: "237",
    label: "+237",
  },
  {
    country: "CN",
    value: "86",
    label: "+86",
  },
  {
    country: "CO",
    value: "57",
    label: "+57",
  },
  {
    country: "CR",
    value: "506",
    label: "+506",
  },
  {
    country: "CU",
    value: "53",
    label: "+53",
  },
  {
    country: "CV",
    value: "238",
    label: "+238",
  },
  {
    country: "CW",
    value: "599",
    label: "+599",
  },
  {
    country: "CX",
    value: "61",
    label: "+61",
  },
  {
    country: "CY",
    value: "357",
    label: "+357",
  },
  {
    country: "CZ",
    value: "420",
    label: "+420",
  },
  {
    country: "DE",
    value: "49",
    label: "+49",
  },
  {
    country: "DJ",
    value: "253",
    label: "+253",
  },
  {
    country: "DK",
    value: "45",
    label: "+45",
  },
  {
    country: "DM",
    value: "1",
    label: "+1",
  },
  {
    country: "DO",
    value: "1",
    label: "+1",
  },
  {
    country: "DZ",
    value: "213",
    label: "+213",
  },
  {
    country: "EC",
    value: "593",
    label: "+593",
  },
  {
    country: "EE",
    value: "372",
    label: "+372",
  },
  {
    country: "EG",
    value: "20",
    label: "+20",
  },
  {
    country: "EH",
    value: "212",
    label: "+212",
  },
  {
    country: "ER",
    value: "291",
    label: "+291",
  },
  {
    country: "ES",
    value: "34",
    label: "+34",
  },
  {
    country: "ET",
    value: "251",
    label: "+251",
  },
  {
    country: "FI",
    value: "358",
    label: "+358",
  },
  {
    country: "FJ",
    value: "679",
    label: "+679",
  },
  {
    country: "FK",
    value: "500",
    label: "+500",
  },
  {
    country: "FM",
    value: "691",
    label: "+691",
  },
  {
    country: "FO",
    value: "298",
    label: "+298",
  },
  {
    country: "FR",
    value: "33",
    label: "+33",
  },
  {
    country: "GA",
    value: "241",
    label: "+241",
  },
  {
    country: "GB",
    value: "44",
    label: "+44",
  },
  {
    country: "GD",
    value: "1",
    label: "+1",
  },
  {
    country: "GE",
    value: "995",
    label: "+995",
  },
  {
    country: "GF",
    value: "594",
    label: "+594",
  },
  {
    country: "GG",
    value: "44",
    label: "+44",
  },
  {
    country: "GH",
    value: "233",
    label: "+233",
  },
  {
    country: "GI",
    value: "350",
    label: "+350",
  },
  {
    country: "GL",
    value: "299",
    label: "+299",
  },
  {
    country: "GM",
    value: "220",
    label: "+220",
  },
  {
    country: "GN",
    value: "224",
    label: "+224",
  },
  {
    country: "GP",
    value: "590",
    label: "+590",
  },
  {
    country: "GQ",
    value: "240",
    label: "+240",
  },
  {
    country: "GR",
    value: "30",
    label: "+30",
  },
  {
    country: "GT",
    value: "502",
    label: "+502",
  },
  {
    country: "GU",
    value: "1",
    label: "+1",
  },
  {
    country: "GW",
    value: "245",
    label: "+245",
  },
  {
    country: "GY",
    value: "592",
    label: "+592",
  },
  {
    country: "HK",
    value: "852",
    label: "+852",
  },
  {
    country: "HN",
    value: "504",
    label: "+504",
  },
  {
    country: "HR",
    value: "385",
    label: "+385",
  },
  {
    country: "HT",
    value: "509",
    label: "+509",
  },
  {
    country: "HU",
    value: "36",
    label: "+36",
  },
  {
    country: "ID",
    value: "62",
    label: "+62",
  },
  {
    country: "IE",
    value: "353",
    label: "+353",
  },
  {
    country: "IL",
    value: "972",
    label: "+972",
  },
  {
    country: "IM",
    value: "44",
    label: "+44",
  },
  {
    country: "IN",
    value: "91",
    label: "+91",
  },
  {
    country: "IO",
    value: "246",
    label: "+246",
  },
  {
    country: "IQ",
    value: "964",
    label: "+964",
  },
  {
    country: "IR",
    value: "98",
    label: "+98",
  },
  {
    country: "IS",
    value: "354",
    label: "+354",
  },
  {
    country: "IT",
    value: "39",
    label: "+39",
  },
  {
    country: "JE",
    value: "44",
    label: "+44",
  },
  {
    country: "JM",
    value: "1",
    label: "+1",
  },
  {
    country: "JO",
    value: "962",
    label: "+962",
  },
  {
    country: "JP",
    value: "81",
    label: "+81",
  },
  {
    country: "KE",
    value: "254",
    label: "+254",
  },
  {
    country: "KG",
    value: "996",
    label: "+996",
  },
  {
    country: "KH",
    value: "855",
    label: "+855",
  },
  {
    country: "KI",
    value: "686",
    label: "+686",
  },
  {
    country: "KM",
    value: "269",
    label: "+269",
  },
  {
    country: "KN",
    value: "1",
    label: "+1",
  },
  {
    country: "KP",
    value: "850",
    label: "+850",
  },
  {
    country: "KR",
    value: "82",
    label: "+82",
  },
  {
    country: "KW",
    value: "965",
    label: "+965",
  },
  {
    country: "KY",
    value: "1",
    label: "+1",
  },
  {
    country: "KZ",
    value: "7",
    label: "+7",
  },
  {
    country: "LA",
    value: "856",
    label: "+856",
  },
  {
    country: "LB",
    value: "961",
    label: "+961",
  },
  {
    country: "LC",
    value: "1",
    label: "+1",
  },
  {
    country: "LI",
    value: "423",
    label: "+423",
  },
  {
    country: "LK",
    value: "94",
    label: "+94",
  },
  {
    country: "LR",
    value: "231",
    label: "+231",
  },
  {
    country: "LS",
    value: "266",
    label: "+266",
  },
  {
    country: "LT",
    value: "370",
    label: "+370",
  },
  {
    country: "LU",
    value: "352",
    label: "+352",
  },
  {
    country: "LV",
    value: "371",
    label: "+371",
  },
  {
    country: "LY",
    value: "218",
    label: "+218",
  },
  {
    country: "MA",
    value: "212",
    label: "+212",
  },
  {
    country: "MC",
    value: "377",
    label: "+377",
  },
  {
    country: "MD",
    value: "373",
    label: "+373",
  },
  {
    country: "ME",
    value: "382",
    label: "+382",
  },
  {
    country: "MF",
    value: "590",
    label: "+590",
  },
  {
    country: "MG",
    value: "261",
    label: "+261",
  },
  {
    country: "MH",
    value: "692",
    label: "+692",
  },
  {
    country: "MK",
    value: "389",
    label: "+389",
  },
  {
    country: "ML",
    value: "223",
    label: "+223",
  },
  {
    country: "MM",
    value: "95",
    label: "+95",
  },
  {
    country: "MN",
    value: "976",
    label: "+976",
  },
  {
    country: "MO",
    value: "853",
    label: "+853",
  },
  {
    country: "MP",
    value: "1",
    label: "+1",
  },
  {
    country: "MQ",
    value: "596",
    label: "+596",
  },
  {
    country: "MR",
    value: "222",
    label: "+222",
  },
  {
    country: "MS",
    value: "1",
    label: "+1",
  },
  {
    country: "MT",
    value: "356",
    label: "+356",
  },
  {
    country: "MU",
    value: "230",
    label: "+230",
  },
  {
    country: "MV",
    value: "960",
    label: "+960",
  },
  {
    country: "MW",
    value: "265",
    label: "+265",
  },
  {
    country: "MX",
    value: "52",
    label: "+52",
  },
  {
    country: "MY",
    value: "60",
    label: "+60",
  },
  {
    country: "MZ",
    value: "258",
    label: "+258",
  },
  {
    country: "NA",
    value: "264",
    label: "+264",
  },
  {
    country: "NC",
    value: "687",
    label: "+687",
  },
  {
    country: "NE",
    value: "227",
    label: "+227",
  },
  {
    country: "NF",
    value: "672",
    label: "+672",
  },
  {
    country: "NG",
    value: "234",
    label: "+234",
  },
  {
    country: "NI",
    value: "505",
    label: "+505",
  },
  {
    country: "NL",
    value: "31",
    label: "+31",
  },
  {
    country: "NO",
    value: "47",
    label: "+47",
  },
  {
    country: "NP",
    value: "977",
    label: "+977",
  },
  {
    country: "NR",
    value: "674",
    label: "+674",
  },
  {
    country: "NU",
    value: "683",
    label: "+683",
  },
  {
    country: "NZ",
    value: "64",
    label: "+64",
  },
  {
    country: "OM",
    value: "968",
    label: "+968",
  },
  {
    country: "PA",
    value: "507",
    label: "+507",
  },
  {
    country: "PE",
    value: "51",
    label: "+51",
  },
  {
    country: "PF",
    value: "689",
    label: "+689",
  },
  {
    country: "PG",
    value: "675",
    label: "+675",
  },
  {
    country: "PH",
    value: "63",
    label: "+63",
  },
  {
    country: "PK",
    value: "92",
    label: "+92",
  },
  {
    country: "PL",
    value: "48",
    label: "+48",
  },
  {
    country: "PM",
    value: "508",
    label: "+508",
  },
  {
    country: "PR",
    value: "1",
    label: "+1",
  },
  {
    country: "PS",
    value: "970",
    label: "+970",
  },
  {
    country: "PT",
    value: "351",
    label: "+351",
  },
  {
    country: "PW",
    value: "680",
    label: "+680",
  },
  {
    country: "PY",
    value: "595",
    label: "+595",
  },
  {
    country: "QA",
    value: "974",
    label: "+974",
  },
  {
    country: "RE",
    value: "262",
    label: "+262",
  },
  {
    country: "RO",
    value: "40",
    label: "+40",
  },
  {
    country: "RS",
    value: "381",
    label: "+381",
  },
  {
    country: "RU",
    value: "7",
    label: "+7",
  },
  {
    country: "RW",
    value: "250",
    label: "+250",
  },
  {
    country: "SA",
    value: "966",
    label: "+966",
  },
  {
    country: "SB",
    value: "677",
    label: "+677",
  },
  {
    country: "SC",
    value: "248",
    label: "+248",
  },
  {
    country: "SD",
    value: "249",
    label: "+249",
  },
  {
    country: "SE",
    value: "46",
    label: "+46",
  },
  {
    country: "SG",
    value: "65",
    label: "+65",
  },
  {
    country: "SH",
    value: "290",
    label: "+290",
  },
  {
    country: "SI",
    value: "386",
    label: "+386",
  },
  {
    country: "SJ",
    value: "47",
    label: "+47",
  },
  {
    country: "SK",
    value: "421",
    label: "+421",
  },
  {
    country: "SL",
    value: "232",
    label: "+232",
  },
  {
    country: "SM",
    value: "378",
    label: "+378",
  },
  {
    country: "SN",
    value: "221",
    label: "+221",
  },
  {
    country: "SO",
    value: "252",
    label: "+252",
  },
  {
    country: "SR",
    value: "597",
    label: "+597",
  },
  {
    country: "SS",
    value: "211",
    label: "+211",
  },
  {
    country: "ST",
    value: "239",
    label: "+239",
  },
  {
    country: "SV",
    value: "503",
    label: "+503",
  },
  {
    country: "SX",
    value: "1",
    label: "+1",
  },
  {
    country: "SY",
    value: "963",
    label: "+963",
  },
  {
    country: "SZ",
    value: "268",
    label: "+268",
  },
  {
    country: "TA",
    value: "290",
    label: "+290",
  },
  {
    country: "TC",
    value: "1",
    label: "+1",
  },
  {
    country: "TD",
    value: "235",
    label: "+235",
  },
  {
    country: "TG",
    value: "228",
    label: "+228",
  },
  {
    country: "TH",
    value: "66",
    label: "+66",
  },
  {
    country: "TJ",
    value: "992",
    label: "+992",
  },
  {
    country: "TK",
    value: "690",
    label: "+690",
  },
  {
    country: "TL",
    value: "670",
    label: "+670",
  },
  {
    country: "TM",
    value: "993",
    label: "+993",
  },
  {
    country: "TN",
    value: "216",
    label: "+216",
  },
  {
    country: "TO",
    value: "676",
    label: "+676",
  },
  {
    country: "TR",
    value: "90",
    label: "+90",
  },
  {
    country: "TT",
    value: "1",
    label: "+1",
  },
  {
    country: "TV",
    value: "688",
    label: "+688",
  },
  {
    country: "TW",
    value: "886",
    label: "+886",
  },
  {
    country: "TZ",
    value: "255",
    label: "+255",
  },
  {
    country: "UA",
    value: "380",
    label: "+380",
  },
  {
    country: "UG",
    value: "256",
    label: "+256",
  },
  {
    country: "UY",
    value: "598",
    label: "+598",
  },
  {
    country: "UZ",
    value: "998",
    label: "+998",
  },
  {
    country: "VA",
    value: "39",
    label: "+39",
  },
  {
    country: "VC",
    value: "1",
    label: "+1",
  },
  {
    country: "VE",
    value: "58",
    label: "+58",
  },
  {
    country: "VG",
    value: "1",
    label: "+1",
  },
  {
    country: "VI",
    value: "1",
    label: "+1",
  },
  {
    country: "VN",
    value: "84",
    label: "+84",
  },
  {
    country: "VU",
    value: "678",
    label: "+678",
  },
  {
    country: "WF",
    value: "681",
    label: "+681",
  },
  {
    country: "WS",
    value: "685",
    label: "+685",
  },
  {
    country: "XK",
    value: "383",
    label: "+383",
  },
  {
    country: "YE",
    value: "967",
    label: "+967",
  },
  {
    country: "YT",
    value: "262",
    label: "+262",
  },
  {
    country: "ZA",
    value: "27",
    label: "+27",
  },
  {
    country: "ZM",
    value: "260",
    label: "+260",
  },
  {
    country: "ZW",
    value: "263",
    label: "+263",
  },
];
