import React from "react";
import styled from "styled-components";
import ApplicationFlowStepHiringDetails from "src/Modules/Shared/Components/Temp/ApplicationFlowStepHiringDetails";
import ApplicationFlowStepRoleRequirements from "src/Modules/Shared/Components/Temp/ApplicationFlowStepRoleRequirements";
import ApplicationFlowStepCompanyDetails from "src/Modules/Shared/Components/Temp/ApplicationFlowStepCompanyDetails";
import ApplicationFlowStepPersonalDetails from "src/Modules/Shared/Components/Temp/ApplicationFlowStepPersonalDetails";
import ApplicationFlowStepSkillExperience from "src/Modules/Shared/Components/Temp/ApplicationFlowStepSkillExperience";
import ApplicationFlowStepWorkPreference from "src/Modules/Shared/Components/Temp/ApplicationFlowStepWorkPreference";
import CustomerBookCalendlyStep from "src/Modules/Customer/Pages/OrganicSignupFlow/CustomerBookCalendlyStep";
import { useQuery } from "@tanstack/react-query";
import { CustomerApiQuery } from "src/Modules/Customer/Services/CustomerApi";

const Container = styled.div`
  grid-area: form;
  width: 650px;
  max-width: 100%;
`;

const CalendlyContainer = styled.div`
  grid-area: form;
  max-width: 100%;
  margin: 0 auto;
`;

type Props = {
  setStep: (step: number) => void;

  data: any;
  setData: (value: any) => void;
};

const TalentApplicationFlowForm: React.FC<Props> = (props) => {
  let surveyQuery = useQuery(CustomerApiQuery.survey.get());

  if (surveyQuery.isLoading) {
    return null;
  }

  let data = surveyQuery.data?.data;

  return null;

  if (data.step == 5) {
    return (
      <CalendlyContainer>
        {props.data?.step == 2 && (
          <ApplicationFlowStepHiringDetails {...props} />
        )}
        {props.data?.step == 3 && (
          <ApplicationFlowStepRoleRequirements {...props} />
        )}
        {props.data?.step == 4 && (
          <ApplicationFlowStepCompanyDetails {...props} />
        )}
        {props.data?.step == 5 && <CustomerBookCalendlyStep {...props} />}
      </CalendlyContainer>
    );
  }

  return (
    <Container>
      {data?.step == 1 && <ApplicationFlowStepHiringDetails {...props} />}
      {data?.step == 2 && <ApplicationFlowStepRoleRequirements {...props} />}
      {data?.step == 3 && <ApplicationFlowStepCompanyDetails {...props} />}
      {data?.step == 4 && <CustomerBookCalendlyStep {...props} />}
    </Container>
  );
};

export default TalentApplicationFlowForm;
