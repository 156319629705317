import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  BackButton,
  FormCard,
  FormGroupDynamic,
  FormLabel,
  FormSubtitle,
  FormTitle,
  PrimaryButton,
  PrimaryButtonOutline,
  PrimaryButtonWide,
} from "src/Modules/Shared/Components/Temp/ApplicationFlowComponents";
import { Form, Field } from "react-final-form";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import SelectField from "src/Modules/Shared/Components/Temp/SelectField";
import countries from "src/Modules/Shared/Components/Temp/countries";
import BlockSelectField from "src/Modules/Shared/Components/Temp/BlockSelectField";
import axios from "axios";
import * as yup from "yup";
import JsonDump from "src/Modules/Shared/Utils/JsonDump";
import PhoneNumberField from "src/Modules/Shared/Components/Temp/PhoneNumberField";
import { useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios/index";
import FluidContentContainer from "src/Modules/Shared/Components/Layout/FluidContentContainer";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "src/Modules/Core/Service/Api";
const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const HalfSizeWidth = 286;

const ArrowLeftIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 20px;
  height: 20px;
`;

const ArrowRightIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  width: 20px;
  height: 20px;
`;

const ButtonGroup = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ButtonGroupSpace = styled.div`
  width: auto;
  height: 48px;
  margin-left: auto;
`;

const AThirdSize = "182px";

type Props = {};

const CalendlyContainer = styled.div`
  width: 100%;
  height: 650px;
  border-radius: 10px;

  margin: 0px auto 0 auto;
`;

const Line = styled.div`
  width: 100%;
  border-top: 1px solid #ededf0;
  height: 1px;
  margin-top: 50px;
  margin-bottom: 20px;
`;

const RatherNotSchedule = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #9fa4b3;

  width: 100%;
  text-align: center;
`;

const Icon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 16px;
    height: 16px;
  }
`;

const ButtonGap = styled.div`
  width: 16px;
  height: 16px;
`;

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100dvh;
  position: fixed;

  top: 0;
  left: 0;

  background: rgba(0, 0, 0, 0.4);

  @media screen and (min-width: 1280px) {
    padding-left: 264px;
  }
`;

const Card = styled.div`
  max-width: 568px;
  width: 100%;
  background: white;
  border-radius: 12px;
  padding: 24px;
  margin: 8px;
`;

const PopupHeader = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.04em;
  color: #1c274a;
  margin-bottom: 24px;
`;

const PopupText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #1c274a;
  margin-bottom: 24px;
`;

const formSchema = yup.object({
  phone_number: yup.object({
    prefix: yup.string().required(),
    number: yup.string().required(),
  }),
});

const PhoneNumberHint = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #858b9d;
  margin-bottom: 12px;
`;

const Wrapper = styled.div`
  height: 100dvh;
  position: relative;
`;

function isCalendlyEvent(e: any) {
  return e.data.event && e.data.event.indexOf("calendly") === 0;
}

const CustomerHomeCallSchedule: React.FC<Props> = (props) => {
  const nav = useNavigate();
  const response = useQuery({
    queryKey: ["auth"],
    queryFn: () => axiosInstance.get("/api/auth/me"),
    staleTime: Infinity,
    retry: false,
  });
  let [loaded, setLoaded] = useState(false);
  let [popup, setPopup] = useState(false);
  let [profile, setProfile] = useState<any>(null);
  let [phone, setPhone] = useState({});

  useEffect(() => {
    if (response.isLoading) {
      return;
    }

    axios
      .get("/api/customers/company")
      .then((response: AxiosResponse<{ data: any }>) => {
        setPhone(response.data.data.phone_number);
      });

    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    script.onload = () => {
      let el = document.getElementById("calendly");

      if (!el) {
        return;
      }

      let content = el.innerHTML.toString();

      if (content.length > 0) {
        return;
      }

      window.Calendly.initInlineWidget({
        url:
          "https://calendly.com/vilsonduka/chat?prima\n" +
          "ry_color=136afb&hide_landing_page_details=1&hide_event_type_details=1&hide_gdpr_banner=1",
        parentElement: document.getElementById("calendly"),
        prefill: {
          name: response.data?.data?.name,
          email: response.data?.data?.email,
        },
      });
    };

    document.body.appendChild(script);

    window.addEventListener("message", (e) => {
      if (isCalendlyEvent(e)) {
        if (e.data.event === "calendly.event_scheduled") {
          window.setTimeout(() => {
            axios
              .post("/api/customer/v1/schedule-call", {
                status: "CallScheduled",
                data: JSON.stringify(e),
              })
              .then((response) => {
                nav("/");
              });
          }, 4000);
        }
      }
    });
  }, [response.isLoading]);

  if (response.isLoading) {
    return null;
  }

  return (
    <FluidContentContainer>
      <Wrapper>
        {popup && (
          <Overlay
            onClick={(event) => {
              setPopup({ popup: false });
              event.preventDefault();
            }}
          >
            <Card
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <PopupHeader>We'll call you back</PopupHeader>
              <PopupText>
                Let us know your phone number so one of our hiring consultants
                can call you to discuss your hiring needs within the next 15
                minutes.
              </PopupText>
              <Form
                onSubmit={(values) => {
                  axios
                    .post("/api/customer/v1/schedule-call", {
                      status: "CallNow",
                      phone: values.phone_number,
                    })
                    .then((response) => {
                      nav("/");
                    });
                }}
                initialValues={phone}
                render={({ handleSubmit, values }) => (
                  <form onSubmit={handleSubmit}>
                    <FormLabel>Phone Number</FormLabel>
                    <Field
                      name="phone_number"
                      component={PhoneNumberField}
                    />{" "}
                    <PhoneNumberHint>
                      We'll never share your number with anyone.
                    </PhoneNumberHint>
                    <ButtonGap />
                    <PrimaryButtonWide
                      type={"submit"}
                      disabled={!formSchema.isValidSync(values)}
                    >
                      Request Call
                    </PrimaryButtonWide>
                  </form>
                )}
              />
            </Card>
          </Overlay>
        )}
        <CalendlyContainer id="calendly" />
        <Line />
        <ButtonGroup>
          <PrimaryButtonOutline
            onClick={() => {
              axios
                .post("/api/customer/v1/schedule-call", {
                  status: "Unscheduled",
                })
                .then((response) => {
                  nav("/");
                });
            }}
          >
            <Icon>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_301_6271)">
                  <path
                    d="M1.66699 9.16667H18.3337V16.6667C18.3337 16.8877 18.2459 17.0996 18.0896 17.2559C17.9333 17.4122 17.7213 17.5 17.5003 17.5H2.50033C2.27931 17.5 2.06735 17.4122 1.91107 17.2559C1.75479 17.0996 1.66699 16.8877 1.66699 16.6667V9.16667ZM14.167 2.5H17.5003C17.7213 2.5 17.9333 2.5878 18.0896 2.74408C18.2459 2.90036 18.3337 3.11232 18.3337 3.33334V7.5H1.66699V3.33334C1.66699 3.11232 1.75479 2.90036 1.91107 2.74408C2.06735 2.5878 2.27931 2.5 2.50033 2.5H5.83366V0.833336H7.50033V2.5H12.5003V0.833336H14.167V2.5Z"
                    fill="#105CF7"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_301_6271">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </Icon>
            <span>Schedule Call Later</span>
          </PrimaryButtonOutline>
          <ButtonGap />
          <PrimaryButton
            onClick={() => {
              setPopup({ popup: true });
            }}
          >
            <Icon>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.5 11.6833V14.63C15.5001 14.841 15.4202 15.0441 15.2763 15.1985C15.1325 15.3528 14.9355 15.4469 14.725 15.4617C14.3608 15.4867 14.0633 15.5 13.8333 15.5C6.46917 15.5 0.5 9.53083 0.5 2.16667C0.5 1.93667 0.5125 1.63917 0.538333 1.275C0.553102 1.06454 0.647151 0.867509 0.801503 0.723674C0.955855 0.579839 1.15902 0.499905 1.37 0.5H4.31667C4.42003 0.499896 4.51975 0.538216 4.59644 0.607517C4.67313 0.676818 4.72133 0.772152 4.73167 0.875C4.75083 1.06667 4.76833 1.21917 4.785 1.335C4.95061 2.49077 5.29 3.61486 5.79167 4.66917C5.87083 4.83583 5.81917 5.035 5.66917 5.14167L3.87083 6.42667C4.97038 8.98871 7.01212 11.0305 9.57417 12.13L10.8575 10.335C10.91 10.2617 10.9865 10.2091 11.0737 10.1864C11.161 10.1637 11.2535 10.1723 11.335 10.2108C12.3892 10.7116 13.513 11.0501 14.6683 11.215C14.7842 11.2317 14.9367 11.25 15.1267 11.2683C15.2294 11.2789 15.3245 11.3271 15.3936 11.4038C15.4628 11.4805 15.501 11.5801 15.5008 11.6833H15.5Z"
                  fill="white"
                />
              </svg>
            </Icon>
            <span>Request a Call Now</span>
          </PrimaryButton>
        </ButtonGroup>
      </Wrapper>
    </FluidContentContainer>
  );
};

export default CustomerHomeCallSchedule;
