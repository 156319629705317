import React, { useState } from "react";
import styled from "styled-components";
import Select from "react-select";
import { FieldRenderProps } from "react-final-form";

const stylesOption = {
  container: (provided: any, state: any) => ({
    ...provided,
    width: "100%",
    height: 48,
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    color: "red",
    display: "none",
  }),
  clearIndicator: (provided: any, state: any) => ({
    ...provided,
    display: state.isMulti ? "none" : "default",
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    margin: 0,
    top: 60,
    border: "1px solid #eceff6",
    borderTop: "none",
    zIndex: 1000,
    maxHeight: 100,

    background: "#FFFFFF",
    boxShadow: "0px 2px 16px rgba(28, 39, 74, 0.06)",
    borderRadius: 6,
  }),
  menuPortal: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    margin: 0,
    top: 48,
    zIndex: 1,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: 48,
    fontFamily: "Inter",
    paddingRight: 12,
    margin: 0,
    color: "#1C274A",
    border: state.isFocused ? "#105cf7 1px solid" : "1px solid #EDEDF0",
    "&:hover": {
      borderColor: "#7aa0ff 1px solid",
    },
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    fontSize: 14,
    color: state.isDisabled ? "#999" : "#1C274A",
    letterSpacing: "0",
    fontStyle: "normal",
    fontFamily: "Inter",
    fontWeight: 400,
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    borderRadius: 6,
    padding: "2px 8px",
    backgroundColor: "#F4FBFD",
    height: 24,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  }),
  multiValueRemove: (provided: any, state: any) => ({
    ...provided,
    color: "#1D7C93",
    backgroundColor: "none",
    width: 18,
    height: 18,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      width: 18,
      height: 18,
    },
    "&:hover": {
      backgroundColor: "transparent",
      color: "pink",
      cursor: "pointer",
    },
  }),
  multiValueLabel: (provided: any, state: any) => ({
    ...provided,
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "Inter",
    color: "#1D7C93",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: 20,
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontSize: 14,
    height: 20,
    fontFamily: "Inter",
    fontWeight: 400,
    marginLeft: 0,
    color: "#858B9D",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: 12,
    fontWeight: 500,
    display: "flex",
    alignItems: "center",

    height: 48,
    backgroundColor: state.isSelected
      ? "#F8FAFF"
      : state.isFocused
      ? "#F8FAFF"
      : "white",
    color: state.isSelected
      ? "#1C274A"
      : state.isFocused
      ? "#1C274A"
      : "#1C274A",
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
  }),
};

const CustomDropdownIndicator = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 12.5001L6.46417 8.96422L7.64334 7.78589L10 10.1434L12.3567 7.78589L13.5358 8.96422L10 12.5001Z"
        fill="#9FA4B3"
      />
    </svg>
  );
};

const Container = styled.div`
  width: 100%;
  height: 48px;
`;

type Props = FieldRenderProps<string, any>;

const SelectField: React.FC<Props> = (props) => {
  return (
    <Container>
      <Select
        components={{ DropdownIndicator: CustomDropdownIndicator }}
        styles={stylesOption}
        options={props.options}
        placeholder={props.placeholder}
        isDisabled={props.disabled}
        {...props.input}
        {...props}
      />
    </Container>
  );
};

export default SelectField;
