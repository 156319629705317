import React, { useState } from "react";
import styled from "styled-components";
import { Field } from "react-final-form";
import PrefixTextInputField from "src/Modules/Shared/Components/Temp/PrefixTextInputField";

const Container = styled.div``;

const ClickLink = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Layover = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: rgba(0, 0, 0, 0.5);

  position: fixed;
  z-index: 200;

  width: 100vw;
  height: 100dvh;

  top: 0;
  left: 0;

  padding: 60px 8px;

  overflow-y: auto;
`;

const Card = styled.div`
  width: 560px;
  max-width: 96vw;

  display: flex;
  flex-direction: column;

  padding: 24px;

  background: white;

  border-radius: 12px;

  position: relative;

  gap: 12px;
`;

const Title = styled.div`
  ont-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const Description = styled.div`
  background: #fff8e2;
  border-radius: 6px;
  padding: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: 24px;
`;

const BackButton = styled.button`
  height: 48px;
  padding: 12px 32px;
  background: none;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid transparent;
  }
`;

export const PrimaryButton = styled.button`
  height: 48px;
  padding: 12px 32px;
  background: #105cf7;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #ffffff;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 8px;

  svg {
    fill: #ffffff;
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    background: #ededf0;
    color: #9fa4b3;
    cursor: default;
    border: none;
    svg {
      fill: #9fa4b3;
    }
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 48px;
`;

const Input = styled.input`
  padding: 12px;

  width: 100%;
  height: 48px;

  background: #ffffff;
  /* Notch/Neutral/100 */

  border: 1px solid #ededf0;
  border-radius: 0 6px 6px 0;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;

  &:focus {
    box-shadow: 0px 0px 0px 2px #e6eefe;
    border: 1px solid #105cf7;
    outline: none;
  }

  &:disabled {
    background: #fafafb;
    color: #9fa4b3;
  }
`;

const Prefix = styled.div`
  background: #fafafb;
  width: auto;
  padding: 0 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: -0.03em;

  /* Notch/Neutral/400 */

  color: #9fa4b3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  border-radius: 6px 0 0 6px;
  border: 1px solid #ededf0;
  border-right: none;
`;

const AddJobLink: React.FC = (props) => {
  let [popup, setPopup] = useState(false);
  let [link, setLink] = useState(props.initialLink ?? "");

  let form = props.form;

  return (
    <Container>
      {popup && (
        <Layover>
          <Card>
            <Title>Add Job Link</Title>
            <InputContainer>
              <Prefix>https://</Prefix>
              <Input
                placeholder={"Ex: www.linkedin.com/job/id=190213113"}
                value={link}
                onChange={(event) => {
                  setLink(event.target.value);
                }}
              />
            </InputContainer>
            <Description>
              Add the URL of any existing job post and we’ll automatically
              create your job post on Notch within a few hours. Once you add a
              job link, no fields are required but please make sure to include
              any information that isn’t in your existing job post.
            </Description>
            <ButtonGroup>
              <BackButton
                onClick={() => {
                  setPopup(false);
                }}
              >
                Close
              </BackButton>
              <PrimaryButton
                onClick={() => {
                  form.change("jobLink", link);

                  setPopup(false);
                }}
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.16663 5.16675V0.166748H6.83329V5.16675H11.8333V6.83342H6.83329V11.8334H5.16663V6.83342H0.166626V5.16675H5.16663Z"
                    fill="white"
                  />
                </svg>
                Add
              </PrimaryButton>
            </ButtonGroup>
          </Card>
        </Layover>
      )}
      <ClickLink onClick={() => setPopup(true)}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.16669 9.16663V4.16663H10.8334V9.16663H15.8334V10.8333H10.8334V15.8333H9.16669V10.8333H4.16669V9.16663H9.16669Z"
            fill="#105CF7"
          />
        </svg>
        Add Job Link
      </ClickLink>
    </Container>
  );
};

export default AddJobLink;
