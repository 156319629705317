import React, { ReactNode, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
`;

type Props = {
  popup: ReactNode;
  children: ReactNode;
};

const PopupContainer: React.FC<Props> = (props) => {
  let [display, setDisplay] = useState(false);

  return (
    <Container
      onClick={() => {
        setDisplay(!display);
      }}
      onMouseEnter={() => {
        setDisplay(true);
      }}
      onMouseLeave={() => {
        setDisplay(false);
      }}
    >
      <>
        {display && props.popup}
        {props.children}
      </>
    </Container>
  );
};

export default PopupContainer;
