import React, { useState } from "react";
import styled from "styled-components";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PrimaryButtonWide } from "src/Modules/Shared/Components/Temp/ApplicationFlowComponents";
import PopupOverlay from "src/Modules/Core/Components/Experimental/PopupOverlay";
import { Field, Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import axios from "axios";
import ShortlistDeclineReasonsField from "src/Modules/Customer/Components/Jobs/ShortlistDeclineReasonsField";
import AreaInputField from "src/Modules/Shared/Components/Temp/AreaInputField";
import Button from "src/Modules/Core/Components/Atoms/Button/Button";
import { CustomerApi } from "src/Modules/Customer/Services/CustomerApi";

const CardTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.04em;
  color: #1c274a;
`;

const CardButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  margin-top: 16px;
`;

const CardContent = styled.div`
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const CardInfoText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const Profile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  img {
    width: 30px;
    aspect-ratio: 1;
    border-radius: 999px;
  }

  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.03em;
    color: #1c274a;
  }
`;

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 200;
  width: 100vw;
  height: 100dvh;
  top: 0;
  left: 0;
  padding: 60px 8px;
  overflow-y: auto;
`;

const Card = styled.div`
  width: 544px;
  max-width: 96vw;

  padding: 24px;

  background: white;

  border-radius: 12px;

  position: relative;

  gap: 12px;
`;

const NotInterestedButton = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 48px;
  cursor: pointer;
`;

const CandidateDeclineAction: React.FC = (props) => {
  const [declinePopup, setDeclinePopup] = useState(false);
  const queryClient = useQueryClient();

  let actionMutation = useMutation({
    mutationFn: CustomerApi.candidates.id(props.data.id).action,
    onSuccess: () => {
      queryClient.invalidateQueries(["jobs"]);
      setDeclinePopup(false);
    },
  });

  let data = props.data;

  return (
    <>
      <NotInterestedButton onClick={() => setDeclinePopup(true)}>
        Not interested
      </NotInterestedButton>
      <PopupOverlay
        active={declinePopup}
        onClose={() => setDeclinePopup(false)}
      >
        {({ onClose }) => (
          <Card>
            <Form
              mutators={{ ...arrayMutators }}
              onSubmit={(values) => {
                values.action = "Decline";
                actionMutation.mutate(values);
              }}
              initialValues={{}}
              render={({ handleSubmit, values, form }) => (
                <form onSubmit={handleSubmit}>
                  <CardContent>
                    <CardTitle>Not interested</CardTitle>
                    <Profile>
                      <img src={data.legacyProfile.profileBase} />
                      <span>{data.overwrites.Name}</span>
                    </Profile>
                    <CardInfoText>
                      Thank you for your feedback. Please share any areas where
                      this candidates is not a fit so that we can better adjust
                      your matching moving forward.
                    </CardInfoText>
                    <Field
                      name={"reasons.tags"}
                      component={ShortlistDeclineReasonsField}
                      isMulti={true}
                      options={[
                        {
                          label: "Years of experience",
                          value: "Years of experience",
                        },
                        {
                          label: "Domain expertise",
                          value: "Domain expertise",
                        },
                        {
                          label: "Location / Timezone",
                          value: "Location / Timezone",
                        },
                        {
                          label: "Required skill experience",
                          value: "Required skill experience",
                        },
                        { label: "Salary / Rate", value: "Salary / Rate" },
                        {
                          label: "Company size/stage experience",
                          value: "Company size/stage experience",
                        },
                        {
                          label: "Time at previous jobs",
                          value: "Time at previous jobs",
                        },
                        { label: "Education", value: "Education" },
                        { label: "Other reasons", value: "Other reasons" },
                      ]}
                    />
                    <Field
                      name="reasons.comment"
                      placeholder="Add additional comments"
                      component={AreaInputField}
                    />
                    <CardButtonGroup>
                      <Button
                        variant="outline"
                        size="fullwidth"
                        onClick={(event) => {
                          setDeclinePopup(false);
                          event.preventDefault();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button size="fullwidth">Confirm</Button>
                    </CardButtonGroup>
                  </CardContent>
                </form>
              )}
            />
          </Card>
        )}
      </PopupOverlay>
    </>
  );
};

export default CandidateDeclineAction;
