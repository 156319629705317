const AThirdSize = "188px";
const HalfSizeWidth = 286;
const HalfSize = "280px";

const options = {
  companySize: [
    {
      value: "1 - 10",
      label: "1-10 employees",
      width: AThirdSize,
    },
    {
      value: "11 - 50",
      label: "11-50 employees",
      width: AThirdSize,
    },
    {
      value: "51 - 200",
      label: "51-200 employees",
      width: AThirdSize,
    },
    {
      value: "201 - 1000",
      label: "201-1000 employees",
      width: "280px",
    },
    {
      value: "1000+",
      label: "1000+ employees",
      width: "280px",
    },
  ],
  roles: [
    {
      value: "Fullstack",
      label: "Fullstack Engineer",
      width: AThirdSize,
    },
    {
      value: "Backend",
      label: "Backend Engineer",
      width: AThirdSize,
    },
    {
      value: "Frontend",
      label: "Frontend Engineer",
      width: AThirdSize,
    },
    {
      value: "DevOps",
      label: "DevOps Engineer",
      width: AThirdSize,
    },
    {
      value: "Mobile",
      label: "Mobile Engineer",
      width: AThirdSize,
    },
    { value: "Data", label: "Data Engineer", width: AThirdSize },
  ],
  mainSkills: [
    {
      label: "Typescript",
      value: "mj1ueb2t",
      logoPath: "typescript-plain.svg",
      width: AThirdSize,
    },
    {
      label: "React.js",
      value: "axh88v7l",
      logoPath: "react-original.svg",
      width: AThirdSize,
    },
    {
      label: "Python",
      value: "bg1r6fap",
      logoPath: "python-plain.svg",
      width: AThirdSize,
    },
    {
      label: "Angular",
      value: "lmzgqc2r",
      logoPath: "angularjs-plain.svg",
      width: AThirdSize,
    },
    {
      label: "Ruby",
      value: "spkkldtu",
      logoPath: "rails-plain.svg",
      width: AThirdSize,
    },
    {
      label: "JavaScript",
      value: "qwfzrzky",
      logoPath: "javascript-plain.svg",
      width: AThirdSize,
    },
    {
      label: "React Native",
      value: "xeehwdai",
      logoPath: "react-original.svg",
      width: AThirdSize,
    },
    {
      label: "Android",
      value: "kuataftx",
      logoPath: "android-plain.svg",
      width: AThirdSize,
    },
    {
      label: "Vue.js",
      value: "bnjlsama",
      logoPath: "vuejs-original.svg",
      width: AThirdSize,
    },
  ],
  requiredDevelopersNumber: [
    { label: "1 Developer", value: "1_developer", width: "31%" },
    { label: "2-4 Developers", value: "2to4_developers", width: "31%" },
    { label: "5+ Developers", value: "5plus_developers", width: "31%" },
  ],
  jobType: [
    {
      value: "Contractor",
      label: "Contractor",
      width: AThirdSize,
    },
    {
      value: "Employee",
      label: "Employee",
      width: AThirdSize,
    },
    {
      value: "Open to either",
      label: "Open to either",
      width: AThirdSize,
    },
  ],
  hours: [
    {
      value: "Full-time",
      label: "Full-time",
      width: AThirdSize,
    },
    {
      value: "Part-time",
      label: "Part-time",
      width: AThirdSize,
    },
    {
      value: "Open to either",
      label: "Open to either",
      width: AThirdSize,
    },
  ],
  startDate: [
    {
      value: "As soon as possible",
      label: "As soon as possible",
      width: HalfSize,
    },
    {
      value: "2-4 weeks form now",
      label: "2-4 weeks from now",
      width: HalfSize,
    },
    {
      value: "More than a month from now",
      label: "More than a month from now",
      width: HalfSize,
    },
    {
      value: "Not Sure",
      label: "Not sure",
      width: HalfSize,
    },
  ],
  isLocationRequired: [
    {
      value: "Yes",
      label: "Yes",
      width: "45.5%",
    },
    {
      value: "No",
      label: "No",
      width: "45.5%",
    },
  ],
  requiredRegions: [
    { value: "United States", label: "United States" },
    { value: "North America", label: "North America" },
    { value: "Central America", label: "Central America" },
    { value: "Latin America", label: "Latin America" },
    { value: "Europe", label: "Europe" },
    { value: "Asia", label: "Asia" },
    { value: "Middle East", label: "Middle East" },
    { value: "Africa", label: "Africa" },
    { value: "Oceania", label: "Oceania" },
    { value: "Caribbean", label: "Caribbean" },
  ],
  longTerm: [
    {
      value: "Yes",
      label: "Yes",
      width: "45.5%",
    },
    {
      value: "No",
      label: "No",
      width: "45.5%",
    },
  ],
  longTermOption: [
    { value: "Less than 1 month", label: "Less than 1 month" },
    { value: "1-3 months", label: "1-3 months" },
    { value: "4-6 months", label: "4-6 months" },
    { value: "7-9 month", label: "7-9 month" },
    { value: "9-12 months", label: "9-12 months" },
    { value: "12+ months", label: "12+ months" },
  ],
};

export default options;
