import React from "react";
import styled from "styled-components";
import { formatDistanceToNow, isDate } from "date-fns";

const Container = styled.div`
  display: inline;
`;

const AsRelativeTime: React.FC = (props) => {
  let time = props.children;

  if (Array.isArray(time)) {
    return Error("Arrays are not accepted as children for Component");
  }

  if (!isDate(time)) {
    return Error("Not a datetime");
  }

  let relativeTime = formatDistanceToNow(time);
  let i = relativeTime;
  i = i.replace(" minutes", "m");
  i = i.replace("a minute", "1m");
  i = i.replace("1 minute", "1m");
  i = i.replace(" hours", "h");
  i = i.replace("a hour", "1h");
  i = i.replace("1 hour", "1h");
  i = i.replace(" days", "d");
  i = i.replace("a day", "1d");
  i = i.replace("1 day", "1d");
  i = i.replace(" months", "mo");
  i = i.replace(" month", "mo");

  i = i.replace("1 year", "1y");
  i = i.replace(" years", "y");

  return <Container>{i} ago</Container>;
};

export default AsRelativeTime;
