import React from "react";
import styled from "styled-components";
import { FieldRenderProps } from "react-final-form";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 660px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
    height: auto;
    min-height: 48px;
    gap: 1px;
  }
`;

const ItemContainer = styled.div<{ size: any }>`
  padding: 12px;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #ededf0;
  border-radius: 6px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 100%;

  @media screen and (min-width: 660px) {
    padding: 12px;
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #ededf0;
    border-radius: 6px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 184px;
  }

  &.active {
    border: 1px solid #105cf7;
  }

  &:hover {
    border: 1px solid #105cf7;
  }
`;

const ContainerFixed = styled(Container)`
  ${ItemContainer} {
    margin-bottom: 12px;
  }

  @media screen and (min-width: 660px) {
    ${ItemContainer} {
      margin-right: 12px;
    }
  }
`;

const Icon = styled.div`
  width: 20px;
  height: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

const Label = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: #1c274a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

type Item = { value: string; label: string; width: any };

type Props = FieldRenderProps<string, any> & { spacing: "fixed" | "full" };

const isSelected = (currentItem: string, currentSelection: string) => {
  return currentItem == currentSelection;
};

const PreferredRoleField: React.FC<Props> = (props) => {
  let Element = ContainerFixed;
  if (props.spacing === "fixed") {
    Element = ContainerFixed;
  }

  return (
    <Element>
      {props.options.map((item: Item) => {
        return (
          <ItemContainer
            size={item.width}
            onClick={() => props.input.onChange(item.value)}
            className={
              isSelected(item.value, props.input.value) ? "active" : ""
            }
          >
            <Icon>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.1673 9.99968L13.2748 15.8922L12.0965 14.7138L16.8107 9.99968L12.0965 5.28551L13.2748 4.10718L19.1673 9.99968ZM3.19065 9.99968L7.90482 14.7138L6.72648 15.8922L0.833984 9.99968L6.72648 4.10718L7.90482 5.28551L3.19065 9.99968Z"
                  fill="#1C274A"
                />
              </svg>
            </Icon>
            <Label>{item.label}</Label>
          </ItemContainer>
        );
      })}
    </Element>
  );
};

export default PreferredRoleField;
