import React from "react";
import styled from "styled-components";
import { FieldRenderProps } from "react-final-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Container = styled(DatePicker)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;

  width: 100%;
  height: 48px;

  background: #ffffff;
  /* Notch/Neutral/100 */

  border: 1px solid #ededf0;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;

  &:focus {
    box-shadow: 0px 0px 0px 2px #e6eefe;
    border: 1px solid #105cf7;
    outline: none;
  }

  &:disabled {
    border: 1px solid var(--notch-neutral-100, #ededf0);
    background: var(--notch-neutral-100, #ededf0);
    color: var(--notch-neutral-500, #858b9d);
  }

  &::placeholder {
    color: #9fa4b3 !important;
  }
`;

type Props = FieldRenderProps<string, any>;

const DatePickerField: React.FC<Props> = (props) => {
  let value = props.input.value;
  let onChange = props.input.onChange;

  return (
    <Container
      mode="single"
      dateFormat="P"
      selected={value}
      {...props.input}
      {...props}
    />
  );
};

export default DatePickerField;
