import React from "react";
import styled from "styled-components";
import skillOptions from "src/Modules/Shared/Components/Temp/skillOptions";
import CandidateSkills from "src/Modules/Shared/Components/Shortlist/CandidateCard/CandidateSkills";
import CandidateSummary from "src/Modules/Shared/Components/Shortlist/CandidateCard/CandidateSummary";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { CustomerApi } from "src/Modules/Customer/Services/CustomerApi";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  max-width: 100ch;
  flex-grow: 1;
  min-width: 100px;

  @media screen and (min-width: 1000px) {
    margin-left: 16px;
  }
`;

const ProfileInfoLine = styled.div`
  display: flex;
  flex-direction: row;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const InfoLineIcon = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  svg {
  }
`;

const InfoLineText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: -0.03em;

  /* Notch/Neutral/900 */

  color: #1c274a;
`;

const DataCardHeader = styled.div`
  /* Notch/Label/Large */

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  /* identical to box height, or 178% */

  letter-spacing: -0.03em;

  /* Notch/Neutral/900 */

  color: #1c274a;
  margin-bottom: 8px;
`;

const SkillList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin: 0px 0;
  height: fit-content;
  margin-bottom: 16px;
`;

const Summary = styled.div`
  width: 100%;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;

  color: #1c274a;
  margin-bottom: 16px;
`;

const CriteriaCard = styled.div`
  padding: 16px;
  background: #fafafb;
  border: 1px solid #ededf0;
  border-radius: 12px;

  display: flex;
  flex-direction: column;
`;

const CriteriaTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.06px;
  text-transform: uppercase;
  color: #1c274a;
  margin-bottom: 12px;
`;

const Skill = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  height: 32px;
  background: #f4fbfd;
  border-radius: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: #1d7c93;
  margin-right: 12px;
  margin-bottom: 12px;
`;

const SkillLogo = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const SkillByCode = (code) => {
  const s = skillOptions.find((i) => {
    return i.value == code;
  });

  return (
    <Skill>
      <SkillLogo
        src={`https://app.notchteam.com/images/icons/skills/${s.logoPath}`}
      />
      {s.label}
    </Skill>
  );
};

const CandidateDataSection: React.FC = (props) => {
  let draftOverwrites = props.data.dataOverwrites;

  return (
    <Container>
      <DataCardHeader>Skills Match</DataCardHeader>
      <SkillList>
        <CandidateSkills {...props} />
      </SkillList>
      <DataCardHeader>Summary</DataCardHeader>
      <CandidateSummary {...props} />

      <CriteriaCard>
        {draftOverwrites.MatchCriteriaLines.map((i) => {
          return (
            <ProfileInfoLine>
              <InfoLineIcon>
                <svg
                  width="15"
                  height="11"
                  viewBox="0 0 15 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.33336 8.64338L12.9934 0.982544L14.1725 2.16088L5.33336 11L0.0300293 5.69671L1.20836 4.51838L5.33336 8.64338Z"
                    fill="#00C181"
                  />
                </svg>
              </InfoLineIcon>
              <InfoLineText>{i}</InfoLineText>
            </ProfileInfoLine>
          );
        })}
      </CriteriaCard>
    </Container>
  );
};

export default CandidateDataSection;
