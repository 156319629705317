import React, { HTMLProps, PropsWithChildren } from "react";
import {
  button,
  ButtonVariants,
} from "src/Modules/Core/Components/Atoms/Button/button.css";
import Box from "src/Modules/Core/Components/Atoms/Box/Box";

type Props = {} & ButtonVariants & React.ComponentPropsWithRef<"button">;

const Button: React.FC<Props> = (props) => {
  let { size, variant } = props;

  return (
    <button className={button({ size, variant })} {...props}>
      {props.children}
    </button>
  );
};

export default Button;
