import styled from "styled-components";

export const UpperPart = styled.div`
  display: flex;
  min-height: 410px;
  flex-direction: column;
  @media screen and (min-width: 1000px) {
    flex-direction: row;
  }
`;

export const ProfileCard = styled.div`
  border: 1px solid #ededf0;
  width: 100%;

  border-radius: 12px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  @media screen and (min-width: 1000px) {
    min-width: 320px;
    max-width: 320px;
    margin-bottom: 0;
  }
`;
