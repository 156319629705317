import React from "react";
import styled from "styled-components";
import ApplicationFlowStepPersonalDetails from "src/Modules/Shared/Components/Temp/ApplicationFlowStepPersonalDetails";
import ApplicationFlowStepWorkPreference from "src/Modules/Shared/Components/Temp/ApplicationFlowStepWorkPreference";
import ApplicationFlowStepSkillExperience from "src/Modules/Shared/Components/Temp/ApplicationFlowStepSkillExperience";

const Container = styled.div`
  grid-area: form;
  width: 650px;
  max-width: 100%;
`;

type Props = {
  setStep: (step: number) => void;

  data: any;
  setData: (value: any) => void;
};

const TalentApplicationFlowForm: React.FC<Props> = (props) => {
  return (
    <Container>
      {props.data?.step == 2 && (
        <ApplicationFlowStepPersonalDetails {...props} />
      )}
      {props.data?.step == 3 && (
        <ApplicationFlowStepSkillExperience {...props} />
      )}
      {props.data?.step == 4 && (
        <ApplicationFlowStepWorkPreference {...props} />
      )}
    </Container>
  );
};

export default TalentApplicationFlowForm;
