import React from "react";
import styled from "styled-components";
import CandidateStatusTag from "src/Modules/Shared/Components/Shortlist/CandidateCard/CandidateStatusTag";
import { formatDistanceToNow } from "date-fns";
import AsRelativeTime from "src/Modules/Shared/Components/AsRelativeTime";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ViewProfileLink = styled.a`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #105cf7;
  margin: 0 12px 0 auto;
`;

const MatchedAt = styled.div`
  color: var(--notch-neutral-500, #858b9d);
  font-size: 14px;
  font-family: Inter;
  line-height: 24px;
  letter-spacing: -0.42px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-left: 8px;
`;

const CandidateCardFooter: React.FC = (props) => {
  let publishedAt = null;

  if (props.data?.publishedAt != undefined) {
    publishedAt = new Date(props.data?.publishedAt);
  }

  return (
    <Container>
      <CandidateStatusTag {...props} />
      {publishedAt && (
        <MatchedAt>
          <AsRelativeTime>{publishedAt}</AsRelativeTime>
        </MatchedAt>
      )}
      <ViewProfileLink target="_blank" href={`/candidates/${props.data.id}`}>
        View Full Profile
      </ViewProfileLink>
    </Container>
  );
};

export default CandidateCardFooter;
