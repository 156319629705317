import axios, { AxiosError, AxiosResponse } from "axios";

const isDev = import.meta.env.DEV;

const axiosInstance = axios.create({
  baseURL: "/",
  timeout: 20000,
  headers: {
    "X-API-CALL": "1",
  },
});

axiosInstance.interceptors.response.use(
  (response: AxiosResponse<any, any>) => {
    return response;
  },
  (error) => {
    if (error.response?.status >= 500) {
      if (import.meta.env.MODE != "development") {
        alert(error.message);
      }
    }
    if (error.response?.status == 401) {
      //      location.href = "/";
    }

    throw new Error(error);
  }
);

export default axiosInstance;
