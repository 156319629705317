import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const transitionTime = 300;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: fixed;
  z-index: 2000;

  width: 100vw;
  height: 100dvh;

  top: 0;
  left: 0;

  &.center {
    justify-content: center;
  }

  &.scroll {
    padding: 60px 8px;
    overflow-y: auto;
  }

  background-color: rgba(0, 0, 0, 0.1);

  transition: background-color ${transitionTime}ms ease-in;

  &.active {
    background-color: rgba(0, 0, 0, 0.6);
    transition: background-color ${transitionTime}ms ease-in;
  }
`;

const PopupContent = styled.div`
  opacity: 0;
  transform: translateY(-40px);
  transition: opacity ${transitionTime}ms ease-in,
    transform ${transitionTime}ms ease-in;

  &.active {
    opacity: 1;
    transform: translateY(0);
  }
`;

type PopupOverlayProps = {
  active: boolean;
  onClose: () => void;
};

const PopupOverlay: React.FC<PopupOverlayProps> = (props) => {
  let [classNames, setClassNames] = useState("");
  let [active, setActive] = useState("initial");

  useEffect(() => {
    if (active == "initial" && props.active == true) {
      // window.setTimeout(() => {
      setActive("active");
      // }, 1);
    }

    let element = document.getElementById("popover");
    if (!element) {
      return;
    }

    let children = element.children;
    let content = children[0];

    if (!content) {
      return;
    }

    const calculateOverlayClasses = () => {
      let clientHeight = content.clientHeight;
      let windowHeight = window.innerHeight;

      if (clientHeight + 64 > windowHeight) {
        setClassNames("scroll");
      } else {
        setClassNames("center");
      }
    };

    let observer = new ResizeObserver(calculateOverlayClasses);

    observer.observe(content);

    window.addEventListener("resize", calculateOverlayClasses);
    calculateOverlayClasses();

    // Listen for the escape key
    const onEscapePress = (event: KeyboardEvent) => {
      if (event.key == "Escape") {
        setActive("initial");
        setTimeout(() => {
          props.onClose();
        }, transitionTime);
      }
    };

    window.addEventListener("keydown", onEscapePress);

    return () => {
      window.removeEventListener("resize", calculateOverlayClasses);
      window.removeEventListener("keydown", onEscapePress);
      observer.disconnect();
    };
  }, [props.active]);

  if (!props.active) {
    return null;
  }

  return (
    <Container
      id="popover"
      className={[classNames, active].join(" ")}
      onClick={() => {
        setActive("initial");
        setTimeout(() => {
          props.onClose();
        }, transitionTime);
      }}
    >
      <PopupContent
        className={active}
        id="popup_content"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {props.children({
          onClose: () => {
            setActive("initial");
            setTimeout(() => {
              props.onClose();
            }, transitionTime);
          },
        })}
      </PopupContent>
    </Container>
  );
};

export default PopupOverlay;
