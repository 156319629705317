import React from "react";
import styled from "styled-components";
import { FieldRenderProps } from "react-final-form";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 660px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
    height: auto;
    min-height: 48px;
  }
`;

const ItemContainer = styled.div`
  padding: 12px;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #ededf0;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  @media screen and (min-width: 660px) {
    padding: 12px;
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #ededf0;
    border-radius: 6px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: ${(props: { size: any }) => props.size ?? "auto"};
  }

  &.active {
    border: 1px solid #105cf7;
  }

  &:hover {
    border: 1px solid #105cf7;
  }
`;

const ContainerFixed = styled(Container)`
  ${ItemContainer} {
    margin-bottom: 12px;
  }

  @media screen and (min-width: 660px) {
    ${ItemContainer} {
      margin-right: 12px;
    }
  }
`;

const Icon = styled.div`
  width: 20px;
  height: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

const Label = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: #1c274a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  display: flex;
  align-items: center;
  letter-spacing: -0.03em;

  padding-left: 30px;
  padding-top: 4px;

  /* Notch/Neutral/600 */

  color: #6a7288;
`;

const Title = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  display: flex;
  align-items: center;
  letter-spacing: -0.03em;

  /* Notch/Neutral/900 */

  color: #1c274a;
`;

const UpperSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const LowerSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

type Item = { value: string; label: string; width: any };

type Props = FieldRenderProps<string, any> & { spacing: "fixed" | "full" };

const isSelected = (currentItem: string, currentSelection: string) => {
  return currentItem == currentSelection;
};

const EnglishSelectField: React.FC<Props> = (props) => {
  let Element = ContainerFixed;
  if (props.spacing === "fixed") {
    Element = ContainerFixed;
  }

  let options = [
    {
      title: "Fluent",
      label:
        "I can communicate well in verbal or written form and easily comprehend discussions with my teammates.",
    },
    {
      title: "Average",
      label:
        "I can communicate verbally or in written form with teammates, but may require things to be spoken slower than normal and require additional explanations or repeated sentences.",
    },
    {
      title: "Basic",
      label:
        "I can communicate with teammates primarily over written forms like email and Slack.  Verbal communication would be not be practical due to speed and lack of comprehension.",
    },
  ];

  return (
    <Element>
      {options.map((item: any) => {
        return (
          <ItemContainer
            key={item.title}
            size={null}
            onClick={() => props.input.onChange(item.title)}
            className={
              isSelected(item.title, props.input.value) ? "active" : ""
            }
          >
            <UpperSection>
              <Icon>
                {isSelected(item.title, props.input.value) ? (
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.00008 17.3334C4.39758 17.3334 0.666748 13.6026 0.666748 9.00008C0.666748 4.39758 4.39758 0.666748 9.00008 0.666748C13.6026 0.666748 17.3334 4.39758 17.3334 9.00008C17.3334 13.6026 13.6026 17.3334 9.00008 17.3334ZM9.00008 15.6667C10.7682 15.6667 12.4639 14.9644 13.7141 13.7141C14.9644 12.4639 15.6667 10.7682 15.6667 9.00008C15.6667 7.23197 14.9644 5.53628 13.7141 4.28604C12.4639 3.03579 10.7682 2.33341 9.00008 2.33341C7.23197 2.33341 5.53628 3.03579 4.28604 4.28604C3.03579 5.53628 2.33341 7.23197 2.33341 9.00008C2.33341 10.7682 3.03579 12.4639 4.28604 13.7141C5.53628 14.9644 7.23197 15.6667 9.00008 15.6667ZM9.00008 13.1667C7.89501 13.1667 6.8352 12.7278 6.0538 11.9464C5.2724 11.165 4.83341 10.1052 4.83341 9.00008C4.83341 7.89501 5.2724 6.8352 6.0538 6.0538C6.8352 5.2724 7.89501 4.83341 9.00008 4.83341C10.1052 4.83341 11.165 5.2724 11.9464 6.0538C12.7278 6.8352 13.1667 7.89501 13.1667 9.00008C13.1667 10.1052 12.7278 11.165 11.9464 11.9464C11.165 12.7278 10.1052 13.1667 9.00008 13.1667Z"
                      fill="#105CF7"
                    />
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0001 18.3334C5.39758 18.3334 1.66675 14.6026 1.66675 10.0001C1.66675 5.39758 5.39758 1.66675 10.0001 1.66675C14.6026 1.66675 18.3334 5.39758 18.3334 10.0001C18.3334 14.6026 14.6026 18.3334 10.0001 18.3334ZM10.0001 16.6667C11.7682 16.6667 13.4639 15.9644 14.7141 14.7141C15.9644 13.4639 16.6667 11.7682 16.6667 10.0001C16.6667 8.23197 15.9644 6.53628 14.7141 5.28604C13.4639 4.03579 11.7682 3.33341 10.0001 3.33341C8.23197 3.33341 6.53628 4.03579 5.28604 5.28604C4.03579 6.53628 3.33341 8.23197 3.33341 10.0001C3.33341 11.7682 4.03579 13.4639 5.28604 14.7141C6.53628 15.9644 8.23197 16.6667 10.0001 16.6667Z"
                      fill="#B8BCC7"
                    />
                  </svg>
                )}
              </Icon>
              <Title>{item.title}</Title>
            </UpperSection>
            <LowerSection>
              <Label>{item.label}</Label>
            </LowerSection>
          </ItemContainer>
        );
      })}
    </Element>
  );
};

export default EnglishSelectField;
