import React from "react";
import styled from "styled-components";
import { FieldRenderProps } from "react-final-form";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Textarea = styled.textarea`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;

  width: 100%;
  height: 100px;
  background: #ffffff;
  border: 1px solid #ededf0;
  border-radius: 6px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #1c274a;

  resize: vertical;
  position: relative;

  &:focus {
    box-shadow: 0px 0px 0px 2px #e6eefe;
    border: 1px solid #105cf7;
    outline: none;
  }
`;

const MaxLengthIndicator = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: -0.03em;
  width: max-content;
  color: #9fa4b3;
  margin-left: auto;
  text-align: right;

  position: absolute;

  right: 2px;
  bottom: -22px;
`;

type Props = FieldRenderProps<string, any>;

const TextareaField: React.FC<Props> = (props) => {
  return (
    <Container>
      <Textarea {...props.input} {...props}></Textarea>
      {props.maxLength && (
        <MaxLengthIndicator>
          {props.input.value.length} / {props.maxLength}
        </MaxLengthIndicator>
      )}
    </Container>
  );
};

export default TextareaField;
