import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
`;

const Label = styled.label`
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  & i {
    position: relative;
    display: inline-block;
    margin-right: 0;
    width: 32px;
    height: 17px;
    background-color: #ededf0;
    border-radius: 12px;
    vertical-align: text-bottom;
    transition: all 0.3s linear;
  }

  & i::after {
    content: "";
    position: absolute;
    left: 0;
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 999px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    transform: translate3d(2px, 2px, 0);
    transition: all 0.2s ease-in-out;
  }

  &:active input:checked + i::after {
    transform: translate3d(16px, 2px, 0);
  }
  & input {
    display: none;
  }
  & input:checked + i {
    background-color: #105cf7;
  }
  & input:checked + i::before {
    transform: translate3d(1px, 2px, 0) scale3d(0, 0, 0);
  }
  & input:checked + i::after {
    transform: translate3d(17px, 2px, 0);
  }
`;

const ToggleSwitchField: React.FC = (props) => {
  let currentValue = props.input.value ?? false;
  return (
    <Container>
      <Label className={".form-switch"}>
        <input
          type={"checkbox"}
          checked={currentValue}
          onChange={(value) => {
            props.input.onChange(!currentValue);
          }}
        />
        <i />
        {props.label}
      </Label>
    </Container>
  );
};

export default ToggleSwitchField;
