import { JobDetailsType } from "src/Modules/Core/Types/JobTypes";
import * as yup from "yup";

const getSelection = (value: any, options: any) => {
  return options.find((i: any) => {
    return i.value == value;
  });
};

export const JobDetailsOptions = {
  OverlappingTimeTotalHoursOptions: [
    {
      value: "anytime_8",
      label: "8+ hours anytime between",
      hours: 8,
      type: "anytime",
    },
    {
      value: "specific_8",
      label: "8 hours specifically from",
      hours: 8,
      type: "specific",
    },
    {
      value: "anytime_7",
      label: "7+ hours anytime between",
      hours: 7,
      type: "anytime",
    },
    {
      value: "specific_7",
      label: "7 hours specifically from",
      hours: 7,
      type: "specific",
    },
    {
      value: "anytime_6",
      label: "6+ hours anytime between",
      hours: 6,
      type: "anytime",
    },
    {
      value: "specific_6",
      label: "6 hours specifically from",
      hours: 6,
      type: "specific",
    },
    {
      value: "anytime_5",
      label: "5+ hours anytime between",
      hours: 5,
      type: "anytime",
    },
    {
      value: "specific_5",
      label: "5 hours specifically from",
      hours: 5,
      type: "specific",
    },
    {
      value: "anytime_4",
      label: "4+ hours anytime between",
      hours: 4,
      type: "anytime",
    },
    {
      value: "specific_4",
      label: "4 hours specifically from",
      hours: 4,
      type: "specific",
    },
    {
      value: "anytime_3",
      label: "3+ hours anytime between",
      hours: 3,
      type: "anytime",
    },
    {
      value: "specific_3",
      label: "3 hours specifically from",
      hours: 3,
      type: "specific",
    },
    {
      value: "anytime_2",
      label: "2+ hours anytime between",
      hours: 2,
      type: "anytime",
    },
    {
      value: "specific_2",
      label: "2 hours specifically from",
      hours: 2,
      type: "specific",
    },
    {
      value: "anytime_1",
      label: "1+ hour anytime between",
      hours: 1,
      type: "anytime",
    },
    {
      value: "specific_1",
      label: "1 hour specifically from",
      hours: 1,
      type: "specific",
    },
  ],
  OverlappingTimeStartEndOptions: [
    { value: 0, label: "12:00 am" },
    { value: 1, label: "1:00 am" },
    { value: 2, label: "2:00 am" },
    { value: 3, label: "3:00 am" },
    { value: 4, label: "4:00 am" },
    { value: 5, label: "5:00 am" },
    { value: 6, label: "6:00 am" },
    { value: 7, label: "7:00 am" },
    { value: 8, label: "8:00 am" },
    { value: 9, label: "9:00 am" },
    { value: 10, label: "10:00 am" },
    { value: 11, label: "11:00 am" },
    { value: 12, label: "12:00 pm" },
    { value: 13, label: "1:00 pm" },
    { value: 14, label: "2:00 pm" },
    { value: 15, label: "3:00 pm" },
    { value: 16, label: "4:00 pm" },
    { value: 17, label: "5:00 pm" },
    { value: 18, label: "6:00 pm" },
    { value: 19, label: "7:00 pm" },
    { value: 20, label: "8:00 pm" },
    { value: 21, label: "9:00 pm" },
    { value: 22, label: "10:00 pm" },
    { value: 23, label: "11:00 pm" },
  ],
  jobType: [
    { value: "Employee", label: "Employee" },
    { value: "Contractor", label: "Contractor" },
    {
      value: "Open to either",
      label: "Employee or Contractor",
    },
  ],
  hours: [
    { label: "Full-time", value: "Full-time" },
    { label: "Part-time", value: "Part-time" },
    {
      label: "Full-time or Part-time",
      value: "Open to either",
    },
  ],
};

export const JobDetailsHelper = {
  getJobTypeValue: (data: JobDetailsType) => {
    let result = "";

    if (data.hours == "Full-time" || data.hours == "Part-time") {
      result += data.hours + " ";
    }

    if (data.jobType == "Employee" || data.jobType == "Contractor") {
      result += data.jobType;
    }

    return result;
  },
  getJobViewTimezoneText: (data: JobDetailsType) => {
    let result = "";

    if (data.overlappingTimeNotRequired) {
      return "Any working hours";
    }

    if (data.overlappingTimeTotalHours) {
      const startTime = getSelection(
        data.overlappingTimeStart,
        JobDetailsOptions.OverlappingTimeStartEndOptions
      );

      if (!startTime) {
        return "";
      }

      const endTime = getSelection(
        data.overlappingTimeEnd,
        JobDetailsOptions.OverlappingTimeStartEndOptions
      );

      const type = getSelection(
        data.overlappingTimeTotalHours,
        JobDetailsOptions.OverlappingTimeTotalHoursOptions
      );

      if (type) {
        const hours = type.hours;

        if (type.type == "specific") {
          result += hours + " hr specifically between ";
        }

        if (type.type == "anytime") {
          result += hours + "+ hr overlap between ";
        }

        if (startTime) {
          result +=
            startTime.label.slice(0, startTime.label.indexOf(":")) +
            startTime.label.slice(-2) +
            "";
        }

        let startTimeValue = startTime.value;
        const endtimeValue = (startTime.value + hours) % 24;
        const endTime = getSelection(
          endtimeValue,
          JobDetailsOptions.OverlappingTimeStartEndOptions
        );
        if (endTime) {
          result +=
            "-" +
            endTime.label.slice(0, endTime.label.indexOf(":")) +
            endTime.label.slice(-2);
        }
      }
    }

    return result;
  },
  isDirectHire: (data: JobDetailsType) => {
    let isDirectHire = false;

    if (data?.jobType == "Employee") {
      isDirectHire = true;
    } else {
      if (data?.hiringModel == "Direct Hire") {
        isDirectHire = true;
      }
    }

    if (data?.hiringModel == "Open to either") {
      isDirectHire = true;
    }

    return isDirectHire;
  },
};

export const jobPublishableSchema = yup.object({
  title: yup.string().required(),
  requiredSkills: yup.array().required().min(1),
  role: yup.string().required(),
  totalExperienceYears: yup.string().required(),
  hours: yup.string().required(),
  salary: yup.object({
    min: yup.string().required(),
    max: yup.string().required(),
  }),
  jobType: yup.string().required(),
  hiringModel: yup.object().when("jobType", {
    is: (value: any) => value != "Employee",
    then: (schema: any) => yup.string().required(),
    otherwise: (schema: any) => yup.string().notRequired(),
  }),
  hiringLocations: yup.object().when("noRequiredLocations", {
    is: (value: any) => value == true,
    then: (schema: any) => yup.array().notRequired(),
    otherwise: (schema: any) => yup.array().required().min(1),
  }),
  // @Todo Timezone
  openingsNumber: yup.string().required(),
  visaSponsorship: yup.string().required(),
  overview: yup.string().required(),
  responsibilities: yup.array().min(1),
  requirements: yup.array().min(1),
});
