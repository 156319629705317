import React, { PropsWithChildren } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  max-width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 16px 1fr 16px;
  grid-template-rows: 1fr;
  grid-template-areas: "pad content pad";
  overflow-y: auto;

  @media (min-width: 768px) {
    grid-template-columns: 32px 1fr 32px;
  }

  @media screen and (min-width: 1440px) {
    grid-template-columns: 1fr 1112px 1fr;
  }

  @media screen and (min-width: 1568px) {
    grid-template-columns: 96px 1fr 96px;
  }

  @media screen and (min-width: 1888px) {
    grid-template-columns: 96px 1432px 1fr;
  }
`;

const Content = styled.div`
  padding: 56px 0;
  display: flex;
  flex-direction: column;
  height: fit-content;
  overflow-y: visible;
  margin-bottom: 0;
`;

type Props = {};

const FluidContentContainer: React.FC<PropsWithChildren> = (props) => {
  return (
    <Container>
      <div></div>
      <Content>{props.children}</Content>
      <div></div>
    </Container>
  );
};

export default FluidContentContainer;
