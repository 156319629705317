import React, { useState } from "react";
import styled from "styled-components";
import Select, { OptionProps, SingleValueProps } from "react-select";
import { FieldRenderProps } from "react-final-form";
import AsyncSelect from "react-select/async";
import * as querystring from "querystring";
import axios from "axios";
import { CircleFlag } from "react-circle-flags";
import countries from "src/Modules/Shared/Components/Temp/countries";
import CompanyLogo from "src/Modules/Shared/Components/Util/CompanyLogo";

const stylesOption = {
  container: (provided: any, state: any) => ({
    ...provided,
    width: "100%",
    height: 48,
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    color: "red",
    display: "none",
  }),
  clearIndicator: (provided: any, state: any) => ({
    ...provided,
    display: state.isMulti ? "none" : "default",
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    margin: 0,
    top: 60,
    border: "1px solid #eceff6",
    borderTop: "none",
    zIndex: 1000,

    background: "#FFFFFF",
    boxShadow: "0px 2px 16px rgba(28, 39, 74, 0.06)",
    borderRadius: 6,
  }),
  menuPortal: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    margin: 0,
    top: 48,
    zIndex: 1,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: 48,
    fontFamily: "Inter",
    paddingRight: 12,
    margin: 0,
    color: "#1C274A",
    border: state.isFocused ? "#105cf7 1px solid" : "1px solid #EDEDF0",
    "&:hover": {
      borderColor: "#7aa0ff 1px solid",
    },
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    fontSize: 14,
    color: state.isDisabled ? "#999" : "#1C274A",
    letterSpacing: "0",
    fontStyle: "normal",
    fontFamily: "Inter",
    fontWeight: 400,
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    borderRadius: 6,
    padding: "2px 8px",
    backgroundColor: "#F4FBFD",
    height: 24,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  }),
  multiValueRemove: (provided: any, state: any) => ({
    ...provided,
    color: "#1D7C93",
    backgroundColor: "none",
    width: 18,
    height: 18,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      width: 18,
      height: 18,
    },
    "&:hover": {
      backgroundColor: "transparent",
      color: "pink",
      cursor: "pointer",
    },
  }),
  multiValueLabel: (provided: any, state: any) => ({
    ...provided,
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "Inter",
    color: "#1D7C93",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: 20,
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontSize: 14,
    height: 20,
    fontFamily: "Inter",
    fontWeight: 400,
    marginLeft: 0,
    color: "#858B9D",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: 12,
    fontWeight: 500,
    display: "flex",
    alignItems: "center",

    height: 48,
    backgroundColor: state.isSelected
      ? "#F8FAFF"
      : state.isFocused
      ? "#F8FAFF"
      : "white",
    color: state.isSelected
      ? "#1C274A"
      : state.isFocused
      ? "#1C274A"
      : "#1C274A",
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
  }),
};

const CustomDropdownIndicator = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 12.5001L6.46417 8.96422L7.64334 7.78589L10 10.1434L12.3567 7.78589L13.5358 8.96422L10 12.5001Z"
        fill="#9FA4B3"
      />
    </svg>
  );
};

const Container = styled.div`
  width: 100%;
  height: 48px;
`;

type Props = FieldRenderProps<string, any>;

const CustomOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 12px;
  width: 100%;
  position: relative;
  overflow-y: hidden;
  height: 46px;
  gap: 12px;

  &:hover {
    background: #f8fafc;
  }

  .logo {
    width: 26px;
    height: 26px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 26px;
      height: 26px;
    }
  }

  label {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.03em;
    color: #1c274a;
    padding: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: auto;
  }
`;

const CustomOption = (props: OptionProps) => {
  let currentOption = props.options.find((i) => {
    return i.label == props.label;
  });

  if (currentOption.value == "create_new") {
    return (
      <CustomOptionContainer
        onClick={() => {
          props.selectOption(currentOption);
        }}
      >
        <EnterCompany>
          <span>+ Enter Company</span>
        </EnterCompany>
      </CustomOptionContainer>
    );
  }

  return (
    <CustomOptionContainer
      onClick={() => {
        props.selectOption(currentOption);
      }}
    >
      <div className="logo">
        <CompanyLogo
          key={currentOption.label}
          size={26}
          src={currentOption.logo}
        />
      </div>
      <label>{props.label}</label>
    </CustomOptionContainer>
  );
};

const CustomSingleValueContainer = styled.div`
  fontsize: 14px;
  letterspacing: "0";
  fontstyle: "normal";
  fontfamily: "Inter";
  fontweight: 400;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 12px;

  position: absolute;

  .logo {
    width: 24px;
    height: 24px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 24px;
      height: 24px;
    }
  }

  .label {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.03em;
    color: #1c274a;
  }

  .domain {
    margin-left: auto;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -0.03em;
    color: #858b9d;
  }
`;

const EnterCompany = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #105cf7;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const CustomSingleValue = (props: SingleValueProps) => {
  let value = props.getValue()[0];

  return (
    <CustomSingleValueContainer>
      <div className="logo">
        <CompanyLogo size={24} src={value.logo} />
      </div>
      <label>{props.children}</label>
      <div className="domain">{value.domain}</div>
    </CustomSingleValueContainer>
  );
};

const loadOptions = (
  inputValue: string,
  callback: (options: any[]) => void
) => {
  let query = {
    input: inputValue,
  };

  axios
    .get("/api/options/company-autocomplete", { params: query })
    .then((response) => {
      if (response.data != undefined) {
        let options = response.data.data;
        options = options.map((option: unknown) => {
          return {
            value: option.name,
            label: option.name,
            domain: option.domain,
            logo: option.logo,
          };
        });

        options.push({ value: "create_new", label: "create_new" });

        callback(options);
      } else {
        callback([]);
      }
    });
};

const CompanySearchField: React.FC<Props> = (props) => {
  let current = null;
  if (props.customValue.label != undefined) {
    current = props.customValue;
  }

  return (
    <Container>
      <AsyncSelect
        components={{
          DropdownIndicator: null,
          Option: CustomOption,
          SingleValue: CustomSingleValue,
        }}
        styles={stylesOption}
        placeholder={props.placeholder}
        isDisabled={props.disabled}
        loadOptions={loadOptions}
        value={current}
        isMulti={false}
        onChange={(newValue) => {
          if (newValue.value == "create_new") {
            props.setCustomCompanyForm(true);
          } else {
            props.form.change("companyName", newValue.value);
            props.form.change("companyWebsite", newValue.domain);
            props.form.change("companyLogo", newValue.logo);
          }
        }}
        {...props}
      />
    </Container>
  );
};

export default CompanySearchField;
