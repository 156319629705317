import React, { useState } from "react";
import styled from "styled-components";
import {
  BackButton,
  FormCard,
  FormGroupDynamic,
  FormGroupHalfWidth,
  FormLabel,
  FormSubtitle,
  FormTitle,
  PrimaryButton,
  Spacer,
} from "src/Modules/Shared/Components/Temp/ApplicationFlowComponents";
import { Form, Field } from "react-final-form";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import SelectField from "src/Modules/Shared/Components/Temp/SelectField";
import countries from "src/Modules/Shared/Components/Temp/countries";
import { timezoneOptions } from "src/Modules/Shared/Components/Temp/timezones";
import PrefixTextInputField from "src/Modules/Shared/Components/Temp/PrefixTextInputField";
import MultipleBlockSelectField, {
  BlockFieldItem,
} from "src/Modules/Shared/Components/Temp/MultipleBlockSelectField";
import BlockSelectField from "src/Modules/Shared/Components/Temp/BlockSelectField";
import axios from "axios";
import * as yup from "yup";
import { Info } from "@tanstack/react-query-devtools/build/lib/Explorer";
import { PageTitle } from "src/Modules/Customer/Components/CommonCustoemerLib";
import DefaultSelectField from "src/Modules/Core/Components/Form/Select/DefaultSelectField/DefaultSelectField";
import CountriesGroupedByRegionSelect from "src/Modules/Core/Components/Form/Select/CountriesGroupedByRegionSelect/CountriesGroupedByRegionSelect";
import InformationFillIcon from "remixicon-react/InformationFillIcon";
import CustomerApplicationFlowOptions from "src/Modules/Shared/Components/Temp/CustomerApplicationFlowOptions";
const Container = styled.div``;

const HalfSizeWidth = 286;

const ArrowRightIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  width: 20px;
  height: 20px;
`;

const ArrowLeftIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 20px;
  height: 20px;
`;

const ButtonGroup = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ButtonGroupSpace = styled.div`
  width: auto;
  height: 48px;
  margin-left: auto;
`;

const InfoText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #858b9d;
  width: 260px;
  margin-top: -22px;
`;

const HoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;
`;

const HoverText = styled.div`
  width: 260px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.03em;

  color: #ffffff;
  padding: 8px 12px;
  background: #1c274a;
  border-radius: 6px;

  bottom: 28px;
  position: absolute;
`;

type Props = {
  setStep: (step: number) => void;
};

const formSchema = yup.object({
  job_type: yup.string().required(),
  time: yup.string().required(),
  start_date: yup.string().required(),
  cscsc: yup.string().required(),
  asdf: yup.string().required(),
  country2: yup.array().when("asdf", {
    is: (value: string) => value == "y",
    then: (schema) => schema.required().min(1),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  long_term: yup.object().when("cscsc", {
    is: (value: string) => value == "n",
    then: (schema) => schema.required(),
    otherwise: (schema: any) => schema.notRequired(),
  }),
});
const ApplicationFlowStepRoleRequirements: React.FC<Props> = (props) => {
  let [info, setInfo] = useState(false);

  return (
    <Container>
      <Form
        onSubmit={(values) => {
          axios
            .post("/api/customers/v1/application-flow", values)
            .then((response) => {
              props.setData(response.data);
            });
        }}
        initialValues={props.data}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <FormTitle>Role Requirements</FormTitle>
            <FormSubtitle>
              Tell us more about your specific hiring needs.
            </FormSubtitle>
            <FormCard>
              <FormGroupDynamic width={650}>
                <FormLabel>Job type</FormLabel>
                <Field
                  multiple={false}
                  name={"job_type"}
                  component={BlockSelectField}
                  options={CustomerApplicationFlowOptions.jobType}
                ></Field>
              </FormGroupDynamic>

              <FormGroupDynamic width={650}>
                <FormLabel>Hours</FormLabel>
                <Field
                  multiple={false}
                  name={"time"}
                  component={BlockSelectField}
                  options={CustomerApplicationFlowOptions.hours}
                ></Field>
              </FormGroupDynamic>

              <FormGroupDynamic width={650}>
                <FormLabel>Start Date</FormLabel>
                <Field
                  multiple={false}
                  name={"start_date"}
                  component={BlockSelectField}
                  options={CustomerApplicationFlowOptions.startDate}
                ></Field>
              </FormGroupDynamic>
              <FormGroupDynamic width={HalfSizeWidth}>
                <FormLabel>Location Requirement</FormLabel>
                <Field
                  multiple={false}
                  name={"asdf"}
                  size={44}
                  component={BlockSelectField}
                  options={CustomerApplicationFlowOptions.isLocationRequired}
                ></Field>
              </FormGroupDynamic>
              <FormGroupDynamic width={HalfSizeWidth}>
                {values.asdf == "y" && (
                  <>
                    <FormLabel>Select location</FormLabel>
                    <Field
                      name={"country2"}
                      component={DefaultSelectField}
                      isMulti={true}
                      maxValuesLengths={2}
                      maxValuesLengthLabels={"Regions"}
                      options={CustomerApplicationFlowOptions.requiredRegions}
                    />
                  </>
                )}
              </FormGroupDynamic>

              <FormGroupDynamic width={HalfSizeWidth}>
                <FormLabel>
                  <span>Looking to hire long-term</span>
                  <HoverContainer
                    onClick={() => setInfo(!info)}
                    onMouseEnter={() => setInfo(true)}
                    onMouseLeave={() => setInfo(false)}
                  >
                    {info && (
                      <HoverText>
                        Long-term is considered as a permanent team member
                        without a specific end date
                      </HoverText>
                    )}
                    <InformationFillIcon size={18.7} color={"#D2D4DB"} />
                  </HoverContainer>
                </FormLabel>
                <Field
                  multiple={false}
                  name={"cscsc"}
                  component={BlockSelectField}
                  options={CustomerApplicationFlowOptions.longTerm}
                ></Field>
              </FormGroupDynamic>
              <FormGroupDynamic width={HalfSizeWidth}>
                {values.cscsc == "n" && (
                  <>
                    <FormLabel>Expected time working with hire</FormLabel>
                    <Field
                      name={"long_term"}
                      component={SelectField}
                      options={CustomerApplicationFlowOptions.longTermOption}
                    />
                  </>
                )}
              </FormGroupDynamic>

              {values.cscsc == "n" && (
                <FormGroupDynamic>
                  <InfoText>
                    Notch focuses on long-term hires and matching shorter term
                    jobs may take additional time or not be possible.
                  </InfoText>
                </FormGroupDynamic>
              )}
            </FormCard>

            <ButtonGroup>
              <BackButton
                onClick={(event) => {
                  axios
                    .post("/api/customers/v1/application-flow-back", values)
                    .then((response) => {
                      props.setData(response.data);
                    });
                  event.preventDefault();
                }}
              >
                <ArrowLeftIcon>
                  <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.02331 6.16664H14.1666V7.83331H4.02331L8.49331 12.3033L7.31498 13.4816L0.833313 6.99998L7.31498 0.518311L8.49331 1.69664L4.02331 6.16664Z"
                      fill="#105CF7"
                    />
                  </svg>
                </ArrowLeftIcon>
                Back
              </BackButton>
              <ButtonGroupSpace />
              <PrimaryButton
                type="submit"
                disabled={!formSchema.isValidSync(values)}
              >
                Continue{" "}
                <ArrowRightIcon>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.4767 6.16664L6.00668 1.69664L7.18501 0.518311L13.6667 6.99998L7.18501 13.4816L6.00668 12.3033L10.4767 7.83331H0.333344V6.16664H10.4767Z" />
                  </svg>
                </ArrowRightIcon>
              </PrimaryButton>
            </ButtonGroup>
          </form>
        )}
      />
    </Container>
  );
};

export default ApplicationFlowStepRoleRequirements;
