import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  grid-area: header;
`;

const ProgressContainerLarge = styled.div`
  display: none;

  @media screen and (min-width: 660px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    grid-area: progress;
    height: 100%;
    width: 100%;
  }
`;

const MobileProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-area: progress;
  height: 100%;
  width: 100%;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.03em;

  color: #1c274a;

  @media screen and (min-width: 660px) {
    display: none;
  }
`;

const AccountSection = styled.div`
  grid-area: header;
`;

const stepCalc = (step: number, currentStep: number): string => {
  if (currentStep > step) return "done";
  if (currentStep == step) return "active";
  if (currentStep < step) return "";

  return "";
};

const stepIcon = (activeStep: number, currentStep: number) => {
  if (currentStep < activeStep)
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.00002 17.3334C4.39752 17.3334 0.666687 13.6026 0.666687 9.00008C0.666687 4.39758 4.39752 0.666748 9.00002 0.666748C13.6025 0.666748 17.3334 4.39758 17.3334 9.00008C17.3334 13.6026 13.6025 17.3334 9.00002 17.3334ZM8.16919 12.3334L14.0609 6.44091L12.8825 5.26258L8.16919 9.97675L5.81169 7.61925L4.63335 8.79758L8.16919 12.3334Z"
          fill="#105CF7"
        />
      </svg>
    );

  if (currentStep == activeStep)
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 18.3334C5.39752 18.3334 1.66669 14.6026 1.66669 10.0001C1.66669 5.39758 5.39752 1.66675 10 1.66675C14.6025 1.66675 18.3334 5.39758 18.3334 10.0001C18.3334 14.6026 14.6025 18.3334 10 18.3334ZM10 16.6667C11.7681 16.6667 13.4638 15.9644 14.7141 14.7141C15.9643 13.4639 16.6667 11.7682 16.6667 10.0001C16.6667 8.23197 15.9643 6.53628 14.7141 5.28604C13.4638 4.03579 11.7681 3.33341 10 3.33341C8.23191 3.33341 6.53622 4.03579 5.28598 5.28604C4.03573 6.53628 3.33335 8.23197 3.33335 10.0001C3.33335 11.7682 4.03573 13.4639 5.28598 14.7141C6.53622 15.9644 8.23191 16.6667 10 16.6667Z"
          fill="#105CF7"
        />
      </svg>
    );

  if (currentStep > activeStep)
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_522_65579)">
          <path
            d="M9.99996 18.3334C5.39746 18.3334 1.66663 14.6026 1.66663 10.0001C1.66663 5.39758 5.39746 1.66675 9.99996 1.66675C14.6025 1.66675 18.3333 5.39758 18.3333 10.0001C18.3333 14.6026 14.6025 18.3334 9.99996 18.3334ZM9.99996 16.6667C11.7681 16.6667 13.4638 15.9644 14.714 14.7141C15.9642 13.4639 16.6666 11.7682 16.6666 10.0001C16.6666 8.23197 15.9642 6.53628 14.714 5.28604C13.4638 4.03579 11.7681 3.33341 9.99996 3.33341C8.23185 3.33341 6.53616 4.03579 5.28591 5.28604C4.03567 6.53628 3.33329 8.23197 3.33329 10.0001C3.33329 11.7682 4.03567 13.4639 5.28591 14.7141C6.53616 15.9644 8.23185 16.6667 9.99996 16.6667V16.6667Z"
            fill="#B8BCC7"
          />
        </g>
        <defs>
          <clipPath id="clip0_522_65579">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
};

const StepItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const StepCircle = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

const StepLabel = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #858b9d;

  &.active {
    color: #105cf7;
  }

  &.done {
    color: #1c274a;
  }
`;

const StepArrow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
`;

const mobileStepLabel = (step: number) => {
  if (step == 2) {
    return "Step 2 of 4";
  }

  if (step == 3) {
    return "Step 3 of 4";
  }

  if (step == 4) {
    return "Step 4 of 4";
  }
};

type Props = {
  step: number;
};

const TalentApplicationFlowHeader: React.FC<Props> = (props) => {
  if (props.step == 5) {
    return null;
  }

  let step = props.step;
  return (
    <>
      <MobileProgressContainer>{mobileStepLabel(step)}</MobileProgressContainer>
      <ProgressContainerLarge>
        <StepItem key={1}>
          <StepCircle>{stepIcon(step, 1)}</StepCircle>
          <StepLabel className={stepCalc(1, step)}>Create Account</StepLabel>
          <StepArrow>
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.97668 6.00011L0.851685 1.87511L2.03002 0.696777L7.33335 6.00011L2.03002 11.3034L0.851685 10.1251L4.97668 6.00011Z"
                fill="#B8BCC7"
              />
            </svg>
          </StepArrow>
        </StepItem>

        <StepItem key={2}>
          <StepCircle>{stepIcon(step, 2)}</StepCircle>
          <StepLabel className={stepCalc(2, step)}>Hiring Details</StepLabel>
          <StepArrow>
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.97668 6.00011L0.851685 1.87511L2.03002 0.696777L7.33335 6.00011L2.03002 11.3034L0.851685 10.1251L4.97668 6.00011Z"
                fill="#B8BCC7"
              />
            </svg>
          </StepArrow>
        </StepItem>

        <StepItem key={3}>
          <StepCircle>{stepIcon(step, 3)}</StepCircle>
          <StepLabel className={stepCalc(3, step)}>Role Requirements</StepLabel>
          <StepArrow>
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.97668 6.00011L0.851685 1.87511L2.03002 0.696777L7.33335 6.00011L2.03002 11.3034L0.851685 10.1251L4.97668 6.00011Z"
                fill="#B8BCC7"
              />
            </svg>
          </StepArrow>
        </StepItem>

        <StepItem key={4}>
          <StepCircle>{stepIcon(step, 4)}</StepCircle>
          <StepLabel className={stepCalc(4, step)}>Company Details</StepLabel>
        </StepItem>
      </ProgressContainerLarge>
      <AccountSection />
    </>
  );
};

export default TalentApplicationFlowHeader;
