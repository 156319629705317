import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  border-radius: 6px;
  font-size: 16px;
  font-family: Inter;
  line-height: 1.4;
  gap: 12px;
`;

const Content = styled.div``;

const LeftBorder = styled.div`
  height: 100%;
  width: 4px;
  // background: red;
  left: 0;
  position: absolute;
  border-radius: 6px 0 0 6px;
`;

type Props = React.PropsWithChildren & {
  type: "info" | "warning" | "error" | "default";
};

const Message: React.FC<Props> = (props) => {
  let style = {
    backgroundColor: "#ddd",
    color: "#222",
  };

  let type = props.type ?? "default";

  switch (type) {
    case "info":
      style = {
        backgroundColor: "#e0f2fe",
        color: "#2563eb",
      };
      break;
    case "warning":
      style = {
        backgroundColor: "#fffbeb",
        color: "#eab308",
      };
      break;
    case "error":
      style = {
        backgroundColor: "#fee2e2",
        color: "#ef4444",
      };
      break;
    default:
      style = {
        backgroundColor: "#f1f5f9",
        color: "#64748b",
      };
      break;
  }

  return (
    <Container style={style}>
      <LeftBorder style={{ background: style.color }} />
      {props.children}
    </Container>
  );
};

export default Message;
