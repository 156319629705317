import React from "react";
import styled from "styled-components";
import {
  BackButton,
  FormCard,
  FormGroupDynamic,
  FormGroupHalfWidth,
  FormLabel,
  FormSubtitle,
  FormTitle,
  PrimaryButton,
  Spacer,
} from "src/Modules/Shared/Components/Temp/ApplicationFlowComponents";
import { Form, Field } from "react-final-form";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import SelectField from "src/Modules/Shared/Components/Temp/SelectField";
import countries from "src/Modules/Shared/Components/Temp/countries";
import { timezoneOptions } from "src/Modules/Shared/Components/Temp/timezones";
import PrefixTextInputField from "src/Modules/Shared/Components/Temp/PrefixTextInputField";
const Container = styled.div``;
import * as yup from "yup";
import { object, string } from "yup";
import JsonDump from "src/Modules/Shared/Utils/JsonDump";
import axios from "axios";
import PopupContainer from "src/Modules/Shared/Components/Temp/PopupContainer";
import { sprink } from "src/Modules/Core/Design/sprink";
import { s } from "@design/sprink";

const HalfSizeWidth = 286;

const ArrowRightIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  width: 20px;
  height: 20px;
`;

const ArrowLeftIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 20px;
  height: 20px;
`;

const ButtonGroup = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ButtonGroupSpace = styled.div`
  width: auto;
  height: 48px;
  margin-left: auto;
`;

const GreetingWithName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #1c274a;
`;

const GreetingText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.04em;
  color: #1c274a;
  margin-bottom: 32px;
`;

const OptionalLabel = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #b8bcc7;
  margin-left: 4px;
  margin-bottom: 8px;
`;

const InfoIcon = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;
  margin-left: 4px;
  margin-bottom: 8px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LinkedinInfoPopup = styled.div`
  position: absolute;
  width: 260px;
  background: white;
  border: 1px solid #ededf0;
  box-shadow: 0px 12px 32px rgba(28, 39, 74, 0.08);
  border-radius: 6px;
  display: flex;
  flex-direction: column;

  font-family: Inter;
  font-size: 12px;
  padding: 16px;
  line-height: 1.3;

  color: #1c274a;
  bottom: 40px;
  left: -110px;
`;

type Props = {
  setStep: (step: number) => void;
  setData: (values: any) => void;
  data: object;
};

const formSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  country: yup.object().required(),
  country2: yup.object().required(),
});

const ApplicationFlowStepPersonalDetails: React.FC<Props> = (props) => {
  return (
    <Container>
      <Form
        onSubmit={(values) => {
          axios
            .post("/api/customers/v1/application-flow", values)
            .then((response) => {
              props.setData(response.data);
            });
        }}
        initialValues={props.data}
        render={({ handleSubmit, values, submitting }) => (
          <form onSubmit={handleSubmit}>
            <GreetingWithName>Hi, {values.firstName}</GreetingWithName>
            <GreetingText>Let’s get your profile started</GreetingText>
            <FormTitle>Personal Details</FormTitle>
            <FormSubtitle>
              To get started please let us know a few basic details and make
              sure everything is accurate.
            </FormSubtitle>
            <FormCard>
              <FormGroupDynamic width={HalfSizeWidth}>
                <FormLabel>First Name</FormLabel>
                <Field name={"firstName"} component={TextInputField} />
              </FormGroupDynamic>
              <FormGroupDynamic width={16} className="hide-mb" />
              <FormGroupDynamic width={HalfSizeWidth}>
                <FormLabel>Last Name</FormLabel>
                <Field name={"lastName"} component={TextInputField} />
              </FormGroupDynamic>

              <FormGroupDynamic width={HalfSizeWidth}>
                <FormLabel>Country of Residence</FormLabel>
                <Field
                  name={"country"}
                  component={SelectField}
                  options={countries}
                />
              </FormGroupDynamic>
              <FormGroupDynamic width={16} className="hide-mb" />
              <FormGroupDynamic width={HalfSizeWidth}>
                <FormLabel>Country of Citizenship</FormLabel>
                <Field
                  name={"country2"}
                  component={SelectField}
                  options={countries}
                />
              </FormGroupDynamic>
              <FormGroupDynamic width={HalfSizeWidth}>
                <FormLabel>Timezone</FormLabel>
                <Field
                  name={"timezone"}
                  component={SelectField}
                  options={timezoneOptions}
                />
              </FormGroupDynamic>

              <FormGroupDynamic width={HalfSizeWidth * 2 + 16}>
                <FlexRow>
                  <FormLabel>Linkedin Profile</FormLabel>
                  <OptionalLabel>(optional)</OptionalLabel>
                  <PopupContainer
                    popup={
                      <LinkedinInfoPopup>
                        Sharing your LinkedIn helps us review your profile and
                        approve you faster.
                      </LinkedinInfoPopup>
                    }
                  >
                    <InfoIcon>
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.99935 17.3334C4.39685 17.3334 0.666016 13.6026 0.666016 9.00008C0.666016 4.39758 4.39685 0.666748 8.99935 0.666748C13.6018 0.666748 17.3327 4.39758 17.3327 9.00008C17.3327 13.6026 13.6018 17.3334 8.99935 17.3334ZM8.99935 15.6667C10.7675 15.6667 12.4632 14.9644 13.7134 13.7141C14.9636 12.4639 15.666 10.7682 15.666 9.00008C15.666 7.23197 14.9636 5.53628 13.7134 4.28604C12.4632 3.03579 10.7675 2.33341 8.99935 2.33341C7.23124 2.33341 5.53555 3.03579 4.2853 4.28604C3.03506 5.53628 2.33268 7.23197 2.33268 9.00008C2.33268 10.7682 3.03506 12.4639 4.2853 13.7141C5.53555 14.9644 7.23124 15.6667 8.99935 15.6667ZM8.16602 4.83341H9.83268V6.50008H8.16602V4.83341ZM8.16602 8.16675H9.83268V13.1667H8.16602V8.16675Z"
                          fill="#B8BCC7"
                        />
                      </svg>
                    </InfoIcon>
                  </PopupContainer>
                </FlexRow>

                <Field
                  name={"linkedin_profile"}
                  component={PrefixTextInputField}
                  prefix="https://linkedin.com/in/"
                />
              </FormGroupDynamic>
            </FormCard>

            <ButtonGroup>
              <ButtonGroupSpace />
              <PrimaryButton
                type="submit"
                disabled={!formSchema.isValidSync(values) || submitting}
              >
                Continue{" "}
                <ArrowRightIcon>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.4767 6.16664L6.00668 1.69664L7.18501 0.518311L13.6667 6.99998L7.18501 13.4816L6.00668 12.3033L10.4767 7.83331H0.333344V6.16664H10.4767Z" />
                  </svg>
                </ArrowRightIcon>
              </PrimaryButton>
            </ButtonGroup>
          </form>
        )}
      />
    </Container>
  );
};

export default ApplicationFlowStepPersonalDetails;
