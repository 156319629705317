import React from "react";
import styled from "styled-components";
import ShortlistCandidateStatus from "src/Modules/Admin/Components/Pages/ShortList/Components/ShortlistCandidateStatus";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 12px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  width: max-content;

  border-radius: 6px;
`;

const Incomplete = styled(Container)`
  background: #fafafb;
  color: #6a7288;
`;

const New = styled(Container)`
  background: var(--notch-secondary-purple-50, #f1e7fe);
  color: var(--notch-secondary-purple-600, #6522f2);
`;

const CandidateStatusTag: React.FC = (props) => {
  let status = props.data.status;
  return <ShortlistCandidateStatus status={status} />;
};

export default CandidateStatusTag;
