import React, { useState } from "react";
import styled from "styled-components";
import skillOptions from "src/Modules/Shared/Components/Temp/skillOptions";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { CustomerApi } from "src/Modules/Customer/Services/CustomerApi";

const Container = styled.div``;
const SkillList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin: 0px 0;
  gap: 12px;
`;

const Empty = styled.div`
  font-size: 12px;
  color: red;
`;

const Skill = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  height: 32px;
  background: #f4fbfd;
  border-radius: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: #1d7c93;
`;

const SkillLogo = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const SkillYears = styled.div`
  color: var(--notch-secondary-teal-50, #f4fbfd);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.42px;
  border-radius: 999px;
  background: var(--notch-secondary-teal-600, #1d7c93);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  min-width: 32px;

  padding: 0 8px;
`;

const ShowMoreToggle = styled.div`
  border-radius: 6px;
  display: flex;
  padding: 4px 16px 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;

  color: var(--notch-neutral-400, #9fa4b3);
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.42px;

  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: #105cf7;
  }
`;

const GetCodeByName = (name) => {
  let found = skillOptions.find((i) => {
    return i.label == name;
  });

  return found?.value;
};

const SkillByCode = (code, icon, candidateSkills, showOneYearLabel = true) => {
  const s = skillOptions.find((i) => {
    return i.value == code;
  });

  let existingCandidateSkill = candidateSkills.find((i) => {
    return i.name == s.label;
  });

  if (s?.logoPath != "") {
    return (
      <Skill key={s.label}>
        {icon && (
          <>
            <SkillLogo
              src={`https://app.notchteam.com/images/icons/skills/${s.logoPath}`}
            />
          </>
        )}
        {s.label}
        {existingCandidateSkill &&
          (showOneYearLabel || existingCandidateSkill.years != "1") && (
            <SkillYears>{existingCandidateSkill.years}y</SkillYears>
          )}
      </Skill>
    );
  } else {
    return <Skill>{s.label}</Skill>;
  }
};

const CandidateSkills: React.FC = (props) => {
  let [showAll, setShowAll] = useState(false);

  let params = useParams();

  let jobId = params.jobId;
  const jobQuery = useQuery(CustomerApi.jobs.get(jobId));

  if (jobQuery.isLoading) {
    return null;
  }

  let job = jobQuery.data?.data;
  let jobSkills = {
    requiredSkills: job.jobDetails.requiredSkills,
    niceToHaveSkills: job.jobDetails.niceToHaveSkills,
  };

  let candidateSkills = props.data?.dataOverwrites?.Skills;
  let draftSkills = props.data.dataOverwrites?.Skills;
  let candidateSkillsCode = candidateSkills.map((i) => {
    return GetCodeByName(i.name);
  });

  ///
  if (draftSkills.length == 0) {
    return (
      <Empty>Profile was not saved before publishing, skills missing</Empty>
    );
  }

  let jobRequiredSkills = jobSkills.requiredSkills;
  let jobOptionalSkills = jobSkills.niceToHaveSkills;

  let candidatePrimarySkills = draftSkills;
  let candidateSecondarySkills = candidateSkillsCode;

  if (!Array.isArray(jobRequiredSkills)) {
    jobRequiredSkills = [];
  }

  let jobRequiredSkillsMatchingAsPrimary = jobRequiredSkills.filter(
    (jobSkillCode) => {
      return candidatePrimarySkills.includes(jobSkillCode);
    }
  );

  // Remove skills from required
  jobRequiredSkills = jobRequiredSkills.filter((code) => {
    return !jobRequiredSkillsMatchingAsPrimary.includes(code);
  });

  let jobRequiredSkillsMatchingAsSecondary = jobRequiredSkills.filter(
    (jobSkillCode) => {
      return candidateSecondarySkills.includes(jobSkillCode);
    }
  );

  if (!Array.isArray(jobOptionalSkills)) {
    jobOptionalSkills = [];
  }

  let jobOptionalSkillsMatchingAsPrimary = jobOptionalSkills.filter(
    (jobSkillCode) => {
      return candidatePrimarySkills.includes(jobSkillCode);
    }
  );

  jobOptionalSkills = jobOptionalSkills.filter((code) => {
    return !jobOptionalSkillsMatchingAsPrimary.includes(code);
  });

  let jobOptionalSkillsMatchingAsSecondary = jobOptionalSkills.filter(
    (jobSkillCode) => {
      return candidateSecondarySkills.includes(jobSkillCode);
    }
  );

  // Combine them

  let skillsToRender = jobRequiredSkillsMatchingAsPrimary.map((i) => {
    return SkillByCode(i, true, candidateSkills, true);
  });

  skillsToRender = skillsToRender.concat(
    jobRequiredSkillsMatchingAsSecondary.map((i) => {
      return SkillByCode(i, true, candidateSkills, false);
    })
  );

  skillsToRender = skillsToRender.concat(
    jobOptionalSkillsMatchingAsPrimary.map((i) => {
      return SkillByCode(i, false, candidateSkills, true);
    })
  );

  skillsToRender = skillsToRender.concat(
    jobOptionalSkillsMatchingAsSecondary.map((i) => {
      return SkillByCode(i, false, candidateSkills, false);
    })
  );

  ///---------------

  let allSkills = skillsToRender;

  let hasMore = allSkills.length > 3;

  if (!hasMore) {
    return <SkillList>{allSkills}</SkillList>;
  } else {
    return (
      <SkillList>
        {showAll && <>{allSkills}</>}
        {!showAll && <>{allSkills.slice(0, 3)}</>}
        <>
          {!showAll && (
            <ShowMoreToggle onClick={() => setShowAll(true)}>
              + {allSkills.length - 3} More
            </ShowMoreToggle>
          )}
          {showAll && (
            <ShowMoreToggle onClick={() => setShowAll(false)}>
              Show less
            </ShowMoreToggle>
          )}
        </>
      </SkillList>
    );
  }
};

export default CandidateSkills;
