import React from "react";
import styled from "styled-components";

const Container = styled.div`
  background: #ffffff;
  border: 1px solid #ededf0;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  padding: 24px;
  margin-bottom: 32px;
`;

const CandidateCardContainer: React.FC = (props) => {
  return <Container>{props.children}</Container>;
};

export default CandidateCardContainer;
