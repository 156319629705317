export const skills = [
  { label: ".NET", value: "edlgjtmg", logoPath: "dot-net-plain.svg" },
  { label: "Vue.js", value: "bnjlsama", logoPath: "vuejs-original.svg" },
  { label: "HTML5", value: "2fbsesgv", logoPath: "html5-plain.svg" },
  { label: "Python", value: "bg1r6fap", logoPath: "python-plain.svg" },
  { label: "WordPress", value: "gsao7hts", logoPath: "wordpress-plain.svg" },
  { label: "React Native", value: "xeehwdai", logoPath: "react-original.svg" },
  { label: "React.js", value: "axh88v7l", logoPath: "react-original.svg" },
  { label: "Remix", value: "axh88v8u", logoPath: "remix-logo.png" },
  { label: "Angular", value: "lmzgqc2r", logoPath: "angularjs-plain.svg" },
  { label: "Swift", value: "pvxss10d", logoPath: "swift-original.svg" },
  { label: "Java", value: "4redcwck", logoPath: "java-plain.svg" },
  { label: "Node.js", value: "jxfkyji2", logoPath: "nodejs-original.svg" },
  { label: "JavaScript", value: "qwfzrzky", logoPath: "javascript-plain.svg" },
  { label: "Ruby", value: "spkkldtu", logoPath: "ruby-plain.svg" },
  { label: "Ruby on Rails", value: "pqczlczy", logoPath: "rails-plain.svg" },
  { label: "Typescript", value: "mj1ueb2t", logoPath: "typescript-plain.svg" },
  { label: "Kotlin", value: "cwrplhom", logoPath: "kotlin.png" },
  { label: "Scala", value: "s7wlliz4", logoPath: "scala-plain.svg" },
  { label: "Go.js", value: "nlhuinir", logoPath: "go-language-official.svg" },
  { label: "Golang", value: "bfnrgnwn", logoPath: "go-language-official.svg" },
  { label: "PHP", value: "kh4tqa2m", logoPath: "php-logo.svg" },
  { label: ".NET Framework", value: "to83q25b", logoPath: "dot-net-plain.svg" },
  { label: "PostgreSQL", value: "3ncplawr", logoPath: "postgresql-plain.svg" },
  { label: "MeteorJS", value: "5rabi5yu", logoPath: "meteor-plain.svg" },
  { label: "Django", value: "ty91dmd3", logoPath: "django-plain.svg" },
  { label: "Django ORM", value: "dm9jjqdk", logoPath: "django-plain.svg" },
  {
    label: "Django REST Framework",
    value: "9z5r6zrz",
    logoPath: "django-plain.svg",
  },
  { label: ".NET (Core) C#", value: "dd0gojbw", logoPath: "dot-net-plain.svg" },
  {
    label: "Django Templates",
    value: "ke0qfisg",
    logoPath: "django-plain.svg",
  },
  {
    label: ".NET Compact Framework",
    value: "ymob0qzb",
    logoPath: "dot-net-plain.svg",
  },
  { label: ".NET Core", value: "pepzgd5n", logoPath: "dot-net-plain.svg" },
  { label: "Docker", value: "pduxdqhb", logoPath: "docker-plain.svg" },
  { label: "Docker Compose", value: "c9n0evi3", logoPath: "docker-plain.svg" },
  { label: "Docker Swarm", value: "t8e9zsla", logoPath: "docker-plain.svg" },
  { label: "DockerHub", value: "imkapwxr", logoPath: "docker-plain.svg" },
  { label: "C", value: "mide0h6e", logoPath: "c-line.svg" },
  { label: "Webpack", value: "7byfy5nz", logoPath: "webpack-plain.svg" },
  { label: "C#", value: "5gfltwhs", logoPath: "csharp-line.svg" },
  { label: "CakePHP", value: "xk70bbqc", logoPath: "cakephp-plain.svg" },
  { label: "Python 3", value: "byxolmvv", logoPath: "python-plain.svg" },
  { label: "MongoDB", value: "yzcc2ezb", logoPath: "mongodb-plain.svg" },
  { label: "Python/Django", value: "eno5pzos", logoPath: "django-plain.svg" },
  {
    label: "ElasticSearch",
    value: "5bahbzxp",
    logoPath: "elasticsearch-512.png",
  },
  {
    label: "Electron.JS",
    value: "mlkzsodh",
    logoPath: "electron-original.svg",
  },
  { label: "Elixir", value: "gpphmo3q", logoPath: "elixir.svg" },
  {
    label: "Ember.js",
    value: "l97buv0q",
    logoPath: "ember-original-wordmark.svg",
  },
  { label: "Rails", value: "76uhwhmj", logoPath: "rails-plain.svg" },
  { label: "MySQL", value: "43tcvzoa", logoPath: "mysql-plain.svg" },
  { label: "Ionic", value: "hqcu6hdw", logoPath: "ionic-original.svg" },
  { label: "Android", value: "kuataftx", logoPath: "android-plain.svg" },
  { label: "Express", value: "kil9iqr4", logoPath: "express-original.svg" },
  { label: "Redis", value: "4dgfc3n0", logoPath: "redis-plain.svg" },
  { label: "Jasmine", value: "gfyorc96", logoPath: "jasmine-plain.svg" },
  { label: "Redux", value: "zyc59tbc", logoPath: "redux-original.svg" },
  {
    label: "Redux-observable",
    value: "cwnbyvv4",
    logoPath: "redux-original.svg",
  },
  { label: "Redux-Saga", value: "tepfezk6", logoPath: "redux-original.svg" },
  { label: "Redux-Thunk", value: "zaxxwt1d", logoPath: "redux-original.svg" },
  {
    label: "CoffeeScript",
    value: "ta9svycl",
    logoPath: "coffeescript-original.svg",
  },
  { label: "Firebase", value: "hv7lzbdq", logoPath: "firebase.png" },
  { label: "Jenkins", value: "p5owimdc", logoPath: "jenkins.svg" },
  { label: "Firebase Functions", value: "a96qipum", logoPath: "firebase.png" },
  { label: "jQuery", value: "aalzqk0i", logoPath: "jquery-plain.svg" },
  { label: "Flutter", value: "un6g7btx", logoPath: "flutter.svg" },
  { label: "jQuery Mobile", value: "jenolut9", logoPath: "jquery-plain.svg" },
  { label: "jQuery UI", value: "4eurrilr", logoPath: "jquery-plain.svg" },
  { label: "Rust", value: "3l5jffob", logoPath: "rust-plain.svg" },
  {
    label: "Kubernetes",
    value: "d7no5ksg",
    logoPath: "Kubernetes-Logo.wine.svg",
  },
  { label: "SASS", value: "g2wvj0x8", logoPath: "sass-original.svg" },
  {
    label: "Laravel Framework",
    value: "pq7iyh0k",
    logoPath: "laravel-plain.svg",
  },
  { label: "CSS3", value: "86sacnxd", logoPath: "css3-plain.svg" },
  { label: "LESS", value: "qqunjjhu", logoPath: "less-plain-wordmark.svg" },
  {
    label: "AWS",
    value: "pfe4m0re",
    logoPath: "amazonwebservices-original.svg",
  },
  { label: "d3.js", value: "cxieoi5s", logoPath: "d3js-plain.svg" },
  { label: "Graphql", value: "jmhbnakr", logoPath: "GraphQL.png" },
  {
    label: "Digital Ocean",
    value: "6kjemu9k",
    logoPath: "digitalocean-icon.svg",
  },
  { label: "Gulp", value: "r70f08r6", logoPath: "gulp-plain.svg" },
  { label: "GulpJS", value: "nknzmfq2", logoPath: "gulp-plain.svg" },
  { label: "Bitbucket", value: "hefmfzov", logoPath: "bitbucket-original.svg" },
  {
    label: "Handlebars",
    value: "e3k8rcyh",
    logoPath: "handlebars-original.svg",
  },
  { label: "Vue-router", value: "yw6gqarv", logoPath: "vuejs-original.svg" },
  { label: "Haskell", value: "c2l6fn7s", logoPath: "haskell-plain.svg" },
  { label: "Vuetify.JS", value: "zra2gz5b", logoPath: "vuejs-original.svg" },
  { label: "Vuex", value: "kczd8e9e", logoPath: "vuejs-original.svg" },
  { label: "Heroku", value: "0t7yq4qe", logoPath: "heroku-plain.svg" },
  { label: "Heroku Pipeline", value: "g7gpug5f", logoPath: "heroku-plain.svg" },
  { label: "Bootstrap 3", value: "zmljhqbv", logoPath: "bootstrap-plain.svg" },
  { label: "Bootstrap 4", value: "xfwouvqo", logoPath: "bootstrap-plain.svg" },
  { label: "Mocha", value: "c2ejx4eb", logoPath: "mocha-plain.svg" },
  { label: "RabbitMQ", value: "xmtorg4a", logoPath: "rabbitmq.svg" },
  { label: "Rails4", value: "4x5yu2ry", logoPath: "rails-plain.svg" },
  {
    label: "Amazon Athena",
    value: "bh084ln0",
    logoPath: "amazonwebservices-original.svg",
  },
  {
    label: "Amazon Aurora",
    value: "8w9piins",
    logoPath: "amazonwebservices-original.svg",
  },
  {
    label: "Amazon Certificate Manager",
    value: "q5rkwxs6",
    logoPath: "amazonwebservices-original.svg",
  },
  {
    label: "Amazon Echo",
    value: "vifzykve",
    logoPath: "amazonwebservices-original.svg",
  },
  {
    label: "Amazon RDS",
    value: "oppmp7sh",
    logoPath: "amazonwebservices-original.svg",
  },
  {
    label: "Amazon S3",
    value: "6vyazr2q",
    logoPath: "amazonwebservices-original.svg",
  },
  {
    label: "Amazon Stick",
    value: "xwok4xup",
    logoPath: "amazonwebservices-original.svg",
  },
  { label: "React-Router", value: "dnwuqq5n", logoPath: "react-original.svg" },
  { label: "Angular 1.x", value: "1grfa3gq", logoPath: "angularjs-plain.svg" },
  { label: "Angular 2+", value: "3oaivyov", logoPath: "angularjs-plain.svg" },
  { label: "Angular 4", value: "mlkvq4cf", logoPath: "angularjs-plain.svg" },
  { label: "Angular 5", value: "sh5mkht8", logoPath: "angularjs-plain.svg" },
  { label: "Angular 5+", value: "9if1tfnu", logoPath: "angularjs-plain.svg" },
  { label: "Angular 6", value: "6xpv8rxn", logoPath: "angularjs-plain.svg" },
  { label: "Angular 7", value: "jnryxge9", logoPath: "angular-js-plain.svg" },
  { label: "Redis Cache", value: "qolti0a0", logoPath: "redis-plain.svg" },
  { label: "Angular 8", value: "mksjqsln", logoPath: "angularjs-plain.svg" },
  { label: "Symfony 2", value: "vrhbt8se", logoPath: "symfony-original.svg" },
  {
    label: "Symfony Framework",
    value: "jhbwqm92",
    logoPath: "symfony-original.svg",
  },
  { label: "Angular.JS", value: "kszlneeg", logoPath: "angularjs-plain.svg" },
  { label: "Java 8", value: "lbhmr1lh", logoPath: "java-plain.svg" },
  { label: "Java FX", value: "umax5ec6", logoPath: "java-plain.svg" },
  { label: "Java ME", value: "v62l80dh", logoPath: "java-plain.svg" },
  { label: "Java Multithread", value: "qdtzg2sh", logoPath: "java-plain.svg" },
  {
    label: "Java Persistence API (JPA)",
    value: "hvgig6hj",
    logoPath: "java-plain.svg",
  },
  { label: "Java SE", value: "9weu43sq", logoPath: "java-plain.svg" },
  { label: "Twitter API", value: "beliv7kl", logoPath: "twitter-original.svg" },
  { label: "Ubuntu", value: "qljopfa4", logoPath: "ubuntu-plain.svg" },
  { label: "Git", value: "xtxmqldl", logoPath: "git-plain.svg" },
  { label: "Github", value: "sx0wq6e2", logoPath: "github-original.svg" },
  { label: "Gitlab", value: "la950evh", logoPath: "gitlab-plain.svg" },
  { label: "GitLab CI", value: "rxcehzrd", logoPath: "gitlab-plain.svg" },
  {
    label: "AWS Cloud",
    value: "mlmeh8ue",
    logoPath: "amazonwebservices-original.svg",
  },
  {
    label: "AWS Athena",
    value: "xch50x87",
    logoPath: "amazonwebservices-original.svg",
  },
  {
    label: "AWS CloudFormation",
    value: "ppsnrasl",
    logoPath: "amazonwebservices-original.svg",
  },
  { label: "LinkedIn API", value: "aq3deo0b", logoPath: "linkedin-plain.svg" },
  {
    label: "AWS CloudWatch",
    value: "kb2iaby6",
    logoPath: "amazonwebservices-original.svg",
  },
  {
    label: "AWS Codebuild",
    value: "ip4a3jyl",
    logoPath: "amazonwebservices-original.svg",
  },
  {
    label: "AWS Codepipeline",
    value: "pzxcpwiq",
    logoPath: "amazonwebservices-original.svg",
  },
  { label: "Linux", value: "rb0is96l", logoPath: "linux-plain.svg" },
  {
    label: "AWS Cognito",
    value: "uthjhk7j",
    logoPath: "amazonwebservices-original.svg",
  },
  {
    label: "AWS Data Pipeline",
    value: "iyahehlw",
    logoPath: "amazonwebservices-original.svg",
  },
  {
    label: "AWS Dynamo",
    value: "fq0mztoz",
    logoPath: "amazonwebservices-original.svg",
  },
  {
    label: "AWS EC2",
    value: "qzldicne",
    logoPath: "amazonwebservices-original.svg",
  },
  {
    label: "AWS Elastic Beanstalk",
    value: "fnpoxrhu",
    logoPath: "amazonwebservices-original.svg",
  },
  {
    label: "AWS EMR",
    value: "gkmnn9zh",
    logoPath: "amazonwebservices-original.svg",
  },
  {
    label: "AWS Glue",
    value: "hfvwqpzh",
    logoPath: "amazonwebservices-original.svg",
  },
  {
    label: "AWS Kinesis",
    value: "zafovy3t",
    logoPath: "amazonwebservices-original.svg",
  },
  {
    label: "AWS Kinesis Firehose",
    value: "srnye0xn",
    logoPath: "amazonwebservices-original.svg",
  },
  {
    label: "AWS Load Balancer",
    value: "ay3i9eid",
    logoPath: "amazonwebservices-original.svg",
  },
  {
    label: "AWS Quick Sight",
    value: "6vbhymt1",
    logoPath: "amazonwebservices-original.svg",
  },
  {
    label: "AWS RDS",
    value: "eh6fjhei",
    logoPath: "amazonwebservices-original.svg",
  },
  {
    label: "AWS S3",
    value: "v21on4ey",
    logoPath: "amazonwebservices-original.svg",
  },
  {
    label: "AWS Serverless",
    value: "0dogjzjo",
    logoPath: "amazonwebservices-original.svg",
  },
  {
    label: "AWS SNS",
    value: "yt6rbhpo",
    logoPath: "amazonwebservices-original.svg",
  },
  {
    label: "AWS SQS",
    value: "ruhple0u",
    logoPath: "amazonwebservices-original.svg",
  },
  { label: "PHP Storm", value: "ydeg2lkv", logoPath: "php-logo.svg" },
  { label: "Backbone.js", value: "wgrem9n6", logoPath: "backbonejs-plain.svg" },
  { label: "Nginx", value: "fzev2zxy", logoPath: "nginx-original.svg" },
  { label: "Cucumber", value: "sgroiyhu", logoPath: "cucumber-plain.svg" },
  { label: "BASS", value: "qnt6mhch", logoPath: "" },
  { label: "Detailed UI Design", value: "tu4h501t", logoPath: "" },
  { label: "Groovy Grails", value: "sxq3gqnd", logoPath: "" },
  { label: "Material Design", value: "reag3ilr", logoPath: "" },
  { label: "Polymer", value: "7huyjrdb", logoPath: "" },
  { label: "SMACSS", value: "rcnbvcjj", logoPath: "" },
  { label: "VirtualBox", value: "znofbwtn", logoPath: "" },
  { label: "Batch", value: "zafssbcg", logoPath: "" },
  { label: "Detox", value: "4qmbbda1", logoPath: "" },
  { label: "gRPC", value: "mipidbig", logoPath: "" },
  { label: "Material UI", value: "gvlzwskk", logoPath: "" },
  { label: "Portfolio Management", value: "7fbygyud", logoPath: "" },
  { label: "Smart Contracts", value: "pgli905y", logoPath: "" },
  { label: "virtualenv", value: "gm8pxq8j", logoPath: "" },
  { label: "Beanstalkd", value: "nhv4c1ej", logoPath: "" },
  { label: "DevFactory aLine", value: "dxvchvo5", logoPath: "" },
  { label: "Grunt", value: "eltp7dxi", logoPath: "" },
  { label: "Mathematical Algorithms", value: "zmxflxlz", logoPath: "" },
  { label: "Positive Attitude", value: "cpf4tv9v", logoPath: "" },
  { label: "Smarty", value: "cd130qq2", logoPath: "" },
  { label: "Virtualization", value: "cnanenop", logoPath: "" },
  { label: "App Architecture", value: "gcb4qke8", logoPath: "" },
  { label: "Beatifulsoup", value: "nzw1kpvv", logoPath: "" },
  { label: "Devise", value: "9ibqnrbx", logoPath: "" },
  { label: "GTK", value: "xknlogpj", logoPath: "" },
  { label: "Matlab", value: "ghmymhnn", logoPath: "" },
  { label: "Post Acquisitions Integrations", value: "ocx4sctg", logoPath: "" },
  { label: "SMPP", value: "e2uwvof3", logoPath: "" },
  { label: "Visual Basic", value: "ibxrh9s7", logoPath: "" },
  { label: "Atlassian Bamboo", value: "p43yq5r4", logoPath: "" },
  { label: "Beautifulsoap", value: "bwlbjyey", logoPath: "" },
  { label: "DevOps", value: "w3wpnbgr", logoPath: "" },
  { label: "GuardDuty", value: "7bobchy9", logoPath: "" },
  { label: "Matplotlib", value: "hrlfrskc", logoPath: "" },
  { label: "PostCSS", value: "2eewyosa", logoPath: "" },
  { label: "SMS Micro Payment", value: "ntquwrsh", logoPath: "" },
  { label: "Visual Paradigm", value: "8xrrajuj", logoPath: "" },
  { label: "Cypress.io", value: "ii00gcgx", logoPath: "" },
  { label: "Beego", value: "swglylfs", logoPath: "" },
  { label: "DevTest", value: "qsz6yq3t", logoPath: "" },
  { label: "Guice", value: "nscase0u", logoPath: "" },
  { label: "Maya", value: "cqitxihk", logoPath: "" },
  { label: "Postfix", value: "ytw9tfoh", logoPath: "" },
  { label: "Snowflake", value: "5xzab77x", logoPath: "" },
  { label: "Visual Studio Code", value: "sghoqtj0", logoPath: "" },
  { label: "Eclipse RCP", value: "cdnawwfl", logoPath: "" },
  { label: "Behat", value: "k6knwggp", logoPath: "" },
  { label: "DevTrack", value: "ndqvlcwq", logoPath: "" },
  { label: "Mcollective", value: "na7pdjnk", logoPath: "" },
  { label: "PostGis", value: "f9omouhe", logoPath: "" },
  { label: "SOA", value: "98p3bgt1", logoPath: "" },
  { label: "VisualForce", value: "tki0zymb", logoPath: "" },
  { label: "Identity Framework", value: "ovnjjba7", logoPath: "" },
  {
    label: "Behavior-driven development (BDD)",
    value: "km1ou6g9",
    logoPath: "",
  },
  { label: "DialogFlow (Chatbots)", value: "et3jyhyi", logoPath: "" },
  { label: "Mechanize", value: "4eimudja", logoPath: "" },
  { label: "SOAP", value: "kilu7ouf", logoPath: "" },
  { label: "VK API", value: "yp1dxnpu", logoPath: "" },
  { label: "Information Architecture", value: "b2e5sp4v", logoPath: "" },
  { label: "BEM", value: "boengncd", logoPath: "" },
  { label: "Digital Advertising", value: "lahiku6g", logoPath: "" },
  { label: "GWS", value: "b3t2r6vw", logoPath: "" },
  { label: "Media Excel", value: "4pcseq6v", logoPath: "" },
  { label: "Postman API", value: "jz3bhvnb", logoPath: "" },
  { label: "SOAP API", value: "tmsobvhv", logoPath: "" },
  { label: "VmWare", value: "fiopagb7", logoPath: "" },
  { label: "IOC", value: "9skq1jni", logoPath: "" },
  { label: "BEMIT", value: "xgrq72m4", logoPath: "" },
  { label: "Digital Certificates Process", value: "uvnefvek", logoPath: "" },
  { label: "GWT", value: "ylils0il", logoPath: "" },
  { label: "Memcached", value: "pdotf6hi", logoPath: "" },
  { label: "Power Pivots", value: "6uhiskwq", logoPath: "" },
  { label: "Socailite", value: "bismd3uk", logoPath: "" },
  { label: "VoIP", value: "wdp99w6i", logoPath: "" },
  { label: "JavaEE", value: "agykuv7z", logoPath: "" },
  { label: "Big Commerce", value: "obdqqnkz", logoPath: "" },
  { label: "Digital Marketing", value: "6yhhta8o", logoPath: "" },
  { label: "GXT", value: "ksofmoeb", logoPath: "" },
  { label: "Mercurial", value: "fnrmpyyz", logoPath: "" },
  { label: "PowerBI", value: "th6xibld", logoPath: "" },
  { label: "Social APIs", value: "3kqyt30f", logoPath: "" },
  { label: "Volley", value: "v6ncdr4o", logoPath: "" },
  { label: "Serverless Application", value: "yrip0vul", logoPath: "" },
  { label: "Big Data", value: "xvtxgaoj", logoPath: "" },
  { label: "Direct Connect", value: "fcyj1u67", logoPath: "" },
  { label: "Hadoop", value: "xdlnse64", logoPath: "" },
  { label: "Merit CRM API", value: "5bof7aw4", logoPath: "" },
  { label: "PowerMTA", value: "01gho6kt", logoPath: "" },
  { label: "Social Integration", value: "lpwerdng", logoPath: "" },
  { label: "VoltDB", value: "e3jgu1yk", logoPath: "" },
  { label: "Serverless Architecture", value: "u4fr0nqr", logoPath: "" },
  { label: "Big Query", value: "oznfvjpz", logoPath: "" },
  { label: "Direct Input", value: "k1bwzdry", logoPath: "" },
  { label: "Hamcrest", value: "vviftstl", logoPath: "" },
  { label: "MessageBus", value: "wm8xoqka", logoPath: "" },
  { label: "Powershell", value: "z8i14nie", logoPath: "" },
  { label: "Social Media", value: "cbdowxzt", logoPath: "" },
  { label: "Vowpal Wabbit", value: "omhjprel", logoPath: "" },
  { label: "Shell Scripting", value: "cgdn6jtj", logoPath: "" },
  { label: "BinData", value: "nejp5l9l", logoPath: "" },
  { label: "Direct3D", value: "ibbq0xgj", logoPath: "" },
  { label: "HAML", value: "aufl9eq4", logoPath: "" },
  { label: "Messaging Queues", value: "i6xhl6om", logoPath: "" },
  { label: "Pprof", value: "evgh5cqc", logoPath: "" },
  { label: "Social Media Marketing", value: "fztzkfom", logoPath: "" },
  { label: "VPC", value: "goepwmg6", logoPath: "" },
  { label: "Soap UI", value: "mqhjzxbx", logoPath: "" },
  { label: "BIRT", value: "o3de0iu4", logoPath: "" },
  { label: "Directory Service", value: "7dc04v0r", logoPath: "" },
  { label: "Hammer", value: "q1j6dsrl", logoPath: "" },
  { label: "Metal Framework", value: "wzfyzpqy", logoPath: "" },
  { label: "Practitest", value: "idyieq0h", logoPath: "" },
  { label: "Social Network SDKs", value: "dy01apyt", logoPath: "" },
  { label: "VPS", value: "jm6mj081", logoPath: "" },
  { label: "Solus Panel", value: "hybrpajv", logoPath: "" },
  { label: "Directus CMS", value: "2ryq5hws", logoPath: "" },
  { label: "Metasploit", value: "dc9eduax", logoPath: "" },
  { label: "Preact", value: "9t90yvfz", logoPath: "" },
  { label: "Socket.io", value: "hvyli3ru", logoPath: "" },
  { label: "VsCode", value: "npmmd41l", logoPath: "" },
  { label: "Spring Framework", value: "zw1fe7jo", logoPath: "" },
  { label: "Bitcoin", value: "gclddwxl", logoPath: "" },
  { label: "Distributed Systems", value: "8mgimadl", logoPath: "" },
  { label: "Hansoft", value: "yibkdszq", logoPath: "" },
  { label: "Predictive Analytics", value: "hys4b557", logoPath: "" },
  { label: "SockJS", value: "uu8sjlrg", logoPath: "" },
  { label: "vSphere", value: "8oxdpxnd", logoPath: "" },
  { label: "Stunnel", value: "l9pjxjy4", logoPath: "" },
  { label: "Bitcore", value: "ytmfevzo", logoPath: "" },
  { label: "Hapi.js", value: "dkzmrali", logoPath: "" },
  { label: "MFC", value: "i5jfpsjv", logoPath: "" },
  { label: "PrestaShop", value: "8bx4xwxc", logoPath: "" },
  { label: "SoftEther VPN", value: "jwmpmftq", logoPath: "" },
  { label: "Vtiger CRM", value: "409ur9dk", logoPath: "" },
  { label: "(S)CSS", value: "je8lxzqh", logoPath: "" },
  { label: "Bitrise", value: "tiwo8pax", logoPath: "" },
  { label: "Haproxy", value: "a4378exg", logoPath: "" },
  { label: "Microinteractions", value: "eqamhozw", logoPath: "" },
  { label: "Primefaces", value: "z6j33sug", logoPath: "" },
  { label: "SoftImage", value: "8jxszc9j", logoPath: "" },
  { label: "Blackberry", value: "g0zl7bow", logoPath: "" },
  { label: "Microservices", value: "8udhrout", logoPath: "" },
  { label: "PRINCE2", value: "kmwzottp", logoPath: "" },
  { label: "Software Architecture", value: "ujfztfx3", logoPath: "" },
  { label: "BLE (Bluetooth Low Energy)", value: "c4aetlef", logoPath: "" },
  { label: "Hazelcast", value: "3ucykv8c", logoPath: "" },
  { label: "Microsoft 365 Sharepoint", value: "0gtcqnfu", logoPath: "" },
  { label: "Principle", value: "kywxculy", logoPath: "" },
  { label: "Software Design", value: "xlpr0qaa", logoPath: "" },
  { label: "Blender", value: "v7t5gbge", logoPath: "" },
  { label: "DLE (DataLife Engine)", value: "getmjgar", logoPath: "" },
  { label: "HBase", value: "wllhveby", logoPath: "" },
  { label: "Microsoft Access", value: "frofhdzl", logoPath: "" },
  { label: "Prisma", value: "9pukzjns", logoPath: "" },
  { label: "Software Development", value: "ro2cprnz", logoPath: "" },
  { label: "Blockchain", value: "12serytq", logoPath: "" },
  { label: "dlib", value: "orbp6hdg", logoPath: "" },
  { label: "HealthKit", value: "4yi4rkgq", logoPath: "" },
  { label: "Microsoft Azure", value: "evldlefh", logoPath: "" },
  { label: "Private Equity", value: "jijjmain", logoPath: "" },
  { label: "Software Engineering", value: "bnks3lc9", logoPath: "" },
  { label: "Vultr", value: "k1t6m4fj", logoPath: "" },
  { label: "3g", value: "ogtegysv", logoPath: "" },
  { label: "Bloomberg", value: "gdn0mwly", logoPath: "" },
  { label: "DNS", value: "x6aamitv", logoPath: "" },
  { label: "Heap/Thread Dump", value: "7nuloc3a", logoPath: "" },
  { label: "Microsoft Dynamic NAV", value: "ut5xond4", logoPath: "" },
  { label: "Private Ethereum Networks", value: "03wwxrrg", logoPath: "" },
  { label: "Software Product Management", value: "p6dvwrcv", logoPath: "" },
  { label: "WAF", value: "5afzfrrm", logoPath: "" },
  { label: "3G video", value: "23c6ywsa", logoPath: "" },
  { label: "Blue Prism", value: "00b2wib4", logoPath: "" },
  { label: "DNS Protocol", value: "u7evdnfw", logoPath: "" },
  { label: "Hedara Hashgraph", value: "ukfdeytp", logoPath: "" },
  { label: "Microsoft Excel", value: "uvosh5rn", logoPath: "" },
  { label: "Proactivity", value: "pt3cul3z", logoPath: "" },
  { label: "Solaris", value: "ggfwtrt0", logoPath: "" },
  { label: "WAS", value: "vrgicryy", logoPath: "" },
  { label: "A/B Testing", value: "t29ulh4y", logoPath: "" },
  { label: "Bluecoat Proxy", value: "puwib15x", logoPath: "" },
  { label: "HeidiSQL", value: "zdyglsnf", logoPath: "" },
  { label: "Microsoft Exchange Server", value: "wgcaavhr", logoPath: "" },
  { label: "Problem Solving", value: "norqchxq", logoPath: "" },
  { label: "SOLID", value: "ds00qre8", logoPath: "" },
  { label: "WatchKit", value: "lcaebnzn", logoPath: "" },
  { label: "Abstract", value: "xpgw9lho", logoPath: "" },
  { label: "Bluemix", value: "eu5m6ayy", logoPath: "" },
  { label: "Microsoft Fakes", value: "tlcwvyrp", logoPath: "" },
  { label: "Product & Service Design", value: "gsbap3hw", logoPath: "" },
  { label: "Solidity", value: "gpbe6e9d", logoPath: "" },
  { label: "WatchOS", value: "pxvvsrq5", logoPath: "" },
  { label: "Accounting & Finance", value: "brnqdler", logoPath: "" },
  { label: "Bluetooth Firmware", value: "lhgrtbzi", logoPath: "" },
  { label: "Microsoft Luis", value: "iosofsen", logoPath: "" },
  { label: "Product Development", value: "moguczae", logoPath: "" },
  { label: "Solr", value: "pvyebhqj", logoPath: "" },
  { label: "Waterfall", value: "ibtcupme", logoPath: "" },
  { label: "ACL", value: "akerznxs", logoPath: "" },
  { label: "Bluetooth low-energy", value: "kle47cje", logoPath: "" },
  { label: "Heuristic Evaluation", value: "cflpzjr2", logoPath: "" },
  { label: "Microsoft Note", value: "enrdpzng", logoPath: "" },
  { label: "Product Innovation", value: "pp3saf6e", logoPath: "" },
  { label: "Sonar", value: "bw5iqje9", logoPath: "" },
  { label: "Watir", value: "nkr1rxxm", logoPath: "" },
  { label: "Acquia", value: "gprg9psh", logoPath: "" },
  { label: "BOBJ", value: "ma5xmygv", logoPath: "" },
  { label: "Doctrine", value: "pdl1xpoh", logoPath: "" },
  { label: "Hibernate", value: "ptew8gkl", logoPath: "" },
  { label: "Microsoft Office", value: "mhayygeg", logoPath: "" },
  { label: "Product Management", value: "jopgwdlq", logoPath: "" },
  { label: "SonarQube", value: "hwwubvtk", logoPath: "" },
  { label: "Wayfinding", value: "ivfk6s4v", logoPath: "" },
  { label: "Actionscript", value: "uqxujmye", logoPath: "" },
  { label: "BODS", value: "6zyc9mge", logoPath: "" },
  { label: "DoIT", value: "exbggwuf", logoPath: "" },
  { label: "High Charts", value: "opl0l4dv", logoPath: "" },
  { label: "Microsoft Outlook", value: "w0zvisty", logoPath: "" },
  { label: "Product Owner", value: "v37h6ind", logoPath: "" },
  { label: "Sonatype", value: "flxxhowm", logoPath: "" },
  { label: "WCF", value: "r3ggd0kn", logoPath: "" },
  { label: "Actionscript 2", value: "rsigyx8l", logoPath: "" },
  { label: "Bokeh", value: "vz9t2fyf", logoPath: "" },
  { label: "DOJO", value: "mzzwu0pd", logoPath: "" },
  { label: "High-Load", value: "jpman92t", logoPath: "" },
  { label: "Microsoft Project", value: "imugi51i", logoPath: "" },
  { label: "Proftpd", value: "e9ly6cty", logoPath: "" },
  { label: "Sonicwall", value: "s8vduzz1", logoPath: "" },
  { label: "Web 3", value: "kwymyk8e", logoPath: "" },
  { label: "Actionscript 3", value: "fzne617g", logoPath: "" },
  { label: "Boost", value: "ah5i2dig", logoPath: "" },
  { label: "Domain Driven Design", value: "06z0pbnv", logoPath: "" },
  { label: "Hip Chat", value: "adsm7epf", logoPath: "" },
  {
    label: "Microsoft Service Fabric (microservice)",
    value: "pgahvnrn",
    logoPath: "",
  },
  { label: "Program management", value: "o61nkamv", logoPath: "" },
  { label: "Soot Framework", value: "rj1hucik", logoPath: "" },
  { label: "Web API", value: "1a31htiz", logoPath: "" },
  { label: "Active Directory", value: "4fzoylbs", logoPath: "" },
  { label: "Domain-driven design (DDD)", value: "kjq0kb2w", logoPath: "" },
  { label: "HIPAA", value: "memdrank", logoPath: "" },
  {
    label: "Microsoft System Center Configuration Manager",
    value: "gxfpg6g3",
    logoPath: "",
  },
  { label: "Project Management", value: "qkpezpgw", logoPath: "" },
  { label: "SOQL", value: "1pjpnpho", logoPath: "" },
  { label: "Web App Design", value: "zwplu5fm", logoPath: "" },
  { label: "ActiveCollab", value: "r6uipqnh", logoPath: "" },
  { label: "Dos Batch", value: "spuzm256", logoPath: "" },
  { label: "Hiptest", value: "2v1eew0s", logoPath: "" },
  { label: "Microsoft Teams", value: "p8odkjdm", logoPath: "" },
  { label: "Project Planning", value: "5wtvvnqg", logoPath: "" },
  { label: "SOTI MDM", value: "v2s6zhrz", logoPath: "" },
  { label: "Web Application Design", value: "yinsifm7", logoPath: "" },
  { label: "ActiveMQ", value: "exfkayvb", logoPath: "" },
  { label: "Botkit", value: "whaf0fhc", logoPath: "" },
  { label: "Dream Host", value: "orpomoj4", logoPath: "" },
  { label: "Hive", value: "7d4hxeqi", logoPath: "" },
  { label: "Microsoft Test Professional", value: "hpgdsqai", logoPath: "" },
  { label: "Prometheus", value: "mujvoxm8", logoPath: "" },
  { label: "Source Forge", value: "dfvl7bnn", logoPath: "" },
  { label: "Web Components", value: "qbxcbdcz", logoPath: "" },
  { label: "ActiveMQ Apollo", value: "dkeymbju", logoPath: "" },
  { label: "Boto3", value: "fc49up8y", logoPath: "" },
  { label: "DRF", value: "ews5idoc", logoPath: "" },
  { label: "HPE App Pulse", value: "a4etliq4", logoPath: "" },
  { label: "Microsoft Visual Studio", value: "64bbqscs", logoPath: "" },
  { label: "Property-based Testing", value: "bh1dixpv", logoPath: "" },
  { label: "Sourcefire", value: "pvcaq4lr", logoPath: "" },
  { label: "Web Crawler", value: "z3addsmw", logoPath: "" },
  { label: "Activiti", value: "7ynizrrq", logoPath: "" },
  { label: "Boujou", value: "cqeysuii", logoPath: "" },
  { label: "drm", value: "gorhokjf", logoPath: "" },
  { label: "HPE Load Runner", value: "ihv7ulke", logoPath: "" },
  { label: "Microsoft Word", value: "bc1u6gey", logoPath: "" },
  { label: "Protobuf", value: "hdmlq7fv", logoPath: "" },
  { label: "Sourcetree", value: "sl7yg1fp", logoPath: "" },
  { label: "Web Design", value: "bgo6bqtj", logoPath: "" },
  { label: "Address Book", value: "yknzjzgn", logoPath: "" },
  { label: "Bower", value: "lrhbwzps", logoPath: "" },
  { label: "Dropwizard", value: "iezptaj3", logoPath: "" },
  { label: "HPE Service Virtualisation", value: "b6pmbgad", logoPath: "" },
  { label: "MikroTik", value: "ieedqdow", logoPath: "" },
  { label: "Protocol Buffers", value: "5zkbkkvs", logoPath: "" },
  { label: "spacy", value: "hvbgn7zm", logoPath: "" },
  { label: "Web Development", value: "fkasl4q8", logoPath: "" },
  { label: "ADO", value: "rl7cuxps", logoPath: "" },
  { label: "BPMN", value: "ym2xc0m2", logoPath: "" },
  { label: "DRP", value: "uzks2rzi", logoPath: "" },
  { label: "HTML", value: "d06gw40z", logoPath: "" },
  { label: "Mina", value: "tl0j2iob", logoPath: "" },
  { label: "Protocol Oriented Programming", value: "j8ujyqva", logoPath: "" },
  { label: "SpagoBI", value: "wyvdeafe", logoPath: "" },
  { label: "Web Forms", value: "5zthvnww", logoPath: "" },
  { label: "ADO.NET", value: "sqcbrh4n", logoPath: "" },
  { label: "Braintree Payment", value: "e5tnpobk", logoPath: "" },
  { label: "Druid", value: "p7ec6sma", logoPath: "" },
  { label: "MithrilJS", value: "plq5hmpj", logoPath: "" },
  { label: "Prototype", value: "gq14hctk", logoPath: "" },
  { label: "Spark", value: "yvcjumbc", logoPath: "" },
  { label: "Web Logic", value: "ocozvtza", logoPath: "" },
  { label: "Adobe AEM", value: "99jvsovv", logoPath: "" },
  { label: "Branch.io", value: "sxgpoabv", logoPath: "" },
  { label: "Drupal", value: "jtaktlot", logoPath: "" },
  { label: "Hubspot", value: "hp9skylu", logoPath: "" },
  { label: "Mixed Reality Illustrations", value: "oroenx8x", logoPath: "" },
  { label: "Prototype.js", value: "rwno3gwy", logoPath: "" },
  { label: "Spatial Data", value: "eb4twtua", logoPath: "" },
  { label: "Web Scraping", value: "myykgzud", logoPath: "" },
  { label: "Adobe After Effects", value: "5kvilfox", logoPath: "" },
  { label: "Branding", value: "ioywnnpq", logoPath: "" },
  { label: "Dry-monads", value: "awuughcm", logoPath: "" },
  { label: "Hudson", value: "lum1meyp", logoPath: "" },
  { label: "MixPanel", value: "dcfrxenf", logoPath: "" },
  { label: "Prototypes", value: "2ckre7zb", logoPath: "" },
  { label: "SpecFlow", value: "msat7259", logoPath: "" },
  { label: "Web Security", value: "jxw5uhle", logoPath: "" },
  { label: "Adobe CQ", value: "jpuzeup0", logoPath: "" },
  { label: "Branding & Identity", value: "77vlzmgv", logoPath: "" },
  { label: "Dry-struct", value: "oyuudyv4", logoPath: "" },
  { label: "Hybris", value: "hzk3gfwk", logoPath: "" },
  { label: "ML Deployment", value: "bbmhh0kv", logoPath: "" },
  { label: "Prototyping", value: "7gkcaiyc", logoPath: "" },
  { label: "Sphinx", value: "tdipb3rj", logoPath: "" },
  { label: "Web Services", value: "gwdokogd", logoPath: "" },
  { label: "Adobe Creative Suite", value: "ghtap5id", logoPath: "" },
  { label: "BTCD", value: "zfyrlqas", logoPath: "" },
  { label: "Dry-types", value: "wd6v5iku", logoPath: "" },
  { label: "Hyper V", value: "vew9btop", logoPath: "" },
  { label: "Mobile App Design", value: "uvhdoq9r", logoPath: "" },
  { label: "Protractor", value: "lu8sog8v", logoPath: "" },
  { label: "Spinach", value: "qvx6kghc", logoPath: "" },
  { label: "Web Socket", value: "yl6rqvdv", logoPath: "" },
  { label: "Adobe DPS", value: "yntrzgn4", logoPath: "" },
  { label: "Buddy Works", value: "mhxh1vef", logoPath: "" },
  { label: "Due Dilligence", value: "tu5dmaeu", logoPath: "" },
  { label: "Hyperledger", value: "lcsiizie", logoPath: "" },
  { label: "Mobile App Tracking", value: "rycnmhll", logoPath: "" },
  { label: "Proxmox", value: "0nc9rrxq", logoPath: "" },
  { label: "SPINGINX", value: "jtszy3zd", logoPath: "" },
  { label: "Web/WS Security", value: "16ffkokg", logoPath: "" },
  { label: "Adobe DreamWeaver", value: "p27vtrlj", logoPath: "" },
  { label: "Buffalo", value: "jkkohbyx", logoPath: "" },
  { label: "DynamoDB", value: "4vez6fzi", logoPath: "" },
  { label: "Hypervisors", value: "tu2qxcoa", logoPath: "" },
  { label: "Mobile Applications", value: "ev2xhuqx", logoPath: "" },
  { label: "PUG", value: "3x8uctjr", logoPath: "" },
  { label: "Spinnaker", value: "s1iho4iu", logoPath: "" },
  { label: "Web3.js", value: "7bquaygt", logoPath: "" },
  { label: "Adobe Flash", value: "lebozbei", logoPath: "" },
  { label: "Bundler", value: "sp0zcchq", logoPath: "" },
  { label: "E-commerce", value: "ditj4n94", logoPath: "" },
  { label: "IAM", value: "3qwrkov5", logoPath: "" },
  { label: "Mobile Banking Development", value: "jnve5e0x", logoPath: "" },
  { label: "Puppet", value: "4xggnq6l", logoPath: "" },
  { label: "Splunk", value: "z9augycb", logoPath: "" },
  { label: "Web3Swift", value: "mhk95imx", logoPath: "" },
  { label: "Adobe Flex", value: "codvgzvz", logoPath: "" },
  { label: "Business Analysis", value: "lnnerm4p", logoPath: "" },
  { label: "Earthworm", value: "njcubeds", logoPath: "" },
  { label: "IAP", value: "jpymxk2a", logoPath: "" },
  { label: "Mobile Design", value: "euscp0ta", logoPath: "" },
  { label: "Push Notifications", value: "y4wzylj3", logoPath: "" },
  { label: "Spock", value: "zawl56ji", logoPath: "" },
  { label: "WebApp2", value: "ftojxmgm", logoPath: "" },
  { label: "Adobe Illustrator", value: "kzampvyq", logoPath: "" },
  { label: "Business Development", value: "mbbskqxb", logoPath: "" },
  { label: "Easy Mock", value: "xwxux01z", logoPath: "" },
  { label: "IBatis", value: "jecgjk6h", logoPath: "" },
  { label: "Mobile TV", value: "4wgyhvki", logoPath: "" },
  { label: "PWA", value: "8ttuvkm4", logoPath: "" },
  { label: "Spree", value: "bwvczfxm", logoPath: "" },
  { label: "Webflow", value: "cao3brxf", logoPath: "" },
  { label: "Adobe InDesign", value: "8b29hucz", logoPath: "" },
  { label: "Business Intelligence", value: "qsigh9gz", logoPath: "" },
  { label: "EBS", value: "cazep1bg", logoPath: "" },
  { label: "IBM BPM", value: "1db0wedl", logoPath: "" },
  { label: "MobX", value: "tilhvmur", logoPath: "" },
  { label: "Pycharm", value: "3zay4g5p", logoPath: "" },
  { label: "Spring", value: "tw3exuqy", logoPath: "" },
  { label: "WebGL", value: "mijbuugt", logoPath: "" },
  { label: "Adobe Ominture", value: "pgwnvyzc", logoPath: "" },
  { label: "Business Objects", value: "06qtmpso", logoPath: "" },
  { label: "Eclipse", value: "z4hdet6q", logoPath: "" },
  { label: "IBM BPMN", value: "n91290gc", logoPath: "" },
  { label: "pyenv", value: "6s9k7wil", logoPath: "" },
  { label: "Spring Batch", value: "tfmpzofn", logoPath: "" },
  { label: "Webkit Code", value: "zmydal53", logoPath: "" },
  { label: "Adobe Photoshop", value: "r5bmb6s7", logoPath: "" },
  { label: "Butterfly Framework", value: "3q1vbzys", logoPath: "" },
  { label: "Eclipse IDE", value: "lrafryji", logoPath: "" },
  { label: "IBM Dimensions", value: "kpsexrwh", logoPath: "" },
  { label: "Mockgen", value: "gvz1hnwc", logoPath: "" },
  { label: "Pyqgis", value: "ycjarc3k", logoPath: "" },
  { label: "Spring Boot", value: "fwyx8wew", logoPath: "" },
  { label: "Weblogic Application Server", value: "mho7lqvx", logoPath: "" },
  { label: "Adobe Premiere", value: "f0ojjf4w", logoPath: "" },
  { label: "ECMA", value: "j6yrtbib", logoPath: "" },
  { label: "IBM Integration Bus", value: "tdwuyfdc", logoPath: "" },
  { label: "Mocking", value: "wkirnzx9", logoPath: "" },
  { label: "PyQt", value: "sp60d0hv", logoPath: "" },
  { label: "Spring Core", value: "wtii8h4h", logoPath: "" },
  { label: "Adobe XD", value: "zdbhjnfo", logoPath: "" },
  { label: "ECS", value: "7lsksdgx", logoPath: "" },
  { label: "IBM WebSphere", value: "6nl8ybtu", logoPath: "" },
  { label: "Mockito", value: "wftpx3tu", logoPath: "" },
  { label: "Pyramid", value: "kpb1jqyp", logoPath: "" },
  { label: "Spring Data", value: "tpkm76d7", logoPath: "" },
  { label: "WebRTC", value: "eni5dve4", logoPath: "" },
  { label: "Adonis.js", value: "3e1rdg40", logoPath: "" },
  { label: "C++", value: "4qd0sfzb", logoPath: "" },
  { label: "EJB 3", value: "tpqvder3", logoPath: "" },
  { label: "IBM Websphere MQ", value: "qstorwgs", logoPath: "" },
  { label: "Mockk", value: "375p8quy", logoPath: "" },
  { label: "Pyspark", value: "bzsk85sj", logoPath: "" },
  { label: "Spring DM", value: "axmbsivq", logoPath: "" },
  { label: "WebSocket", value: "yymzjwlw", logoPath: "" },
  { label: "Aerospike", value: "3f4zwkss", logoPath: "" },
  { label: "C++ STL", value: "4o1ey7dy", logoPath: "" },
  { label: "EKS", value: "dsv3twfq", logoPath: "" },
  { label: "IBM WODM", value: "wdsjmhn8", logoPath: "" },
  { label: "Mockups", value: "a3nprlcx", logoPath: "" },
  { label: "Spring MVC", value: "yafflqzl", logoPath: "" },
  { label: "WebSphere", value: "e9mak4jq", logoPath: "" },
  { label: "AES", value: "8tthsexd", logoPath: "" },
  { label: "Elastic Host", value: "tuzgsv61", logoPath: "" },
  { label: "IBM Workload Scheduler", value: "ztl1iig9", logoPath: "" },
  { label: "Model View Viewmodel (MVVM)", value: "cbpcfopb", logoPath: "" },
  { label: "Spring Remoting", value: "rqg1fh1y", logoPath: "" },
  { label: "Websphere Application Server", value: "w3h2hisz", logoPath: "" },
  { label: "Affinity Diagraming", value: "w4ubdjkm", logoPath: "" },
  { label: "Calabash", value: "lmimff5y", logoPath: "" },
  { label: "Elastic Load Balancer", value: "che5irex", logoPath: "" },
  { label: "IBMsource safe", value: "uo9v7a1p", logoPath: "" },
  { label: "Python C API", value: "cc7ee8bp", logoPath: "" },
  { label: "Spring REST", value: "sxorvkdb", logoPath: "" },
  { label: "WebStorm", value: "nzwbtagy", logoPath: "" },
  { label: "AFNetworking", value: "bj5o2glp", logoPath: "" },
  { label: "CanCan", value: "f70s6rmn", logoPath: "" },
  { label: "Elastic Transcoders", value: "ic9yoweu", logoPath: "" },
  { label: "Icinga", value: "ptjucg0n", logoPath: "" },
  { label: "Mongoose", value: "cebek82r", logoPath: "" },
  { label: "Spring Security", value: "8sq41j1o", logoPath: "" },
  { label: "Weka", value: "wf049mjz", logoPath: "" },
  { label: "ag-Grid", value: "jqa2uhxt", logoPath: "" },
  { label: "CanCanCan", value: "pmmm3lkj", logoPath: "" },
  { label: "ElastiCache", value: "wzrahzzb", logoPath: "" },
  { label: "Icoms", value: "8pnrjiaa", logoPath: "" },
  { label: "Monit", value: "vsgd5q4a", logoPath: "" },
  { label: "Pytorch", value: "re5rykcp", logoPath: "" },
  { label: "Spring WebFlux", value: "uyqy0o2x", logoPath: "" },
  { label: "Welkin Suite", value: "6brsnguo", logoPath: "" },
  { label: "Agile Coach", value: "7w5xxvdu", logoPath: "" },
  { label: "Capistrano", value: "xnoqtyrz", logoPath: "" },
  { label: "Iconography", value: "fygqg778", logoPath: "" },
  { label: "Monitoring", value: "e57iy64s", logoPath: "" },
  { label: "QLess", value: "ajzs5bdn", logoPath: "" },
  { label: "SpriteKit", value: "llmrb74i", logoPath: "" },
  { label: "WhatsUpGold", value: "09v0dcvt", logoPath: "" },
  { label: "Agile Methodologies", value: "46uo3xpc", logoPath: "" },
  { label: "Capri", value: "wm7nrrpt", logoPath: "" },
  { label: "ElasticStack", value: "8bo28cqn", logoPath: "" },
  { label: "IDA", value: "gc1nx0w7", logoPath: "" },
  { label: "Monolith", value: "fewns38n", logoPath: "" },
  { label: "QML", value: "xes5mqlo", logoPath: "" },
  { label: "SPSS IBM", value: "bjhwctej", logoPath: "" },
  { label: "Wheels", value: "8hsaqgut", logoPath: "" },
  { label: "Agile Project Management", value: "7w7cqful", logoPath: "" },
  { label: "Capybara", value: "f7leuqou", logoPath: "" },
  { label: "ELB", value: "kojdosgu", logoPath: "" },
  { label: "Ideaboardz", value: "vwgixl3k", logoPath: "" },
  { label: "Moodboards", value: "z5xxjpzz", logoPath: "" },
  { label: "Qt", value: "iaxea2or", logoPath: "" },
  { label: "SQL", value: "p32ueiau", logoPath: "" },
  { label: "Whisper", value: "04uzcu4t", logoPath: "" },
  { label: "Agile SLDC", value: "vbemwsvn", logoPath: "" },
  { label: "Carrierwave", value: "pwosejsw", logoPath: "" },
  { label: "Electric Flow", value: "ewgcbkoi", logoPath: "" },
  { label: "Identity Server 3", value: "ay3sn6xq", logoPath: "" },
  { label: "Moodle", value: "lyfi0lfj", logoPath: "" },
  { label: "Quality Assurance", value: "lohzyf0m", logoPath: "" },
  { label: "SQL Alchemy ORM", value: "2elfdsaf", logoPath: "" },
  { label: "Wicket Web", value: "7zcdofdk", logoPath: "" },
  { label: "Agile software development", value: "y9xg1y2k", logoPath: "" },
  { label: "Carthage", value: "bewulxhc", logoPath: "" },
  { label: "IGListKit", value: "vmnjuczb", logoPath: "" },
  { label: "Mootools", value: "tjziobjn", logoPath: "" },
  { label: "Quality Assurance Testing", value: "4qvsbvst", logoPath: "" },
  { label: "SQL Loader", value: "rj4ymagn", logoPath: "" },
  { label: "Wildfly", value: "oaxgbrli", logoPath: "" },
  { label: "AI Research", value: "y826zgaa", logoPath: "" },
  { label: "CassandraDB", value: "ocxspuma", logoPath: "" },
  { label: "Elementary OS", value: "dibjqamr", logoPath: "" },
  { label: "IIS", value: "wfemjp8w", logoPath: "" },
  { label: "Moq", value: "xae9dugk", logoPath: "" },
  { label: "Quality Control", value: "zyub3oyi", logoPath: "" },
  { label: "SQL Plus", value: "gnofm6aj", logoPath: "" },
  { label: "WinDBG", value: "ylgxf6cm", logoPath: "" },
  { label: "AIR", value: "vhu5ikaj", logoPath: "" },
  { label: "Castle Windsor", value: "oeycfuf2", logoPath: "" },
  { label: "Illustration", value: "fqel3lxm", logoPath: "" },
  { label: "Moya", value: "pnfw1apj", logoPath: "" },
  { label: "Quartz", value: "fqmdpssh", logoPath: "" },
  { label: "SQL Server", value: "0a4cb5n3", logoPath: "" },
  { label: "Windows Console", value: "zzoqvysg", logoPath: "" },
  { label: "Airbrake", value: "vzcpp6kf", logoPath: "" },
  { label: "Castor", value: "ptmuqrxj", logoPath: "" },
  { label: "ELK Stack", value: "qzqycayf", logoPath: "" },
  { label: "Image Processing", value: "rmt9ixb7", logoPath: "" },
  { label: "MQL 4", value: "k3voslzd", logoPath: "" },
  { label: "Quasar", value: "6bpo8lo0", logoPath: "" },
  { label: "SQL Server Functions", value: "0x8el7hg", logoPath: "" },
  {
    label: "Windows Presentation Foundation (WPF)",
    value: "lc8kqxht",
    logoPath: "",
  },
  { label: "Airflow", value: "bfwfom5b", logoPath: "" },
  { label: "cat", value: "2ewknclw", logoPath: "" },
  { label: "Elliptic Curve Cryptography", value: "gmkfwskw", logoPath: "" },
  { label: "iManage", value: "flayrhq2", logoPath: "" },
  { label: "MQL 5", value: "ipljhbb5", logoPath: "" },
  { label: "Query DSL", value: "8wc155mu", logoPath: "" },
  { label: "SQL Server Jobs", value: "zokwyhyp", logoPath: "" },
  { label: "Windows Server", value: "m1rs5kuy", logoPath: "" },
  { label: "Airtable", value: "cvtt03bl", logoPath: "" },
  { label: "CCXT", value: "s9lwzrfs", logoPath: "" },
  { label: "ELM", value: "tcrqofay", logoPath: "" },
  { label: "Immutable.js", value: "7shsjxcc", logoPath: "" },
  { label: "MQTT", value: "8jfp1bmb", logoPath: "" },
  { label: "Quickbooks", value: "6cjib9x4", logoPath: "" },
  { label: "SQL Server Procedures", value: "f41ckjxu", logoPath: "" },
  { label: "Windows Services", value: "cprxz3gv", logoPath: "" },
  { label: "AJAX", value: "08xwm6dt", logoPath: "" },
  { label: "Celery", value: "ab0h1buh", logoPath: "" },
  { label: "Eloquent ORM", value: "zwj5bt91", logoPath: "" },
  { label: "Impala", value: "lypjz9rj", logoPath: "" },
  { label: "MS Biztalk", value: "aroyz13h", logoPath: "" },
  { label: "Quicksight", value: "8bpelsq5", logoPath: "" },
  { label: "SQLite", value: "irvbhjie", logoPath: "" },
  { label: "Windows Workflow Foundation", value: "0onnjffd", logoPath: "" },
  { label: "Akka", value: "ottc27pe", logoPath: "" },
  { label: "Celluloid", value: "gsi2ddww", logoPath: "" },
  { label: "Email designs", value: "4nteashn", logoPath: "" },
  { label: "Imperva Incapsula CDN", value: "tkv4972z", logoPath: "" },
  { label: "MS SSIS", value: "ul55u9zv", logoPath: "" },
  { label: "R", value: "1y56ao7n", logoPath: "" },
  { label: "Squid", value: "aoetkeim", logoPath: "" },
  { label: "Wine", value: "edvkmi9z", logoPath: "" },
  { label: "Akka Actors", value: "bjx5douk", logoPath: "" },
  { label: "CentOS Server", value: "4xhpnba1", logoPath: "" },
  { label: "Embedded", value: "xrf6hiwr", logoPath: "" },
  { label: "IMS", value: "wvcppsw3", logoPath: "" },
  { label: "MS Test", value: "ms3pa5gs", logoPath: "" },
  { label: "SRE", value: "2x3igovg", logoPath: "" },
  { label: "WinForms", value: "lwcnqdh1", logoPath: "" },
  { label: "Akka HTTP", value: "awpvfig4", logoPath: "" },
  { label: "Cesium", value: "ebt2kapz", logoPath: "" },
  { label: "Embedded C", value: "p8irvukt", logoPath: "" },
  { label: "Incident Management", value: "up95ceqp", logoPath: "" },
  { label: "MS TFS", value: "sxbfttv5", logoPath: "" },
  { label: "Rabl", value: "7ajizs1u", logoPath: "" },
  { label: "SSAS", value: "ivzghnyj", logoPath: "" },
  { label: "WinRM", value: "jtmzv8mr", logoPath: "" },
  { label: "Akka Streams", value: "4gbggtuw", logoPath: "" },
  { label: "Cffi", value: "bkhvna9r", logoPath: "" },
  { label: "InfluxDB", value: "kcl2ere7", logoPath: "" },
  { label: "MS Windows Data Center 2012 R2", value: "qnup9t24", logoPath: "" },
  { label: "Rack Space", value: "eu5it9ij", logoPath: "" },
  { label: "SSL", value: "7yxqeycn", logoPath: "" },
  { label: "Wirecast", value: "bclpcsgr", logoPath: "" },
  { label: "Akka.Net", value: "bwdpj41b", logoPath: "" },
  { label: "CFML", value: "lens7ykv", logoPath: "" },
  { label: "EMR", value: "izlumldk", logoPath: "" },
  { label: "Information Design", value: "o6zz3sbw", logoPath: "" },
  { label: "MSMQ", value: "ngq6vbma", logoPath: "" },
  { label: "Rack Space Cloud", value: "hngwd932", logoPath: "" },
  { label: "SSO", value: "ffuspg2j", logoPath: "" },
  { label: "Wireframing", value: "nrroyegp", logoPath: "" },
  { label: "Alamofire", value: "dqy0xeqb", logoPath: "" },
  { label: "CGO", value: "gugk9tyc", logoPath: "" },
  { label: "Encryption", value: "iihyxyv1", logoPath: "" },
  { label: "Informix", value: "becrgt4e", logoPath: "" },
  { label: "MSSQL", value: "kwka7rae", logoPath: "" },
  { label: "SSRS", value: "xscac1ec", logoPath: "" },
  { label: "Wireshark", value: "ogbpzgo4", logoPath: "" },
  { label: "Alexa", value: "zyhtoogi", logoPath: "" },
  { label: "Chai", value: "lnvq1ddl", logoPath: "" },
  { label: "Enterprise Architect", value: "okq97irn", logoPath: "" },
  { label: "Infragistics", value: "idqtheve", logoPath: "" },
  { label: "Mule ESB", value: "locmldli", logoPath: "" },
  { label: "Star Team", value: "vkkp9swe", logoPath: "" },
  { label: "WooCommerce", value: "yu2davrv", logoPath: "" },
  { label: "Algolia", value: "vrnvpm7p", logoPath: "" },
  { label: "Change Management", value: "zjguvoon", logoPath: "" },
  { label: "Enterprise Dynamic 4D script", value: "7omm5p15", logoPath: "" },
  { label: "Inkscape", value: "iozi1ucj", logoPath: "" },
  { label: "Mustache", value: "fgcfwyqy", logoPath: "" },
  { label: "Rake", value: "vhzeqrlh", logoPath: "" },
  { label: "Starling", value: "fxoyknmb", logoPath: "" },
  { label: "Algorithm Development", value: "rv5yardv", logoPath: "" },
  { label: "Charts", value: "x9ucpn0b", logoPath: "" },
  { label: "Enterprise Java Beans", value: "ybcgaom2", logoPath: "" },
  { label: "Inspector", value: "720gmxq8", logoPath: "" },
  { label: "MVC", value: "qo2rtyba", logoPath: "" },
  { label: "Rally", value: "tp2pvrp7", logoPath: "" },
  { label: "Stash", value: "1azmkfaa", logoPath: "" },
  { label: "Wordpress Multisite", value: "6wvl144d", logoPath: "" },
  { label: "Algorithms", value: "orjbgbzs", logoPath: "" },
  { label: "Check Point", value: "oy7ksea5", logoPath: "" },
  { label: "Enterprise JavaBeans (EJB)", value: "sfu2wpk8", logoPath: "" },
  { label: "Instabug", value: "tjozkb7e", logoPath: "" },
  { label: "MVP", value: "0ly7wiwl", logoPath: "" },
  { label: "Ramda JS", value: "zxype5sp", logoPath: "" },
  { label: "Stats Models", value: "zfx6d6ta", logoPath: "" },
  { label: "Work Ethic", value: "tihnaxck", logoPath: "" },
  { label: "Amadeus", value: "tkthxxxt", logoPath: "" },
  { label: "CheckMk", value: "jrilg6og", logoPath: "" },
  { label: "Entity Framework", value: "609fgz5c", logoPath: "" },
  { label: "Instagram API", value: "nw7jbibv", logoPath: "" },
  { label: "MVVM Cross", value: "dc6e0usm", logoPath: "" },
  { label: "Rancher", value: "rqsw7vvr", logoPath: "" },
  { label: "Stimulus.js", value: "uehxotun", logoPath: "" },
  { label: "Workspaces", value: "zlgbwpos", logoPath: "" },
  { label: "Chef", value: "wk4efnul", logoPath: "" },
  { label: "Envivio", value: "nicy9bdf", logoPath: "" },
  { label: "Intellij IDEA", value: "qaahfusj", logoPath: "" },
  { label: "MVVM-C", value: "kpecvr0p", logoPath: "" },
  { label: "Rasa (Chatbots)", value: "sfok4r4s", logoPath: "" },
  { label: "Stored Procedures", value: "qovpwll3", logoPath: "" },
  { label: "Wowza", value: "f5z4ck31", logoPath: "" },
  { label: "CherryPy", value: "1m5e6yda", logoPath: "" },
  { label: "Envoyer", value: "2bikdoql", logoPath: "" },
  { label: "Interaction Design", value: "kbahdv1n", logoPath: "" },
  { label: "MyBatis", value: "iekipkl2", logoPath: "" },
  { label: "Rasa Core", value: "0xboy8jw", logoPath: "" },
  { label: "StoreKit", value: "2gghwmj7", logoPath: "" },
  { label: "WPF", value: "rvrxp29r", logoPath: "" },
  { label: "Chrome Extension", value: "5zlyw9xe", logoPath: "" },
  { label: "Enzyme", value: "8ld5ahv2", logoPath: "" },
  { label: "Interface Builder", value: "vc2iliqw", logoPath: "" },
  { label: "Ration Team Concert (RTC)", value: "sd81ymuz", logoPath: "" },
  { label: "Storyboarding", value: "hyt6l7hb", logoPath: "" },
  { label: "WSDL", value: "q4ydnnc4", logoPath: "" },
  { label: "Chromecast", value: "yjodzbbt", logoPath: "" },
  { label: "EOS", value: "a2iw9csr", logoPath: "" },
  { label: "Intergraph G/Technology", value: "nl1uthze", logoPath: "" },
  { label: "MySQL Workbench", value: "kjppc6g7", logoPath: "" },
  { label: "Raw Sockets", value: "te4bobki", logoPath: "" },
  { label: "Storyboards", value: "ounnhajt", logoPath: "" },
  { label: "X.509", value: "yjwq4qa1", logoPath: "" },
  { label: "Cinema 4D", value: "clwukjmh", logoPath: "" },
  { label: "Episerver", value: "oyksot3l", logoPath: "" },
  { label: "inVision", value: "hesg9bg6", logoPath: "" },
  { label: "n", value: "mizxcpxf", logoPath: "" },
  { label: "Razor", value: "z2eg5plz", logoPath: "" },
  { label: "Strategic Design", value: "kimurj7h", logoPath: "" },
  { label: "X11", value: "s5xcl6jx", logoPath: "" },
  { label: "Circle CI", value: "a4xn3wka", logoPath: "" },
  { label: "ERD", value: "platkwuw", logoPath: "" },
  { label: "Nagios", value: "1kcja8m6", logoPath: "" },
  { label: "RCP (SWT)", value: "pmliadza", logoPath: "" },
  { label: "Strategic Planning", value: "lg19oi44", logoPath: "" },
  { label: "Xamarin", value: "vbpx1bzq", logoPath: "" },
  { label: "Cisco", value: "kkivy82u", logoPath: "" },
  { label: "ERP", value: "ubezjimf", logoPath: "" },
  { label: "iOS", value: "kuceqhad", logoPath: "" },
  { label: "Native Mobile Development", value: "rjwwrfto", logoPath: "" },
  { label: "RDBMS", value: "7msmvndt", logoPath: "" },
  { label: "Strategy & Innovation", value: "o0ovxpyn", logoPath: "" },
  { label: "Xamarin Forms", value: "9qdqh6fq", logoPath: "" },
  { label: "AMQP", value: "un5hh1di", logoPath: "" },
  { label: "Cisco OS", value: "dguqot8z", logoPath: "" },
  { label: "ES5", value: "5oolu3fr", logoPath: "" },
  { label: "iOS API", value: "erewmcgv", logoPath: "" },
  {
    label: "Native/Hybrid Mobile Development",
    value: "t6ytuahm",
    logoPath: "",
  },
  { label: "RDS", value: "lqe2zisi", logoPath: "" },
  { label: "Stripe", value: "4oasfv6b", logoPath: "" },
  { label: "Xamarin Native", value: "94ploaw7", logoPath: "" },
  { label: "AMR 5", value: "ahyr35fh", logoPath: "" },
  { label: "Cisco Routers", value: "vrjoylcc", logoPath: "" },
  { label: "ES6", value: "dmb9d9lu", logoPath: "" },
  { label: "iOS Design", value: "5vcmb6io", logoPath: "" },
  { label: "Nativescript", value: "bekqhvxw", logoPath: "" },
  { label: "StripeJS", value: "kiwzezgf", logoPath: "" },
  { label: "XAML", value: "y8xqu8du", logoPath: "" },
  { label: "Analytical Pipelines", value: "n0rgkm4i", logoPath: "" },
  { label: "Cisco Switches", value: "6cgos6re", logoPath: "" },
  { label: "ES7", value: "2pgahdyr", logoPath: "" },
  { label: "iOS SDK", value: "7xppjal7", logoPath: "" },
  { label: "NATS", value: "exkry9s9", logoPath: "" },
  { label: "Struts", value: "wwumeooi", logoPath: "" },
  { label: "XBehave", value: "sj4k3ylz", logoPath: "" },
  { label: "Analytics", value: "9vhybszw", logoPath: "" },
  { label: "Cisco Webex", value: "blc335kr", logoPath: "" },
  { label: "eSender", value: "pob01hpk", logoPath: "" },
  { label: "iOS UI", value: "jkay0t5j", logoPath: "" },
  { label: "NATS/NATS Streaming", value: "naqenxjt", logoPath: "" },
  { label: "Styled System", value: "pbbdvhqo", logoPath: "" },
  { label: "Xcode", value: "84mte7in", logoPath: "" },
  { label: "Citrix Hypervisor", value: "m2ol6g5w", logoPath: "" },
  { label: "Espresso", value: "nefbsvmk", logoPath: "" },
  { label: "iOS UX", value: "2znovfw9", logoPath: "" },
  { label: "Natural language processing", value: "lsv8bitm", logoPath: "" },
  { label: "Reaction", value: "d31ssmqa", logoPath: "" },
  { label: "Sublime Text", value: "osy0g5id", logoPath: "" },
  { label: "XCTest", value: "bx380ror", logoPath: "" },
  { label: "Android Architecture", value: "nfdo5q2x", logoPath: "" },
  { label: "Classification", value: "vtzhelv7", logoPath: "" },
  { label: "eSQL", value: "djx6iung", logoPath: "" },
  { label: "IoT Green Grass", value: "fnce6kra", logoPath: "" },
  { label: "Negotiation", value: "4pv7cujn", logoPath: "" },
  { label: "Reactive Cocoa", value: "kwz6poqz", logoPath: "" },
  { label: "Subversion", value: "sfgx2gvg", logoPath: "" },
  { label: "XFire", value: "hwubl4wy", logoPath: "" },
  { label: "Android Jetpack", value: "labbcsu4", logoPath: "" },
  { label: "Clean Architecture", value: "yltwfxo2", logoPath: "" },
  { label: "eSXI", value: "jlwqtp1b", logoPath: "" },
  { label: "IOTA", value: "byt6wmfv", logoPath: "" },
  { label: "Neo4j", value: "l6hfnqes", logoPath: "" },
  { label: "Reactive Programming", value: "6rb5fve1", logoPath: "" },
  { label: "Sumo-logic", value: "ljskvunp", logoPath: "" },
  { label: "XHTML", value: "iiyem03b", logoPath: "" },
  { label: "Android NDK", value: "a0jtt6pl", logoPath: "" },
  { label: "Clean Code", value: "5kq2rzkx", logoPath: "" },
  { label: "ethereum", value: "jdcei5j8", logoPath: "" },
  { label: "Iptables", value: "4nzughqa", logoPath: "" },
  { label: "Nest.js", value: "j4u2qyqg", logoPath: "" },
  { label: "Reactive Swift", value: "tiimhdvr", logoPath: "" },
  { label: "Sun Solaris", value: "ll0yjyyn", logoPath: "" },
  { label: "xirsys", value: "rgzp2yml", logoPath: "" },
  { label: "Android SDK", value: "mobdfzd5", logoPath: "" },
  { label: "ClearCase", value: "wsi4tucp", logoPath: "" },
  { label: "ETL", value: "gzzuyd9n", logoPath: "" },
  { label: "IPTV", value: "olnmocfp", logoPath: "" },
  { label: "NetBackup", value: "ul3kggps", logoPath: "" },
  { label: "Reactor", value: "hcaonwxp", logoPath: "" },
  { label: "Supervisor", value: "2zah4s19", logoPath: "" },
  { label: "XL Deploy", value: "0ftxpnvn", logoPath: "" },
  { label: "Android Studio", value: "hb6a75cs", logoPath: "" },
  { label: "CLI.NET", value: "a9yz5j0x", logoPath: "" },
  { label: "Eucalyptus", value: "8n0k6vac", logoPath: "" },
  { label: "IPv6", value: "e59zv62p", logoPath: "" },
  { label: "NetBeans", value: "vqa8prrt", logoPath: "" },
  { label: "Reality Server", value: "nlofgplx", logoPath: "" },
  { label: "Supervisord", value: "0hrh2gll", logoPath: "" },
  { label: "XML", value: "pjcsctxw", logoPath: "" },
  { label: "", value: "pm2w7tzx", logoPath: "" },
  { label: "Clickhouse", value: "l7329nqk", logoPath: "" },
  { label: "Eureka", value: "jeegffgw", logoPath: "" },
  { label: "iRedMail", value: "amvnn1u8", logoPath: "" },
  { label: "NetBeans RCP", value: "jrdpor1q", logoPath: "" },
  { label: "Realm", value: "uzl8e9xg", logoPath: "" },
  { label: "SVN", value: "v0b6zzas", logoPath: "" },
  { label: "XNA", value: "f3cueyjp", logoPath: "" },
  { label: "Client.JS", value: "ha1oq9ro", logoPath: "" },
  { label: "Event Driven Architecture", value: "nto1rkpv", logoPath: "" },
  { label: "Ireports", value: "krljyci8", logoPath: "" },
  { label: "Netflix OSS", value: "jngaidyc", logoPath: "" },
  { label: "Realm Database", value: "utn6zkg4", logoPath: "" },
  { label: "Swagger", value: "rfx3rn2e", logoPath: "" },
  { label: "XP", value: "y6nisvbj", logoPath: "" },
  { label: "ClientVPN", value: "ekmpdhyy", logoPath: "" },
  { label: "Event Sourcing", value: "bobk1ldn", logoPath: "" },
  { label: "iSeries", value: "ijcutexm", logoPath: "" },
  { label: "NetSuite ERP", value: "9xpbg4ba", logoPath: "" },
  { label: "Realtime Events", value: "6eghlq4d", logoPath: "" },
  { label: "XSD", value: "42dfamst", logoPath: "" },
  { label: "Clipper", value: "kvts5e2a", logoPath: "" },
  { label: "Event-Driven", value: "w11zfj7k", logoPath: "" },
  { label: "IVR", value: "18wbqsqe", logoPath: "" },
  { label: "Netty", value: "dd9hewwu", logoPath: "" },
  { label: "ReasonML", value: "ktauydee", logoPath: "" },
  { label: "SwiftLint", value: "emyyxpmo", logoPath: "" },
  { label: "XSL", value: "xqljq5af", logoPath: "" },
  { label: "Clojure", value: "vdjggzp3", logoPath: "" },
  { label: "Exadata", value: "lduoelzw", logoPath: "" },
  { label: "Ivy", value: "ztqw668s", logoPath: "" },
  { label: "Network Design & Configuration", value: "onmylhjo", logoPath: "" },
  { label: "Recognition Server (OCR)", value: "e5n0ezac", logoPath: "" },
  { label: "SwiftUI", value: "7iqrqiw8", logoPath: "" },
  { label: "XSLT", value: "jgqsyk3j", logoPath: "" },
  { label: "Cloud Computing", value: "pwqneznp", logoPath: "" },
  { label: "Excel VBA", value: "pvzvmetc", logoPath: "" },
  { label: "J2EE", value: "km2evia8", logoPath: "" },
  { label: "Network Stack", value: "trsilqva", logoPath: "" },
  { label: "Recurly", value: "yt36nqof", logoPath: "" },
  { label: "SWIG", value: "lszx18aw", logoPath: "" },
  { label: "XSplit", value: "1v7ay6cm", logoPath: "" },
  { label: "Cloud Functions", value: "5zdlfqu8", logoPath: "" },
  { label: "Expect", value: "h6sing30", logoPath: "" },
  { label: "J2J Framework", value: "v9gawahi", logoPath: "" },
  { label: "Networking", value: "atuuhq7e", logoPath: "" },
  { label: "Redhat", value: "vz4otcwb", logoPath: "" },
  { label: "Swing", value: "yubfsfd1", logoPath: "" },
  { label: "XStream", value: "felxzoag", logoPath: "" },
  { label: "Cloud-native", value: "ps3fijao", logoPath: "" },
  { label: "J2ME", value: "lcs5orap", logoPath: "" },
  { label: "Networking Fundamentals", value: "3ujecsmw", logoPath: "" },
  { label: "Sybase Power Designer", value: "jytlwr0g", logoPath: "" },
  { label: "XUnit", value: "ussffjx4", logoPath: "" },
  { label: "CloudBees", value: "l2luuzvx", logoPath: "" },
  { label: "Ext JS", value: "qweee6ab", logoPath: "" },
  { label: "J2SE", value: "dloix3hu", logoPath: "" },
  { label: "Neural Networks", value: "uyiq0vw6", logoPath: "" },
  { label: "Symantec", value: "zobqztm7", logoPath: "" },
  { label: "Yarn", value: "cucfahbd", logoPath: "" },
  { label: "Cloudera", value: "m6hwx4qs", logoPath: "" },
  { label: "Ext.JS", value: "tezsr5hw", logoPath: "" },
  { label: "JADE", value: "yt9hvd8m", logoPath: "" },
  { label: "New Relic", value: "b4ocmr4g", logoPath: "" },
  { label: "Redmine", value: "m5w7ikbb", logoPath: "" },
  { label: "Yee", value: "g8nfndvx", logoPath: "" },
  { label: "Angular CLI", value: "snzbizbu", logoPath: "" },
  { label: "CloudFlare", value: "oiurxrst", logoPath: "" },
  { label: "F#", value: "xryhnetg", logoPath: "" },
  { label: "Jasig CAS", value: "h4nszus2", logoPath: "" },
  { label: "Next.js", value: "pvmavacs", logoPath: "" },
  { label: "Redshift", value: "mauzyyds", logoPath: "" },
  { label: "Yeoman", value: "18jnv7e9", logoPath: "" },
  { label: "Angular Material", value: "boqdnn7f", logoPath: "" },
  { label: "CloudFront", value: "thfwguzr", logoPath: "" },
  { label: "F5", value: "r3zefkly", logoPath: "" },
  { label: "Nexus", value: "fabpxadr", logoPath: "" },
  { label: "Sympli", value: "in37cr5r", logoPath: "" },
  { label: "Yii", value: "ksztbrwu", logoPath: "" },
  { label: "Angular Universal", value: "p2gmrnto", logoPath: "" },
  { label: "Cloudinary", value: "a0uigigh", logoPath: "" },
  { label: "Fabric", value: "3fhwiye8", logoPath: "" },
  { label: "Jasper ETL", value: "rs8oqsde", logoPath: "" },
  { label: "NFC", value: "yhhayzvh", logoPath: "" },
  { label: "SysOps", value: "udymn5nw", logoPath: "" },
  { label: "Yii2", value: "yuzejf06", logoPath: "" },
  { label: "CloudSearch", value: "6ubtzwhx", logoPath: "" },
  { label: "Facebook Ads", value: "8xie1wsc", logoPath: "" },
  { label: "Jasper Report Server", value: "7nbdd4fz", logoPath: "" },
  { label: "System Administration", value: "jynv5pqp", logoPath: "" },
  { label: "Yocto", value: "qr9c3wf0", logoPath: "" },
  { label: "Animation", value: "707dlthz", logoPath: "" },
  { label: "CloudTrail", value: "sniv4xpe", logoPath: "" },
  { label: "Facebook API", value: "re714xpl", logoPath: "" },
  { label: "Jasper Reports", value: "ckwh73vd", logoPath: "" },
  { label: "NgRx", value: "0dkl0z4r", logoPath: "" },
  { label: "System Architecture", value: "jeebl85q", logoPath: "" },
  { label: "Youtube API", value: "5vqhec9q", logoPath: "" },
  { label: "ANSI SQL", value: "ave2ucwm", logoPath: "" },
  { label: "CloudWatch", value: "nvce6ik7", logoPath: "" },
  { label: "Facebook Messenger", value: "65u4pexq", logoPath: "" },
  { label: "NHibernate", value: "qnnbby1i", logoPath: "" },
  { label: "Regex", value: "wc0iheal", logoPath: "" },
  { label: "System Design", value: "pohhh6pf", logoPath: "" },
  { label: "Zabbix", value: "cmovp7d1", logoPath: "" },
  { label: "Ansible", value: "bssjbq3l", logoPath: "" },
  { label: "Cluster Control", value: "njs6siab", logoPath: "" },
  { label: "Facebook Pages", value: "zkk4lioe", logoPath: "" },
  { label: "NightmareJS", value: "gqz75rwp", logoPath: "" },
  { label: "Release Management", value: "sksg3qkt", logoPath: "" },
  { label: "System Manager", value: "otdfps6y", logoPath: "" },
  { label: "Zapier", value: "ar5jkobg", logoPath: "" },
  { label: "ANSIC", value: "pfwycyk8", logoPath: "" },
  { label: "CMDB", value: "hjzqbv6t", logoPath: "" },
  { label: "Facebook SDK", value: "qcmd1mvs", logoPath: "" },
  { label: "Nightwatch", value: "2bfdyfss", logoPath: "" },
  { label: "Report Builder", value: "jlol5rds", logoPath: "" },
  { label: "Sysvipc", value: "41umnk6z", logoPath: "" },
  { label: "Zend Framework", value: "k6dyqxtk", logoPath: "" },
  { label: "Ant Design", value: "aiqpeex8", logoPath: "" },
  { label: "CMS", value: "y69a7yqm", logoPath: "" },
  { label: "Facilitation", value: "j8u7tzyh", logoPath: "" },
  { label: "NLP", value: "mwaikfzj", logoPath: "" },
  { label: "Reporting Services", value: "ochqb9mo", logoPath: "" },
  { label: "T-SQL", value: "cjvziyzp", logoPath: "" },
  { label: "Zeplin", value: "k3vakqwa", logoPath: "" },
  { label: "AOP", value: "mr07i44v", logoPath: "" },
  { label: "Coaching", value: "gn9xzn3m", logoPath: "" },
  { label: "Fake", value: "zw7p1j1j", logoPath: "" },
  { label: "NLTK", value: "kivthnae", logoPath: "" },
  { label: "Require.JS", value: "kzzdg1nl", logoPath: "" },
  { label: "TA-Lib", value: "qrrn4mn8", logoPath: "" },
  { label: "ZK Framework", value: "u9ilza9w", logoPath: "" },
  { label: "Apache", value: "gsixhfgc", logoPath: "" },
  { label: "Cocoa", value: "qqdrf2vm", logoPath: "" },
  { label: "Falcon", value: "3iqe0k3y", logoPath: "" },
  { label: "NOC", value: "zypkxdra", logoPath: "" },
  { label: "ResearchKit", value: "1ezp7md9", logoPath: "" },
  { label: "Table Plus", value: "itglnitk", logoPath: "" },
  { label: "Zoom", value: "tops3skk", logoPath: "" },
  { label: "Apache Ant", value: "56tvll7m", logoPath: "" },
  { label: "Cocoa Touch", value: "mbrofmzl", logoPath: "" },
  { label: "Faraday API", value: "h1kablpf", logoPath: "" },
  { label: "Reselect", value: "kla4nfig", logoPath: "" },
  { label: "Tableau", value: "nmtcknel", logoPath: "" },
  { label: "Zope", value: "crwmxevq", logoPath: "" },
  { label: "Apache Benchmark", value: "kc5j84gx", logoPath: "" },
  { label: "CocoaPods", value: "zrv2f4nr", logoPath: "" },
  { label: "Fargate", value: "ciwbsmtd", logoPath: "" },
  { label: "Java Swing", value: "kdsekcrq", logoPath: "" },
  { label: "Nokogiri", value: "m3fabwlm", logoPath: "" },
  { label: "Responsive Design", value: "pkvihh5i", logoPath: "" },
  { label: "Tachyons", value: "sdj6zdfm", logoPath: "" },
  { label: "Zurb Foundation", value: "7aoiwzcw", logoPath: "" },
  { label: "Apache Camel", value: "suyameyd", logoPath: "" },
  { label: "Cocos2d", value: "gx2xrqcp", logoPath: "" },
  { label: "Fastlane", value: "phtpvx8b", logoPath: "" },
  { label: "Java Web Token", value: "myp2ygzf", logoPath: "" },
  { label: "NopCommerce", value: "vkcvwduh", logoPath: "" },
  { label: "Responsive UI Design Bootstrap", value: "lzy38bzv", logoPath: "" },
  { label: "Tailwind CSS", value: "362ywm6z", logoPath: "" },
  { label: "Apache Cassandra", value: "cch5fvt6", logoPath: "" },
  { label: "Code Collaborator", value: "9al7zptc", logoPath: "" },
  { label: "Fault Tolerance", value: "azu1tbjq", logoPath: "" },
  { label: "JavaFx", value: "jpfn1hyi", logoPath: "" },
  { label: "noSQL", value: "u48flwdx", logoPath: "" },
  { label: "Responsive Web Design", value: "7ufn1aif", logoPath: "" },
  { label: "Talend", value: "eqloetjh", logoPath: "" },
  { label: "Apache Felix", value: "ougi5y4z", logoPath: "" },
  { label: "Code Deploy", value: "sfjy6vad", logoPath: "" },
  { label: "Faust", value: "ug3jrbke", logoPath: "" },
  { label: "npm", value: "xoxgrh1t", logoPath: "" },
  { label: "Resque", value: "9lwht5qo", logoPath: "" },
  { label: "Tango", value: "1s41ynfm", logoPath: "" },
  { label: "Apache Flink", value: "4sppdrzh", logoPath: "" },
  { label: "Codeception", value: "bikzsp7x", logoPath: "" },
  { label: "Faye", value: "wv0auf2r", logoPath: "" },
  { label: "JavaServer Faces (JSF)", value: "hf5v7q4t", logoPath: "" },
  { label: "NService Bus", value: "gxlg5psd", logoPath: "" },
  { label: "Rest API", value: "smpw1ps8", logoPath: "" },
  { label: "Tape", value: "dzk8e80p", logoPath: "" },
  { label: "Apache HTTP Server", value: "bheypikl", logoPath: "" },
  { label: "CodeFirst", value: "lmwws6k4", logoPath: "" },
  { label: "FeatherJS", value: "tdhbdpzw", logoPath: "" },
  { label: "JavaServer Pages (JSP)", value: "l3ydikia", logoPath: "" },
  { label: "NSQ", value: "8kgqelcm", logoPath: "" },
  { label: "REST WS", value: "x1zcpdkg", logoPath: "" },
  { label: "Team City", value: "s6fkb2ub", logoPath: "" },
  { label: "Apache Jackrabbit", value: "a3ez2c20", logoPath: "" },
  { label: "CodeIgniter Framework", value: "xdifogqc", logoPath: "" },
  { label: "FFMPEG", value: "yneuk0aj", logoPath: "" },
  { label: "JAX-RS", value: "kaonjgn7", logoPath: "" },
  { label: "NSURL Connection", value: "aer8erxd", logoPath: "" },
  { label: "REST-Kit", value: "t9hvzdjq", logoPath: "" },
  { label: "Team Management", value: "dmi7p8qv", logoPath: "" },
  { label: "Apache Maven", value: "rdzcovco", logoPath: "" },
  { label: "CodePush", value: "rj4lkkxp", logoPath: "" },
  { label: "FFTW", value: "fcv84kod", logoPath: "" },
  { label: "JAX-WS", value: "26dv4qoh", logoPath: "" },
  { label: "NTS Admin", value: "3ycuthxt", logoPath: "" },
  { label: "RestAssured", value: "6o0u9a3z", logoPath: "" },
  { label: "Team Server", value: "iva7qyvj", logoPath: "" },
  { label: "Apache Mesos", value: "ybhu5nvx", logoPath: "" },
  { label: "Coding Challenge", value: "x1o7stfg", logoPath: "" },
  { label: "Fibre Optic Works", value: "lxsqzrbl", logoPath: "" },
  { label: "JaxB", value: "y4u6ftvh", logoPath: "" },
  { label: "Nuget", value: "avdglvgn", logoPath: "" },
  { label: "RESTFul Web Security", value: "1tddhvzx", logoPath: "" },
  { label: "TeamCity", value: "ivhslx28", logoPath: "" },
  { label: "Apache Mina", value: "fresospo", logoPath: "" },
  { label: "Fiddler", value: "fld8w7d0", logoPath: "" },
  { label: "jBMP", value: "mczwgnw4", logoPath: "" },
  { label: "NugGet", value: "qkyqn7ov", logoPath: "" },
  { label: "RESTful Web Services", value: "otcedbkr", logoPath: "" },
  { label: "TeamCity CI Server", value: "foztmxva", logoPath: "" },
  { label: "Apache Pig", value: "ibxv8bo3", logoPath: "" },
  { label: "Coldfusion", value: "hpqnvghy", logoPath: "" },
  { label: "Figma", value: "rlaenfkx", logoPath: "" },
  { label: "JBoss", value: "n48l2mra", logoPath: "" },
  { label: "Nuke", value: "odbdsgyj", logoPath: "" },
  { label: "Restify", value: "bwt6dkkn", logoPath: "" },
  { label: "Teamwork", value: "yhqguqjn", logoPath: "" },
  { label: "Apache Storm", value: "2trmcobv", logoPath: "" },
  { label: "Collage", value: "ghlekxrb", logoPath: "" },
  { label: "Finance", value: "uwu8qpiq", logoPath: "" },
  { label: "JBoss EAP", value: "foonthu5", logoPath: "" },
  { label: "Numpy", value: "gwoljjxs", logoPath: "" },
  { label: "RetailPro", value: "qfhnyrro", logoPath: "" },
  { label: "Technical Writing", value: "ofrnzlkg", logoPath: "" },
  { label: "Apache Struts", value: "kndjwkgg", logoPath: "" },
  { label: "collectd", value: "mpgmqk15", logoPath: "" },
  { label: "Finance Systems", value: "nlovtkbf", logoPath: "" },
  { label: "JBoss RESTEasy", value: "hgrbkht9", logoPath: "" },
  { label: "NUnit", value: "oojvvfbb", logoPath: "" },
  { label: "RethinkDB", value: "9ao7lvoz", logoPath: "" },
  { label: "Telecom Systems", value: "yzn0x5ai", logoPath: "" },
  { label: "Apache Velocity", value: "d4cvcn6d", logoPath: "" },
  { label: "COM Server", value: "x8ghgcuy", logoPath: "" },
  {
    label: "Financial controlling & Reporting",
    value: "kp2quoaz",
    logoPath: "",
  },
  { label: "JDBC", value: "dq6gyf9w", logoPath: "" },
  { label: "Nuxt.js", value: "tzib77uv", logoPath: "" },
  { label: "Retrofit", value: "adjlgczs", logoPath: "" },
  {
    label: "Telecommunication Billing System (Corniva)",
    value: "kgeyyole",
    logoPath: "",
  },
  { label: "Apache Wickets", value: "765zn1ye", logoPath: "" },
  { label: "Combine", value: "aleng3ax", logoPath: "" },
  { label: "FinTech", value: "dethwuqx", logoPath: "" },
  { label: "JEE", value: "9atrierv", logoPath: "" },
  { label: "NVD3JS", value: "jr8d7u0j", logoPath: "" },
  { label: "Retrofit2", value: "7rclscwp", logoPath: "" },
  { label: "Telegraf", value: "ro7moci1", logoPath: "" },
  { label: "Apache XML", value: "4gcdxo8d", logoPath: "" },
  { label: "Communication skills", value: "d6ksgxtv", logoPath: "" },
  { label: "NVelocity", value: "ha30ggzl", logoPath: "" },
  { label: "Retrofits2", value: "oqrl05vt", logoPath: "" },
  { label: "Telerik", value: "4s5ejxnl", logoPath: "" },
  { label: "APEX", value: "kwx0oxit", logoPath: "" },
  { label: "Compass", value: "kpjjdqlg", logoPath: "" },
  { label: "Jersey", value: "l2pqw2i1", logoPath: "" },
  { label: "Nvidia Library", value: "dth19mp0", logoPath: "" },
  { label: "RFID", value: "3belyhv6", logoPath: "" },
  { label: "Tenable Security Center", value: "r7vw10ri", logoPath: "" },
  { label: "API Design", value: "gaq2n2we", logoPath: "" },
  { label: "Competitive Analysis", value: "7x9sy90t", logoPath: "" },
  { label: "Firebird", value: "flvebyo3", logoPath: "" },
  { label: "Jest", value: "piqafndz", logoPath: "" },
  { label: "NWJS", value: "5fbux4bb", logoPath: "" },
  { label: "RichFaces", value: "d6gcc8qv", logoPath: "" },
  { label: "Tensorflow", value: "ptgvanjt", logoPath: "" },
  { label: "API Gateways", value: "ik9kar1s", logoPath: "" },
  { label: "Complete Visual Identity", value: "wt7onqcm", logoPath: "" },
  { label: "Firebug", value: "dcmxbcgd", logoPath: "" },
  { label: "Jet-Brains", value: "vmzlulf8", logoPath: "" },
  { label: "OAS (Oracle Application Server)", value: "avwslksf", logoPath: "" },
  { label: "Rider", value: "kyrxfhso", logoPath: "" },
  { label: "Terradata", value: "zvmdvtsg", logoPath: "" },
  { label: "APIGee", value: "dgcsaggh", logoPath: "" },
  { label: "Composer", value: "qcs2b6zz", logoPath: "" },
  { label: "Firewall", value: "u1zdw4rq", logoPath: "" },
  { label: "Jetty", value: "8zsjh25p", logoPath: "" },
  { label: "OAuth", value: "mj8pgg2w", logoPath: "" },
  { label: "Riot.JS", value: "p7ktofkc", logoPath: "" },
  { label: "Terraform", value: "tut3oyp4", logoPath: "" },
  { label: "APM", value: "nt41rrjt", logoPath: "" },
  { label: "Compositing", value: "gi5mr8ga", logoPath: "" },
  { label: "FIX", value: "wsvbcynj", logoPath: "" },
  { label: "JIRA", value: "exilai4o", logoPath: "" },
  { label: "OAuth2", value: "3axn5fow", logoPath: "" },
  { label: "Risk Management", value: "jmffuuvb", logoPath: "" },
  { label: "Test driven development", value: "zubo3u6s", logoPath: "" },
  { label: "APN", value: "jfyrzzsi", logoPath: "" },
  { label: "Computer Programming", value: "bhn0xrxk", logoPath: "" },
  { label: "Flash", value: "yl9hpcch", logoPath: "" },
  { label: "JLRoutes", value: "cckl9wzo", logoPath: "" },
  { label: "Object Detection", value: "ekbtvkbt", logoPath: "" },
  { label: "RMI", value: "jenva3fp", logoPath: "" },
  { label: "Test Rail", value: "mbwl5kyy", logoPath: "" },
  { label: "APNS", value: "x5psie80", logoPath: "" },
  { label: "Computer Security", value: "zmmmqfdo", logoPath: "" },
  { label: "Flash Animation", value: "tzto20na", logoPath: "" },
  { label: "JMeter", value: "mohpx1gt", logoPath: "" },
  {
    label: "Object Oriented Development (OOD)",
    value: "0wdoxpfk",
    logoPath: "",
  },
  {
    label: "Robotic Process Automation (RPA)",
    value: "thrnfzso",
    logoPath: "",
  },
  { label: "Test-driven development (TDD)", value: "lvslame0", logoPath: "" },
  { label: "Apollo", value: "ykatqgwn", logoPath: "" },
  { label: "Computer Vision", value: "ccuw4pu1", logoPath: "" },
  { label: "Flashcode", value: "0ztebhfu", logoPath: "" },
  { label: "JMS (Java Messaging Service)", value: "onpdkroi", logoPath: "" },
  { label: "Objective C", value: "wryx3udo", logoPath: "" },
  { label: "Rocket Chat", value: "ttjxqblp", logoPath: "" },
  { label: "TestComplete", value: "cjrnuywj", logoPath: "" },
  { label: "Apollo Client", value: "ldi5pygz", logoPath: "" },
  { label: "Concept Development", value: "4zeavb7e", logoPath: "" },
  { label: "Flask", value: "bznujumf", logoPath: "" },
  { label: "JMX", value: "fo1pde6l", logoPath: "" },
  { label: "OBS", value: "o7cxrkz5", logoPath: "" },
  { label: "Roda", value: "3hwhcsid", logoPath: "" },
  { label: "TestFlight", value: "phfbue8o", logoPath: "" },
  { label: "Apollo Storm", value: "p5dei4go", logoPath: "" },
  { label: "Concurrency", value: "pb7uckck", logoPath: "" },
  { label: "Flask-SQL Alchemy", value: "bzlz7vrg", logoPath: "" },
  { label: "Joget", value: "g2o9ttid", logoPath: "" },
  { label: "Observium", value: "zzzyd5fg", logoPath: "" },
  { label: "Roku", value: "77o8rpwr", logoPath: "" },
  { label: "Testlink", value: "y0mcrptj", logoPath: "" },
  { label: "Apollo-client", value: "urae66yf", logoPath: "" },
  { label: "Concurrent Programming", value: "hdyuj6iz", logoPath: "" },
  { label: "Flex", value: "slu2kkvv", logoPath: "" },
  { label: "Johnny Cash", value: "eitghiqc", logoPath: "" },
  { label: "Octopus", value: "w7agdyt2", logoPath: "" },
  { label: "Rollbar", value: "a1mhpamc", logoPath: "" },
  { label: "TestNG", value: "m70w8z6t", logoPath: "" },
  { label: "App Code", value: "5rd7j6gk", logoPath: "" },
  { label: "Conda", value: "drwwrn9f", logoPath: "" },
  { label: "FlexCube", value: "cufxz4bz", logoPath: "" },
  { label: "Joomla", value: "jvqazli4", logoPath: "" },
  { label: "ODBC", value: "oqopte7w", logoPath: "" },
  { label: "Rollup", value: "hana1vqm", logoPath: "" },
  { label: "Texture", value: "b9axcepj", logoPath: "" },
  { label: "App Design", value: "bwblec0g", logoPath: "" },
  { label: "Config", value: "qyolyk0x", logoPath: "" },
  { label: "Flexibility", value: "cp6sczvw", logoPath: "" },
  { label: "Jooq", value: "sdsyxmxk", logoPath: "" },
  { label: "OGC Standards", value: "shitttfi", logoPath: "" },
  { label: "Room", value: "mzgbcbia", logoPath: "" },
  { label: "TFS", value: "km4o5g6t", logoPath: "" },
  { label: "App Dynamic", value: "oqlfjzsh", logoPath: "" },
  { label: "Conflict Resolution", value: "5ixztiqz", logoPath: "" },
  { label: "Flow", value: "jtoskzuy", logoPath: "" },
  { label: "JPQL", value: "u54fift9", logoPath: "" },
  { label: "Okta", value: "8kvbkhhe", logoPath: "" },
  { label: "Route 53", value: "hztljrjy", logoPath: "" },
  { label: "THREE.JS", value: "u2bwopua", logoPath: "" },
  { label: "App Extensions", value: "enntg62o", logoPath: "" },
  { label: "Confluence", value: "vum7t30e", logoPath: "" },
  { label: "fluentd", value: "dea3n61q", logoPath: "" },
  { label: "jqGrid", value: "qyznpft4", logoPath: "" },
  { label: "OLAP", value: "rr25oygi", logoPath: "" },
  { label: "Routing", value: "8jyud7d9", logoPath: "" },
  { label: "Thymeleaf", value: "baly5bai", logoPath: "" },
  { label: "Appium", value: "rzubwfxx", logoPath: "" },
  { label: "Console App", value: "krzgqe0f", logoPath: "" },
  { label: "Flume", value: "chdbmi6l", logoPath: "" },
  { label: "OneSignal", value: "dlgm0is4", logoPath: "" },
  { label: "RPM", value: "1yubhoy4", logoPath: "" },
  { label: "Tibco Business Work", value: "j8qtuoup", logoPath: "" },
  { label: "Apple MDM", value: "y45mzzza", logoPath: "" },
  { label: "Consul", value: "npyiu6ac", logoPath: "" },
  { label: "OOCSS", value: "6ugkt8g2", logoPath: "" },
  { label: "RSA", value: "jxcck1oi", logoPath: "" },
  { label: "Tibco iProcess", value: "iz9evlsi", logoPath: "" },
  { label: "Apple Pay", value: "mmn5tsu5", logoPath: "" },
  { label: "Consulting", value: "c6oomroj", logoPath: "" },
  { label: "Font Awesome", value: "mkosfmga", logoPath: "" },
  { label: "OOD", value: "pbigkaxo", logoPath: "" },
  { label: "RSpec", value: "odkil4ik", logoPath: "" },
  { label: "Tiles", value: "3bz2o6bt", logoPath: "" },
  { label: "Apple Push Notifications", value: "xyqhtrsl", logoPath: "" },
  { label: "Container Orchestration", value: "bwqtve6g", logoPath: "" },
  { label: "Fontlab", value: "o3mfamxv", logoPath: "" },
  { label: "JRecordBind", value: "kgs8zgy6", logoPath: "" },
  { label: "OOP", value: "cabfva9b", logoPath: "" },
  { label: "rtp", value: "7ldz8wcd", logoPath: "" },
  { label: "Time Management", value: "tjtlux2c", logoPath: "" },
  {
    label: "Application Performance Monitoring",
    value: "rhgk0dor",
    logoPath: "",
  },
  { label: "Containerization", value: "1xg4bxwj", logoPath: "" },
  { label: "Fortify", value: "tibelbtk", logoPath: "" },
  { label: "JSON", value: "00wtnvgn", logoPath: "" },
  { label: "Oozie", value: "i4tzndfn", logoPath: "" },
  { label: "Rubocop Style Controller", value: "ong4x2mh", logoPath: "" },
  { label: "Time Series Analysis", value: "d6zu8aho", logoPath: "" },
  { label: "AppsFlyer", value: "3dfhbhd7", logoPath: "" },
  { label: "Containers", value: "pwh1wvve", logoPath: "" },
  { label: "FortiGate UTM", value: "iwvmzfud", logoPath: "" },
  { label: "JSTL", value: "nqgtzk5y", logoPath: "" },
  { label: "Open API", value: "4lriy59m", logoPath: "" },
  { label: "Tiny TDS", value: "jfn0d7zd", logoPath: "" },
  { label: "AR Kit", value: "mcpj2bgi", logoPath: "" },
  { label: "Content Writer", value: "5nledigb", logoPath: "" },
  { label: "Fortran", value: "dwiydcbr", logoPath: "" },
  { label: "Julia", value: "hdr2beqi", logoPath: "" },
  { label: "Open CSV", value: "h7fsryuc", logoPath: "" },
  { label: "Ruby Gems", value: "8wdjs97g", logoPath: "" },
  { label: "TOGAF", value: "643d7grf", logoPath: "" },
  { label: "ArcGIS", value: "qxy9x4td", logoPath: "" },
  { label: "Contentful", value: "2dyh80pb", logoPath: "" },
  { label: "Foundation 5", value: "i1up69jo", logoPath: "" },
  { label: "Juniper", value: "jvjgioyi", logoPath: "" },
  {
    label: "Open Diagnostic data eXchange (ODX)",
    value: "o4cekkv7",
    logoPath: "",
  },
  { label: "Tokbox", value: "qu6ixmsy", logoPath: "" },
  { label: "Architecture Components", value: "5bcmcgwo", logoPath: "" },
  { label: "Continuous Delivery (CD)", value: "7b10qull", logoPath: "" },
  { label: "FPS", value: "w4bjdwo9", logoPath: "" },
  { label: "jUnit", value: "7mrecbcu", logoPath: "" },
  { label: "Open GL", value: "jfxektvh", logoPath: "" },
  { label: "Ruby Scripting", value: "smulol5c", logoPath: "" },
  { label: "TokuDB", value: "wqlpxwyg", logoPath: "" },
  { label: "Arcobjects", value: "9mkchvmg", logoPath: "" },
  { label: "Continuous Integration (CI)", value: "wgglgzqg", logoPath: "" },
  { label: "FreeBSD", value: "jkledvv8", logoPath: "" },
  { label: "Jupiter Notebook", value: "1mf6eqhp", logoPath: "" },
  { label: "Open Layers", value: "u9vxbwxl", logoPath: "" },
  { label: "Tomcat Server", value: "siymieep", logoPath: "" },
  { label: "ArcPy", value: "xwoe2fe3", logoPath: "" },
  { label: "Convert", value: "x3ppceez", logoPath: "" },
  { label: "Freemarker", value: "5v8jd72v", logoPath: "" },
  { label: "Jupyter", value: "kefuqvxd", logoPath: "" },
  { label: "Open Swan", value: "pnjl7gxp", logoPath: "" },
  { label: "rvm", value: "2jkcka3n", logoPath: "" },
  { label: "TOPCON Security Systems", value: "w3bts5ra", logoPath: "" },
  { label: "ArcSight ESM", value: "cd8pl5tq", logoPath: "" },
  { label: "CORBA", value: "r0ewmwps", logoPath: "" },
  { label: "Frontend Development", value: "tl36cjka", logoPath: "" },
  { label: "JW Player", value: "gbecmf5s", logoPath: "" },
  { label: "Open Test eXchange (OTX)", value: "kobimbqb", logoPath: "" },
  { label: "RxCocoa", value: "q1jsze0i", logoPath: "" },
  { label: "Tornado", value: "jogvdvaf", logoPath: "" },
  { label: "Arduino", value: "x44rhopx", logoPath: "" },
  { label: "Cordova", value: "ynhpoxrd", logoPath: "" },
  { label: "FRP", value: "bgtw1rru", logoPath: "" },
  { label: "K8S", value: "ghjyctwi", logoPath: "" },
  { label: "OpenCV", value: "opav93vp", logoPath: "" },
  { label: "RxJava", value: "kwnycrbc", logoPath: "" },
  { label: "Torque", value: "9hkqijtf", logoPath: "" },
  { label: "ARM", value: "nozunho3", logoPath: "" },
  { label: "Core Animation", value: "fucmwpsl", logoPath: "" },
  { label: "Full Calendar", value: "vk6uoz9b", logoPath: "" },
  { label: "Kafka", value: "f1pcaqoo", logoPath: "" },
  { label: "Openface", value: "udvdfvd9", logoPath: "" },
  { label: "RxJava 2", value: "kbxlmx5n", logoPath: "" },
  { label: "Tortoise SVN", value: "xua5dxku", logoPath: "" },
  { label: "ArrangoDB", value: "aahwptng", logoPath: "" },
  { label: "Core Data", value: "rrv9jv6t", logoPath: "" },
  { label: "Full Stack Development", value: "w6zevg4d", logoPath: "" },
  { label: "Kanban", value: "lqcpt7g2", logoPath: "" },
  { label: "OpenGL", value: "bcnsbtip", logoPath: "" },
  { label: "RXJS", value: "acmnsxgd", logoPath: "" },
  { label: "Total.js", value: "mlbevwa5", logoPath: "" },
  { label: "Art Director", value: "8nbsqbak", logoPath: "" },
  { label: "Core Graphics", value: "hxp9l1yz", logoPath: "" },
  { label: "Functional Analysis", value: "tvx9rwkk", logoPath: "" },
  { label: "Karma", value: "xmxz9fnx", logoPath: "" },
  { label: "OpenLayers", value: "iu75zmje", logoPath: "" },
  { label: "RxKotlin", value: "eymp1ena", logoPath: "" },
  { label: "Traceroute", value: "pqqpmqyw", logoPath: "" },
  { label: "Artifactory", value: "foduwksf", logoPath: "" },
  { label: "Core Java", value: "40batknk", logoPath: "" },
  { label: "Functional programming", value: "2h1wyg3k", logoPath: "" },
  { label: "KEL stack", value: "yamauhjz", logoPath: "" },
  { label: "OpenML", value: "uupufxgi", logoPath: "" },
  { label: "RxSwift", value: "dyvypie3", logoPath: "" },
  { label: "Travel Agency", value: "lk7edljc", logoPath: "" },
  {
    label: "Artificial Emotional Intelligence",
    value: "sjlaeilb",
    logoPath: "",
  },
  { label: "Core Location", value: "n0p8ps5g", logoPath: "" },
  { label: "Functional Testing", value: "azq7ni8q", logoPath: "" },
  { label: "Kendo UI", value: "zvjtabgd", logoPath: "" },
  { label: "OpenNebula", value: "l37jilkq", logoPath: "" },
  { label: "S3", value: "qkm9lmmu", logoPath: "" },
  { label: "Travis CI", value: "opih3bai", logoPath: "" },
  { label: "Artificial Intelligence", value: "fvcuyyux", logoPath: "" },
  { label: "Corel Draw", value: "8n33ghkj", logoPath: "" },
  { label: "Fusion", value: "s3c7bslz", logoPath: "" },
  { label: "Kentico CMS", value: "8uypmugd", logoPath: "" },
  { label: "OpenNMS", value: "ouoc9yv2", logoPath: "" },
  { label: "SaaS", value: "o7rjgevk", logoPath: "" },
  { label: "Trello", value: "rzm0apdz", logoPath: "" },
  { label: "AS400", value: "dd4svbra", logoPath: "" },
  { label: "CoreLocation", value: "lhhksv8a", logoPath: "" },
  { label: "G Language for Robotics", value: "mrawbf1f", logoPath: "" },
  { label: "Keras", value: "fhetjsro", logoPath: "" },
  { label: "OpenShift", value: "kwzbweop", logoPath: "" },
  { label: "SAFe®", value: "le2a6rf3", logoPath: "" },
  { label: "TrueCript", value: "8bpqvnkh", logoPath: "" },
  { label: "Asana", value: "havsqhij", logoPath: "" },
  { label: "CoreMotion", value: "coiot9yn", logoPath: "" },
  { label: "G!Nius", value: "fkcw6yhx", logoPath: "" },
  { label: "Kettle", value: "rpmsfmdh", logoPath: "" },
  { label: "OpenSSL", value: "ihesyhih", logoPath: "" },
  { label: "Sagas", value: "5ogpvmj9", logoPath: "" },
  { label: "TrueCrypt", value: "lskflazs", logoPath: "" },
  { label: "ASDK", value: "gozbkqnx", logoPath: "" },
  { label: "Coroutines", value: "hwqptr8l", logoPath: "" },
  { label: "Galen Framework", value: "yjwolz4q", logoPath: "" },
  { label: "Keychain Sharing", value: "nktmwpjb", logoPath: "" },
  { label: "Openstack", value: "w3niv5og", logoPath: "" },
  { label: "Sage Intacct", value: "1bu9rzzf", logoPath: "" },
  { label: "Truffle", value: "5aokzggm", logoPath: "" },
  { label: "ASG", value: "qky1hkr1", logoPath: "" },
  { label: "Cortex", value: "mi2lrsz4", logoPath: "" },
  { label: "Game Development", value: "5otglgbw", logoPath: "" },
  { label: "Keynote", value: "q8voe6hl", logoPath: "" },
  { label: "OpenUI5", value: "zy3rk6qo", logoPath: "" },
  { label: "Sails.js", value: "hqgwlgtb", logoPath: "" },
  { label: "Turbogears", value: "7xdzjmgb", logoPath: "" },
  { label: "ASN.1", value: "g7w1fmxc", logoPath: "" },
  { label: "Couchbase", value: "8dfxoptz", logoPath: "" },
  { label: "Gatling", value: "fciua6ng", logoPath: "" },
  { label: "Kibana", value: "14zlauc4", logoPath: "" },
  { label: "OpenVPN", value: "uq7uidhg", logoPath: "" },
  { label: "Sales and Marketing", value: "dmhyeglq", logoPath: "" },
  { label: "tvOS", value: "l7orfvpj", logoPath: "" },
  { label: "ASP Forms", value: "wpemw5yb", logoPath: "" },
  { label: "CouchDB", value: "w50s4l2d", logoPath: "" },
  { label: "GatsbyJS", value: "rrdhornf", logoPath: "" },
  { label: "Kinesis", value: "x0wvgd0k", logoPath: "" },
  { label: "Openvz", value: "tsptixnr", logoPath: "" },
  { label: "SalesForce", value: "pb3zmgkg", logoPath: "" },
  { label: "TWIG", value: "3cszj8ke", logoPath: "" },
  { label: "ASP.Classic", value: "8eltmlos", logoPath: "" },
  { label: "Covadis", value: "mqb46r5d", logoPath: "" },
  { label: "GC", value: "7kqthmex", logoPath: "" },
  { label: "Klein", value: "rf8sk5s0", logoPath: "" },
  { label: "OpenWRT", value: "k1qembd6", logoPath: "" },
  { label: "Salesforce CRM", value: "c6g4zz1x", logoPath: "" },
  { label: "Twisted", value: "0yrahwoy", logoPath: "" },
  { label: "ASP.NET", value: "yhhke6uv", logoPath: "" },
  { label: "CPanel", value: "s1rt6kqs", logoPath: "" },
  { label: "GCD", value: "ormj1ftz", logoPath: "" },
  { label: "Knex.js", value: "5w9kats3", logoPath: "" },
  { label: "Ops Work", value: "lcgysw1i", logoPath: "" },
  { label: "Salkstack", value: "sdxxuds8", logoPath: "" },
  { label: "ASP.NET Core", value: "df7wdcxc", logoPath: "" },
  { label: "CppUnit", value: "tb1kfbnb", logoPath: "" },
  { label: "GCP", value: "psgssgfj", logoPath: "" },
  { label: "KNIME", value: "wxyd78ol", logoPath: "" },
  { label: "Optimizely", value: "xbtuzqo5", logoPath: "" },
  { label: "Saltstack", value: "euauc5qv", logoPath: "" },
  { label: "ASP.NET Identity", value: "rrptzxas", logoPath: "" },
  { label: "CQRS", value: "ohmytaoh", logoPath: "" },
  { label: "GDAL", value: "syaudshw", logoPath: "" },
  { label: "Knockout.JS", value: "liomah2v", logoPath: "" },
  { label: "Oracle", value: "m9qmklks", logoPath: "" },
  { label: "SAML", value: "kqe7qvqu", logoPath: "" },
  { label: "Typo 3", value: "43my378z", logoPath: "" },
  { label: "ASP.NET MVC", value: "jj8zgtxo", logoPath: "" },
  { label: "Crashlytics", value: "dcqhjo71", logoPath: "" },
  { label: "Gearman", value: "h97j4uqt", logoPath: "" },
  { label: "KoaJS", value: "qrm4qlt6", logoPath: "" },
  { label: "Oracle 11g", value: "ivofaxlv", logoPath: "" },
  { label: "Samsung Smart TV", value: "9j7kadcc", logoPath: "" },
  { label: "Typography", value: "flfiudsa", logoPath: "" },
  { label: "Assembly", value: "vsmh0qib", logoPath: "" },
  { label: "CrateDB", value: "ynogqzju", logoPath: "" },
  { label: "Gearset", value: "ikuxnhtq", logoPath: "" },
  { label: "Kohana", value: "enornnpj", logoPath: "" },
  { label: "Oracle BIEE", value: "xeamxtal", logoPath: "" },
  { label: "Sanic", value: "2q4kqwrx", logoPath: "" },
  { label: "Ubiquiti", value: "foymus0e", logoPath: "" },
  { label: "Asterisk", value: "ri0cjhic", logoPath: "" },
  { label: "Creative Management", value: "gff36pvj", logoPath: "" },
  { label: "Gelatine", value: "idnxjkva", logoPath: "" },
  { label: "Koin DI", value: "gq1ydqg4", logoPath: "" },
  { label: "Oracle BPEL", value: "zsssliyy", logoPath: "" },
  { label: "SAP", value: "pxpezk6n", logoPath: "" },
  { label: "Async", value: "rmttpw6o", logoPath: "" },
  { label: "Creative Thinking", value: "emsdgxca", logoPath: "" },
  { label: "Genteran Integration Suite", value: "89hptwin", logoPath: "" },
  { label: "Kong", value: "jxszbi2j", logoPath: "" },
  { label: "Oracle BPM", value: "bvnw2qge", logoPath: "" },
  { label: "SAP BO", value: "wmfpw8kz", logoPath: "" },
  { label: "UDK", value: "b66h8qvm", logoPath: "" },
  { label: "Async Socket", value: "u86qly6s", logoPath: "" },
  { label: "Critical Thinking", value: "tdkk2mjx", logoPath: "" },
  { label: "Geolocation", value: "ptkc0yae", logoPath: "" },
  { label: "Kony Developer", value: "z0m0iwac", logoPath: "" },
  { label: "Oracle Cloud Services", value: "xfo6t4da", logoPath: "" },
  { label: "SAP Data Services", value: "v2pyceg3", logoPath: "" },
  { label: "UI Design", value: "lcq1u8hx", logoPath: "" },
  { label: "AsyncDisplayKit", value: "iqywiv2m", logoPath: "" },
  { label: "CRM Systems", value: "vedar9an", logoPath: "" },
  { label: "Geonode", value: "ujtqivgr", logoPath: "" },
  { label: "Oracle DB", value: "fft1hrai", logoPath: "" },
  { label: "SAP HANA", value: "jfverad3", logoPath: "" },
  { label: "UI Grid", value: "f6rmkfb1", logoPath: "" },
  { label: "AsyncIO", value: "645oxel3", logoPath: "" },
  { label: "Cross Compiling", value: "vs7qjxid", logoPath: "" },
  { label: "Geoserver", value: "h8th50cy", logoPath: "" },
  { label: "Kotlin Coroutines", value: "nzycwh88", logoPath: "" },
  { label: "Oracle Forms", value: "yt0ddpwc", logoPath: "" },
  { label: "SAP SDK", value: "fz82tvjt", logoPath: "" },
  { label: "UI Kit", value: "rxdneci8", logoPath: "" },
  { label: "Atomic Design", value: "cnbydkex", logoPath: "" },
  { label: "CRUD", value: "62kjepdi", logoPath: "" },
  { label: "Gevent", value: "2w0qgbmh", logoPath: "" },
  { label: "Kronos", value: "gvzanioy", logoPath: "" },
  { label: "Oracle Reports", value: "c6rp1yiq", logoPath: "" },
  { label: "SAP XSIS", value: "cibzqgzs", logoPath: "" },
  { label: "UI Prototyping", value: "kzm5tela", logoPath: "" },
  { label: "Audio Streaming", value: "noiktymm", logoPath: "" },
  { label: "CruiseControl", value: "en01uz2e", logoPath: "" },
  { label: "GForge", value: "idqwfuda", logoPath: "" },
  { label: "Kubeflow", value: "boy9dpth", logoPath: "" },
  { label: "Oracle SOA Suite", value: "emeqarf5", logoPath: "" },
  { label: "SAS", value: "nznd95t3", logoPath: "" },
  { label: "UI Test", value: "fliwkweb", logoPath: "" },
  { label: "Augmented Reality", value: "mirk8jp6", logoPath: "" },
  { label: "Cryptocurrency", value: "dossdhux", logoPath: "" },
  { label: "Gimp", value: "zib2vqgz", logoPath: "" },
  { label: "Oracle Spatial", value: "it3s0pf9", logoPath: "" },
  { label: "SASM", value: "ubrw6uli", logoPath: "" },
  { label: "UI/UX", value: "bvoqxirl", logoPath: "" },
  { label: "Aurora", value: "rep48akp", logoPath: "" },
  { label: "Cryptography", value: "g1bphmor", logoPath: "" },
  { label: "Gin", value: "l3kjl9hj", logoPath: "" },
  { label: "KVM", value: "dkzsnbj9", logoPath: "" },
  { label: "Oracle Symphony", value: "phampuyz", logoPath: "" },
  { label: "UI/UX Design", value: "z2jjbm0w", logoPath: "" },
  { label: "Aurora Serverless", value: "7l1ypxbx", logoPath: "" },
  { label: "Crystal", value: "eboeksvm", logoPath: "" },
  { label: "Ginkgo", value: "h03rwun1", logoPath: "" },
  { label: "Lambda", value: "eqjtvuer", logoPath: "" },
  { label: "Oracle Ultra Search", value: "ln8vraaq", logoPath: "" },
  { label: "UIKit", value: "zklvktir", logoPath: "" },
  { label: "Auth0", value: "xu8m1z0n", logoPath: "" },
  { label: "Crystal Reports", value: "9jldstom", logoPath: "" },
  { label: "GIS", value: "4uppypge", logoPath: "" },
  { label: "LAMP", value: "m6xi0kld", logoPath: "" },
  { label: "Oracle Weblogic", value: "rvdig9nr", logoPath: "" },
  { label: "SCC", value: "cpx75qyr", logoPath: "" },
  { label: "UiPath", value: "mx9y22db", logoPath: "" },
  { label: "Auto layout", value: "3tvcuaym", logoPath: "" },
  { label: "CSF", value: "jfbldbqf", logoPath: "" },
  { label: "Landing Zones", value: "v3visno3", logoPath: "" },
  { label: "OracleVm", value: "ozfdkn7g", logoPath: "" },
  { label: "SceneKit", value: "fst4puzb", logoPath: "" },
  { label: "UIXCTest", value: "mka3mkel", logoPath: "" },
  { label: "Auto Mapper", value: "axfctadl", logoPath: "" },
  { label: "CSM", value: "4lf8ztsg", logoPath: "" },
  { label: "Gitflow", value: "ypentex5", logoPath: "" },
  { label: "Laravel Forge", value: "4fdlfifi", logoPath: "" },
  { label: "ORB", value: "jv2a0wsz", logoPath: "" },
  { label: "Scikit Learn", value: "bsxflesl", logoPath: "" },
  { label: "UML", value: "shyuumns", logoPath: "" },
  { label: "Auto-scaling", value: "bnjdq72e", logoPath: "" },
  { label: "CSS", value: "conija52", logoPath: "" },
  { label: "Organisational skills", value: "isjw1dh0", logoPath: "" },
  { label: "SciPy", value: "3zbfih3k", logoPath: "" },
  { label: "UML 2", value: "3nwcfsyp", logoPath: "" },
  { label: "Autocad", value: "dowepeih", logoPath: "" },
  { label: "LaTeX", value: "jlw6odfb", logoPath: "" },
  { label: "OrientDB", value: "nr93rovp", logoPath: "" },
  { label: "Scrapping", value: "qrflcwbw", logoPath: "" },
  { label: "Underscore.js", value: "pje6cu5c", logoPath: "" },
  { label: "Autofaq", value: "tpedpqcu", logoPath: "" },
  { label: "Leadership", value: "3ebugp7h", logoPath: "" },
  { label: "ORM", value: "x6svvugy", logoPath: "" },
  { label: "Scrapy", value: "bcm6hbei", logoPath: "" },
  { label: "Unfuddle", value: "nlcmihjm", logoPath: "" },
  {
    label: "Automated Scripts Implementation",
    value: "ggyr3dcg",
    logoPath: "",
  },
  { label: "CUDA", value: "xhoqbfbh", logoPath: "" },
  { label: "GitOps", value: "jd01bxhm", logoPath: "" },
  { label: "Leaflet", value: "yssk2aps", logoPath: "" },
  { label: "ORM Database", value: "z5f1e5aq", logoPath: "" },
  { label: "Scrum", value: "rn4pfcwt", logoPath: "" },
  { label: "Unit Testing", value: "lxdo1i6r", logoPath: "" },
  { label: "Automated Testing", value: "904jxrnw", logoPath: "" },
  { label: "Custom Integration", value: "xppqfjtv", logoPath: "" },
  { label: "Glass Fish", value: "15i9ynqm", logoPath: "" },
  { label: "Lean", value: "thsvi4qc", logoPath: "" },
  { label: "OSGI", value: "1isfcvtl", logoPath: "" },
  { label: "Scrumban", value: "7sanfyhs", logoPath: "" },
  { label: "Unitils", value: "9ebamfh9", logoPath: "" },
  { label: "Automation", value: "aloancau", logoPath: "" },
  { label: "CVS", value: "uy5dulww", logoPath: "" },
  {
    label: "Global Distribution System (GDS)",
    value: "kswh5hot",
    logoPath: "",
  },
  { label: "Other", value: "roktgsaj", logoPath: "" },
  { label: "ScyllaDB", value: "ergopprq", logoPath: "" },
  { label: "Unity", value: "j8ovolps", logoPath: "" },
  { label: "Automation Anywhere", value: "namp14hi", logoPath: "" },
  { label: "Cyberoam", value: "nwxvczqi", logoPath: "" },
  { label: "GLSL", value: "3b09dook", logoPath: "" },
  { label: "Let's Encrypt", value: "6u44memd", logoPath: "" },
  { label: "OTP", value: "phstst8m", logoPath: "" },
  { label: "SDL", value: "atwshvnb", logoPath: "" },
  { label: "Unity 3D", value: "vqbdckye", logoPath: "" },
  { label: "Autosar", value: "uivegnou", logoPath: "" },
  { label: "Cygwin", value: "84jlqc3l", logoPath: "" },
  { label: "Glyphs", value: "sxlh0bjd", logoPath: "" },
  { label: "LibGDX", value: "y7ltes66", logoPath: "" },
  { label: "OTRS", value: "wlf0nohy", logoPath: "" },
  { label: "SDLC", value: "wkw7hshm", logoPath: "" },
  { label: "Unix", value: "kidpm9la", logoPath: "" },
  { label: "AVFoundation", value: "emdzmdmn", logoPath: "" },
  { label: "Cypress", value: "d1mlj4fr", logoPath: "" },
  { label: "GNU", value: "gpb0emu3", logoPath: "" },
  { label: "Libpthread", value: "tzcw39ca", logoPath: "" },
  {
    label: "OWASP (Web Application Security)",
    value: "chsioboj",
    logoPath: "",
  },
  { label: "Seaborn", value: "1o4ftwl7", logoPath: "" },
  { label: "Unreal Engine 4", value: "9eha92ps", logoPath: "" },
  { label: "Avro", value: "qibslrve", logoPath: "" },
  { label: "Cython", value: "zal4dwr0", logoPath: "" },
  { label: "Go Gin", value: "kmqftxqe", logoPath: "" },
  { label: "Librato", value: "x3luoo9k", logoPath: "" },
  { label: "Owin", value: "ig8ybl8g", logoPath: "" },
  { label: "SecOps", value: "wo4iyymp", logoPath: "" },
  { label: "UPS", value: "o78fhxtx", logoPath: "" },
  { label: "Cytoscape", value: "zz3nj0ey", logoPath: "" },
  { label: "Go-Kit", value: "rnzatir4", logoPath: "" },
  { label: "Liferay", value: "wxe9c4sd", logoPath: "" },
  { label: "Packer", value: "0zg4lmru", logoPath: "" },
  { label: "Secrets Manager", value: "j3hlyngt", logoPath: "" },
  { label: "URLSession", value: "sc0b396r", logoPath: "" },
  { label: "Lightning", value: "rv9tdmmg", logoPath: "" },
  { label: "Pager Duty", value: "jor2c3do", logoPath: "" },
  { label: "Security Certificate Pinning", value: "k8egbgtl", logoPath: "" },
  { label: "Usability", value: "s2dwdcky", logoPath: "" },
  { label: "Dagger", value: "mncetggt", logoPath: "" },
  { label: "Lingo", value: "imyrj13d", logoPath: "" },
  { label: "Paket", value: "x4euciyt", logoPath: "" },
  { label: "Security Fundamentals", value: "0ep7dh1r", logoPath: "" },
  { label: "Usability Audit", value: "rhma6chf", logoPath: "" },
  { label: "Dagger 2", value: "jbkdjhhk", logoPath: "" },
  { label: "Google Ads API", value: "j35tayku", logoPath: "" },
  { label: "Pandas", value: "7rwb4mgt", logoPath: "" },
  { label: "SeisComp3", value: "qq8pzrnv", logoPath: "" },
  { label: "Usability Testing", value: "q9qqazye", logoPath: "" },
  { label: "DAL", value: "eidbgbxf", logoPath: "" },
  { label: "Google Adwords", value: "vt4yndtw", logoPath: "" },
  { label: "Linode", value: "at2fmme4", logoPath: "" },
  { label: "Pantheon", value: "p5gg0e4w", logoPath: "" },
  { label: "Seldon", value: "muqhjkvb", logoPath: "" },
  { label: "User Experience", value: "vrfj9wmz", logoPath: "" },
  { label: "DAM", value: "j7fm0hlp", logoPath: "" },
  { label: "Google Analytics", value: "tqpxz8wm", logoPath: "" },
  { label: "LINQ", value: "49aor8c7", logoPath: "" },
  { label: "Papervision3D", value: "ghonx0fk", logoPath: "" },
  { label: "Selenium", value: "bxvupo13", logoPath: "" },
  { label: "User Experience Design", value: "jglmkb1q", logoPath: "" },
  { label: "Dapper", value: "f7tsbw2p", logoPath: "" },
  { label: "Google API", value: "csflj53m", logoPath: "" },
  { label: "Parquet", value: "w1y4ftst", logoPath: "" },
  { label: "Semantic Segmentation", value: "vc2jcrus", logoPath: "" },
  { label: "User Interface Design", value: "ejf8lyqb", logoPath: "" },
  { label: "Data Analysis", value: "klujtb1n", logoPath: "" },
  { label: "Google App Engine", value: "susm3ckc", logoPath: "" },
  { label: "Liquibase", value: "0cd1mhwc", logoPath: "" },
  { label: "Parse", value: "cprt1cce", logoPath: "" },
  { label: "Semaphore CI", value: "9ibtaolu", logoPath: "" },
  { label: "User Journeys", value: "mzb7wjjq", logoPath: "" },
  { label: "Data Centre", value: "piaicltf", logoPath: "" },
  { label: "Google Apps", value: "6sejyp7m", logoPath: "" },
  { label: "LiveData", value: "jtixgknm", logoPath: "" },
  { label: "Parse SDK", value: "itbv4biu", logoPath: "" },
  { label: "Sensu", value: "fkgyvd2e", logoPath: "" },
  { label: "User Research", value: "ycqr6xkr", logoPath: "" },
  { label: "Data Dog", value: "iyo1w1tq", logoPath: "" },
  { label: "Google Calendar", value: "e0ohe609", logoPath: "" },
  { label: "Load Balancing", value: "amq0o1bs", logoPath: "" },
  { label: "Parse Server", value: "xcjy0vyb", logoPath: "" },
  { label: "Sentry", value: "lokomjdg", logoPath: "" },
  { label: "User Stories", value: "kxsh5kti", logoPath: "" },
  { label: "Data Engineering", value: "ju3as6qt", logoPath: "" },
  { label: "Google Closure Builder", value: "h9juy3og", logoPath: "" },
  { label: "Load Testing", value: "tkauurw8", logoPath: "" },
  { label: "Pascal", value: "e0bx02sa", logoPath: "" },
  { label: "SEO", value: "5yiyaz7s", logoPath: "" },
  { label: "User Strategy", value: "1ocfnrok", logoPath: "" },
  { label: "Data Integration", value: "mxu158kh", logoPath: "" },
  { label: "Google Closure Template", value: "2mlz9rkw", logoPath: "" },
  { label: "Local Notifications", value: "emz7zt22", logoPath: "" },
  { label: "PassportJS", value: "9yk0bayl", logoPath: "" },
  { label: "Sequel", value: "boyrt6jb", logoPath: "" },
  { label: "User Testing", value: "gbtsibri", logoPath: "" },
  { label: "Data Integration (ELT/ETL)", value: "1083kzwh", logoPath: "" },
  { label: "Google Cloud", value: "afefbgf0", logoPath: "" },
  { label: "Log Collector", value: "agvka63u", logoPath: "" },
  { label: "PatternLab", value: "xntvlxuu", logoPath: "" },
  { label: "Sequel Pro", value: "rmeviwnu", logoPath: "" },
  { label: "USPS", value: "l95fqdry", logoPath: "" },
  { label: "Data Management", value: "wuutjfub", logoPath: "" },
  { label: "Google Cloud Firestore", value: "lsg49yqo", logoPath: "" },
  { label: "Log4j", value: "runvzdv2", logoPath: "" },
  { label: "Payment API", value: "k1is87by", logoPath: "" },
  { label: "Sequelize", value: "lqkk44oj", logoPath: "" },
  { label: "USSD", value: "wahiizpb", logoPath: "" },
  { label: "Data Modeling", value: "ez8qhxe8", logoPath: "" },
  { label: "Google Cloud Functions", value: "hrr12law", logoPath: "" },
  { label: "Log4Net", value: "vnsq7fkb", logoPath: "" },
  { label: "Payment Gateway", value: "wussmjxk", logoPath: "" },
  { label: "Serenity BDD Framework", value: "lqsw8ope", logoPath: "" },
  { label: "uWGI", value: "i1uzitsv", logoPath: "" },
  { label: "Data pipelines", value: "tclhjzjv", logoPath: "" },
  { label: "Google Cloud Messaging", value: "a0ixhxfe", logoPath: "" },
  { label: "Logo Design", value: "sx8m4nse", logoPath: "" },
  { label: "Payment SDK", value: "rjvudbtx", logoPath: "" },
  { label: "Serverless", value: "w1mkalfo", logoPath: "" },
  { label: "UX Design", value: "zy32ahlh", logoPath: "" },
  { label: "Data Processing", value: "r9wmwmrt", logoPath: "" },
  { label: "Google Cloud Pub/Sub", value: "s4yso87h", logoPath: "" },
  { label: "Logotype", value: "qgktzewo", logoPath: "" },
  { label: "PayPal API", value: "jafqqrlw", logoPath: "" },
  { label: "Serverless Framework", value: "ejqobei0", logoPath: "" },
  { label: "UX Design and Strategy", value: "yd2u093n", logoPath: "" },
  {
    label: "Data Processing and Analytical Pipelines",
    value: "q1ks6v7o",
    logoPath: "",
  },
  { label: "Google Cloud SQL", value: "bgghxveh", logoPath: "" },
  { label: "Logstash", value: "7cn3kphg", logoPath: "" },
  { label: "PCI DSS", value: "jdktlr0y", logoPath: "" },
  { label: "Service Catalog", value: "bd0pqefl", logoPath: "" },
  { label: "UX Research", value: "ibhuqvom", logoPath: "" },
  { label: "Data Pump", value: "8yrzif7s", logoPath: "" },
  { label: "Google Cloud Storage", value: "2g6v1lna", logoPath: "" },
  { label: "Looker", value: "vcvjl1ya", logoPath: "" },
  { label: "Penetration Testing", value: "5kadutz2", logoPath: "" },
  { label: "Service Now", value: "c7pg7vmp", logoPath: "" },
  { label: "UX/UI Designer", value: "fntmurjo", logoPath: "" },
  { label: "Data Science", value: "xouqwtft", logoPath: "" },
  { label: "Google Data Studio", value: "jp7hz1yg", logoPath: "" },
  { label: "Loopback", value: "idetxbbs", logoPath: "" },
  { label: "Pentaho", value: "yim5tasc", logoPath: "" },
  { label: "Service Oriented Architecture", value: "rnce1j9o", logoPath: "" },
  { label: "uxPin", value: "zjsfqbok", logoPath: "" },
  { label: "Data Structures", value: "nrspzh4k", logoPath: "" },
  { label: "Google EMM API", value: "eh1b3ngi", logoPath: "" },
  { label: "LUA", value: "goyh4vgh", logoPath: "" },
  { label: "PenTest", value: "mlid0jyi", logoPath: "" },
  { label: "Service Stack", value: "pv7rzloa", logoPath: "" },
  { label: "Vaadin", value: "xrpjbjg6", logoPath: "" },
  { label: "Data Synthesis", value: "nzmwgn6k", logoPath: "" },
  { label: "Google Face Detection API", value: "7pfhgava", logoPath: "" },
  { label: "Lucene", value: "scllzm6r", logoPath: "" },
  { label: "People Management", value: "mqqj5x17", logoPath: "" },
  { label: "Servlets", value: "vswqakdl", logoPath: "" },
  { label: "Vagrant", value: "c7w7tmzt", logoPath: "" },
  { label: "Data Vault", value: "f5w9bwuo", logoPath: "" },
  { label: "Google Fusion Tables", value: "btywl3i9", logoPath: "" },
  { label: "Luks", value: "w3cosw5f", logoPath: "" },
  { label: "PeopleSoft", value: "fz9w6off", logoPath: "" },
  { label: "SeveralNines ClusterControl", value: "zuamkuw9", logoPath: "" },
  { label: "Valgrind", value: "bwyajmep", logoPath: "" },
  { label: "AWT", value: "obatwh13", logoPath: "" },
  { label: "Data Visualization", value: "iqhljtu8", logoPath: "" },
  { label: "Google Geocoder", value: "iuzo61ee", logoPath: "" },
  { label: "Lumen", value: "crjfkkhh", logoPath: "" },
  { label: "Perforce", value: "d0yplqyp", logoPath: "" },
  { label: "Shadow DOM", value: "evjovxgr", logoPath: "" },
  { label: "Vanilla", value: "lcg5w4jk", logoPath: "" },
  { label: "Axis", value: "d3nkaowx", logoPath: "" },
  { label: "Data Warehouse", value: "llwrykim", logoPath: "" },
  { label: "Google Geocoding API", value: "awzpfjc4", logoPath: "" },
  { label: "LXC", value: "jdlfhx0w", logoPath: "" },
  { label: "Perl", value: "4yjkybwz", logoPath: "" },
  { label: "Sharepoint", value: "9fmxfhmd", logoPath: "" },
  { label: "Vapor", value: "xgtjcfnf", logoPath: "" },
  { label: "Axure", value: "dsvchwn8", logoPath: "" },
  { label: "Database", value: "m6pkjqo9", logoPath: "" },
  { label: "Google Maps", value: "pthmiut6", logoPath: "" },
  { label: "M&A", value: "nad90hj3", logoPath: "" },
  { label: "Phabricator", value: "fy2va4jl", logoPath: "" },
  { label: "Shell", value: "eeibvben", logoPath: "" },
  { label: "Varnish", value: "jjmjryvz", logoPath: "" },
  { label: "Azure Active Directory", value: "d9owe9fk", logoPath: "" },
  { label: "Database Administration", value: "vzvwprwn", logoPath: "" },
  { label: "Google PubSub", value: "gzqammcr", logoPath: "" },
  { label: "Mac OS", value: "bm0qrpcx", logoPath: "" },
  { label: "Phalcon", value: "9zbib6a0", logoPath: "" },
  { label: "Shiny", value: "oupdpdo6", logoPath: "" },
  { label: "Vault", value: "dx2yarwk", logoPath: "" },
  { label: "Azure Blob Queues", value: "za9dsfpw", logoPath: "" },
  { label: "Database Design", value: "88sfmgqz", logoPath: "" },
  { label: "Google Push Notification", value: "qpqy47rl", logoPath: "" },
  { label: "Machine Learning", value: "sr1ol5v0", logoPath: "" },
  { label: "Phoenix Framework", value: "lppzikhx", logoPath: "" },
  { label: "Shop-Script 5", value: "yvdpzwu0", logoPath: "" },
  { label: "VB", value: "dkouj2mz", logoPath: "" },
  { label: "Azure Data Factory", value: "nnyxwqka", logoPath: "" },
  { label: "Database MINISIS", value: "lxohcguv", logoPath: "" },
  { label: "Google SDK", value: "30d3ikro", logoPath: "" },
  { label: "Macie", value: "ydxn2wvz", logoPath: "" },
  { label: "PhoneGap", value: "gpki264h", logoPath: "" },
  { label: "Shopify", value: "qy8qbu7r", logoPath: "" },
  { label: "VB Script", value: "zkl0hqtf", logoPath: "" },
  { label: "Azure Data Lake Store", value: "qfp30eul", logoPath: "" },
  { label: "Databases", value: "vgzyqmw8", logoPath: "" },
  { label: "Google Search Appliance", value: "sgjarfcl", logoPath: "" },
  { label: "Magento", value: "flcxgz43", logoPath: "" },
  { label: "Photo editing Content Strategy", value: "rpnqficq", logoPath: "" },
  { label: "Shopify API", value: "c0uz0ayt", logoPath: "" },
  { label: "VB.Net", value: "22sbpa4u", logoPath: "" },
  { label: "Azure Databricks", value: "y37p355f", logoPath: "" },
  { label: "Datacenter", value: "6nyqe74a", logoPath: "" },
  { label: "Google Services", value: "pwb0mdax", logoPath: "" },
  { label: "Magic Draw 9.5", value: "3g2irbkk", logoPath: "" },
  { label: "Photography", value: "vklt6kh5", logoPath: "" },
  { label: "Shorewall", value: "makrevux", logoPath: "" },
  { label: "VB6", value: "z0hicee9", logoPath: "" },
  { label: "Azure Functions", value: "bz3xfo0j", logoPath: "" },
  { label: "DataDog", value: "rhtkxqrs", logoPath: "" },
  { label: "Google Tag Manager (GTM)", value: "mucisikg", logoPath: "" },
  { label: "MagicalRecord", value: "3p5upb3d", logoPath: "" },
  { label: "Sidekiq", value: "x6fpibci", logoPath: "" },
  { label: "Vbscript", value: "nojcr74a", logoPath: "" },
  { label: "Azure PaaS Cloud Services", value: "2tmpacmw", logoPath: "" },
  { label: "DB Design and Implementation", value: "euq4acxj", logoPath: "" },
  { label: "Google Test", value: "lsimrv7u", logoPath: "" },
  { label: "Magnolia CMS", value: "uypcefoz", logoPath: "" },
  { label: "PHP5.60", value: "hmtq2yja", logoPath: "" },
  { label: "Siemens API", value: "3p159axv", logoPath: "" },
  { label: "vBulletin", value: "lvzwrn2l", logoPath: "" },
  { label: "Azure Service Bus", value: "v0v0auej", logoPath: "" },
  { label: "DB2", value: "wdba4ghv", logoPath: "" },
  { label: "Gpm", value: "nkj7jcd5", logoPath: "" },
  { label: "Mailchimp API", value: "qg7ajftf", logoPath: "" },
  { label: "Siemens OpenInventor", value: "bvsgg0mj", logoPath: "" },
  { label: "Veema", value: "mpubykld", logoPath: "" },
  { label: "Azure SQL Database", value: "2zbh9tdt", logoPath: "" },
  { label: "DBF", value: "jjz3fpnt", logoPath: "" },
  { label: "GPS", value: "qemewmxj", logoPath: "" },
  { label: "Make", value: "na13fbvt", logoPath: "" },
  { label: "PHP Unit", value: "ypmhq3jv", logoPath: "php-logo.svg" },
  { label: "SignalR", value: "artik0qp", logoPath: "" },
  { label: "VeraCrypt", value: "b6dnbsxr", logoPath: "" },
  { label: "Azure Storage", value: "so8m6rt4", logoPath: "" },
  { label: "DBT", value: "owddi2eq", logoPath: "" },
  { label: "GPUImage", value: "vakwyhpl", logoPath: "" },
  { label: "Man-in-the-Middle", value: "iv2fr27d", logoPath: "" },
  { label: "PHPMyAdmin", value: "cbxtfjju", logoPath: "php-logo.svg" },
  { label: "Silverlight", value: "laaz6zad", logoPath: "" },
  { label: "VersionOne", value: "sgtq8s1c", logoPath: "" },
  { label: "Azure Tables", value: "8ndquigl", logoPath: "" },
  { label: "Debian", value: "dgruxx1u", logoPath: "" },
  { label: "Gradle", value: "rexogkme", logoPath: "" },
  { label: "Management", value: "6vmudir7", logoPath: "" },
  { label: "Pika", value: "tjtfndvd", logoPath: "" },
  { label: "Sinatra", value: "fvv7oylp", logoPath: "" },
  { label: "Vertica", value: "aqjds8vt", logoPath: "" },
  { label: "Azure Web Role", value: "krz0envc", logoPath: "" },
  { label: "DelayedJob", value: "bafiogut", logoPath: "" },
  { label: "Grafana", value: "prpfxtff", logoPath: "" },
  { label: "Maniflod CF", value: "rbnih0kv", logoPath: "" },
  { label: "Ping", value: "v8afniws", logoPath: "" },
  { label: "Single Page Applications (SPA)", value: "t4bzmkf0", logoPath: "" },
  { label: "Vertx", value: "4h9xhenf", logoPath: "" },
  { label: "Azure Worker Role", value: "hmimzlcp", logoPath: "" },
  { label: "Delegates", value: "1aaaqilo", logoPath: "" },
  { label: "Grape", value: "wkbqayj1", logoPath: "" },
  { label: "Mantis", value: "zi10a5y1", logoPath: "" },
  { label: "Pingdom", value: "y9jgmuwi", logoPath: "" },
  { label: "Sinon", value: "o2v3gmle", logoPath: "" },
  { label: "Veryfi", value: "zpqvg4bw", logoPath: "" },
  { label: "B4Bug", value: "xr8ddzly", logoPath: "" },
  { label: "Delivery Management", value: "pjjrq2nv", logoPath: "" },
  { label: "Graph", value: "c1marst0", logoPath: "" },
  { label: "Mantle", value: "n2fmgfpa", logoPath: "" },
  { label: "pip", value: "bilhjfxx", logoPath: "" },
  { label: "SIP/RTP", value: "avkvdc7l", logoPath: "" },
  { label: "VHDL", value: "epls2cil", logoPath: "" },
  { label: "Babel", value: "kdqv8ef5", logoPath: "" },
  { label: "Dell KACE", value: "lbnipzse", logoPath: "" },
  { label: "GraphCMS", value: "0r18cynh", logoPath: "" },
  { label: "Manual Testing", value: "tkqwttd0", logoPath: "" },
  { label: "pipnev", value: "yrjuxe8x", logoPath: "" },
  { label: "Size Classes", value: "h7lbmv7s", logoPath: "" },
  { label: "Video Editing", value: "l7fuhrrx", logoPath: "" },
  { label: "Delphi", value: "9yhzoeq9", logoPath: "" },
  { label: "Graphic Design", value: "pa0awgid", logoPath: "" },
  { label: "Map", value: "wj1owch4", logoPath: "" },
  { label: "Pivotal Cloud Foundry", value: "huoki7a0", logoPath: "" },
  { label: "Sketch", value: "ymfvaq3a", logoPath: "" },
  { label: "Video Processing", value: "uvsfxgsj", logoPath: "" },
  { label: "Balsamiq", value: "kr4c6fgv", logoPath: "" },
  { label: "Delphi Code Gear", value: "51oeil6h", logoPath: "" },
  { label: "Graphite", value: "bhhvdbzx", logoPath: "" },
  { label: "MapKit", value: "ddahrjib", logoPath: "" },
  { label: "PL/SQL", value: "froo7rdh", logoPath: "" },
  { label: "Sketchup", value: "jbbfpfcm", logoPath: "" },
  { label: "Video Streaming", value: "mj6tzi8p", logoPath: "" },
  { label: "Bamboo", value: "eo2lr07s", logoPath: "" },
  { label: "Delphi.NET", value: "2p7eopvq", logoPath: "" },
  { label: "MapR", value: "dpqjky2d", logoPath: "" },
  { label: "Planning Technology Deployments", value: "ciqx7wq0", logoPath: "" },
  { label: "Sklearn", value: "ofhfqct7", logoPath: "" },
  { label: "Vidiator", value: "rk3n4elh", logoPath: "" },
  { label: "Banner designs", value: "lfx8mq5l", logoPath: "" },
  { label: "Demandware", value: "svhdsvoe", logoPath: "" },
  { label: "Grav CMS", value: "fmiulzai", logoPath: "" },
  { label: "Maps", value: "1q9rttig", logoPath: "" },
  { label: "Play", value: "bqqgav51", logoPath: "" },
  { label: "Sklearn Predictive", value: "fm6b5tfi", logoPath: "" },
  { label: "Vim", value: "fgu6b22q", logoPath: "" },
  { label: "Basecamp", value: "sua61epe", logoPath: "" },
  { label: "Dependency Injection", value: "xbtbvyps", logoPath: "" },
  { label: "Greenplum", value: "tetohmno", logoPath: "" },
  { label: "MariaDB", value: "xhzkp5ut", logoPath: "" },
  { label: "PlayerIO", value: "pp70pn19", logoPath: "" },
  { label: "Slack", value: "frfsjjjd", logoPath: "" },
  { label: "VIPER", value: "9hs8fydx", logoPath: "" },
  { label: "Bash", value: "3had32kx", logoPath: "" },
  { label: "Design Patterns", value: "z7oenerj", logoPath: "" },
  { label: "grep", value: "wc0ogf8z", logoPath: "" },
  { label: "Marionette.js", value: "ututhsq3", logoPath: "" },
  { label: "Plone", value: "ikcqo0bu", logoPath: "" },
  { label: "Slack API", value: "30fumb7b", logoPath: "" },
  { label: "Virtual Identity", value: "ztur2lnq", logoPath: "" },
  { label: "Bash Scripting", value: "vlcmff3s", logoPath: "" },
  { label: "Design Systems", value: "oj9fdvmp", logoPath: "" },
  { label: "Grinder", value: "jmp5nvak", logoPath: "" },
  { label: "Marketing", value: "sndeednx", logoPath: "" },
  { label: "PMI", value: "jewsx604", logoPath: "" },
  { label: "SlickGrid", value: "vivuuiah", logoPath: "" },
  { label: "Virtual Reality", value: "rtikabdb", logoPath: "" },
  { label: "Basic Dashboards", value: "zvcc7smu", logoPath: "" },
  { label: "Desktop App Design", value: "mqxow41k", logoPath: "" },
  { label: "Groovy", value: "l6gfnhbd", logoPath: "" },
  { label: "Marvel", value: "6wwxoafz", logoPath: "" },
  { label: "Poi", value: "9fkssbou", logoPath: "" },
  { label: "Slim", value: "skmzeih1", logoPath: "" },
  { label: "Virtual SVN", value: "zrwtanme", logoPath: "" },
];

export default skills;
