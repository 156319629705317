import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.03em;

  color: #1c274a;
  margin-bottom: 16px;
`;

const ShowMoreToggle = styled.div`
  border-radius: 6px;
  display: flex;
  padding: 4px 16px 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-left: auto;

  color: var(--notch-neutral-400, #9fa4b3);
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.42px;

  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: #105cf7;
  }
`;

const BioInitialMaxLength = 500;

const CandidateSummary: React.FC = (props) => {
  let [showAll, setShowAll] = useState(false);
  let bio = props.data.dataOverwrites.Bio;

  let bioLength = bio.length;
  let text = bio;
  let needsCrop = bioLength > BioInitialMaxLength;

  if (!needsCrop) {
    return <Container>{text}</Container>;
  }

  text = bio.slice(0, BioInitialMaxLength);

  return (
    <Container>
      <>
        {!showAll && <>{text}...</>}
        {showAll && <>{bio}</>}
      </>
      <>
        {!showAll && (
          <ShowMoreToggle onClick={() => setShowAll(true)}>
            Show More
          </ShowMoreToggle>
        )}
        {showAll && (
          <ShowMoreToggle onClick={() => setShowAll(false)}>
            Show less
          </ShowMoreToggle>
        )}
      </>
    </Container>
  );
};

export default CandidateSummary;
