import React from "react";
import styled from "styled-components";
import { FieldRenderProps } from "react-final-form";

type Props = FieldRenderProps<string, any> & { spacing: "fixed" | "full" };

const isSelected = (currentItem: string, currentSelection: Set<string>) => {
  return currentSelection.has(currentItem);
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100%;
  height: auto;
  min-height: 48px;
  gap: 8px;
`;

const ItemContainer = styled.div<{ size: any }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 24px;
  padding: 0 8px;
  border-radius: 6px;
  border: 1px solid #ededf0;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #9fa4b3;

  cursor: pointer;

  &.active {
    border: 1px solid #105cf7;
  }

  &:hover {
    border: 1px solid #105cf7;
  }
`;

const Label = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: -0.03em;
  color: #1c274a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ShortlistDeclineReasonsField: React.FC<Props> = (props) => {
  const set = new Set(props.input.value);

  return (
    <Container>
      {props.options.map((Item) => {
        return (
          <ItemContainer
            onClick={() => {
              if (set.has(Item.value)) {
                set.delete(Item.value);
                props.input.onChange(Array.from(set));
              } else {
                set.add(Item.value);
                props.input.onChange(Array.from(set));
              }
            }}
            className={isSelected(Item.value, set) ? "active" : ""}
          >
            <Label>{Item.label}</Label>
          </ItemContainer>
        );
      })}
    </Container>
  );
};

export default ShortlistDeclineReasonsField;
