import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 32px;

  @media (min-width: 1016px) {
    gap: 0;
    display: grid;
    grid-template-columns: 1fr 32px 320px;
    grid-template-areas: "mainColumn gap sideColumn";
  }

  @media (min-width: 1048px) {
    display: grid;
    grid-template-columns: 632px 1fr 32px 320px 1fr;
    grid-template-areas: "mainColumn mainColumn gap sideColumn sideColumn";
  }

  @media (min-width: 1280px) {
    display: grid;
    grid-template-columns: 1fr 32px 320px;
    grid-template-areas: "mainColumn gap sideColumn";
  }

  @media (min-width: 1312px) {
    display: grid;
    grid-template-columns: 632px 1fr 32px 320px 1fr;
    grid-template-areas: "mainColumn mainColumn gap sideColumn sideColumn";
  }
`;

export const MainColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  grid-area: mainColumn;
`;

export const SideColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  grid-area: sideColumn;
`;

const MainAndSideColumnsLayout: React.FC<React.PropsWithChildren> = (props) => {
  return <Container>{props.children}</Container>;
};

export default MainAndSideColumnsLayout;
