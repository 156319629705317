import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 12px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  width: max-content;

  border-radius: 6px;
`;

const Draft = styled(Container)`
  background: #fafafb;
  color: #6a7288;
`;

const New = styled(Container)`
  background: #f1e7fe;
  color: #6522f2;
`;

const NotViewed = styled(Container)`
  background: #f4fbfd;
  color: #1d7c93;
`;

const Interviewing = styled(Container)`
  background: #f8faff;
  color: #09348b;
`;

const NotHired = styled(Container)`
  background: #fafafb;
  color: #6a7288;
`;

const ShortlistCandidateStatus: React.FC = (props) => {
  switch (props.status) {
    case "Draft":
      return <Draft>Draft</Draft>;
    case "New":
      return <New>New</New>;
    case "Viewed":
      return <New>Viewed</New>;
    case "Not Viewed":
      return <NotViewed>Not Viewed</NotViewed>;
    case "Interviewing":
      return <Interviewing>Interviewing</Interviewing>;
    case "Not Interested":
      return <NotHired>Not Interested</NotHired>;

    default:
      return <Draft>-</Draft>;
  }
};

export default ShortlistCandidateStatus;
