import React from "react";
import styled from "styled-components";
import {
  BackButton,
  FormCard,
  FormGroupDynamic,
  FormLabel,
  FormSubtitle,
  FormTitle,
  PrimaryButton,
} from "src/Modules/Shared/Components/Temp/ApplicationFlowComponents";
import { Field, Form } from "react-final-form";
import TextInputField from "src/Modules/Shared/Components/Temp/TextInputField";
import SelectField from "src/Modules/Shared/Components/Temp/SelectField";
import BlockSelectField from "src/Modules/Shared/Components/Temp/BlockSelectField";
import YearlySalaryInputField from "src/Modules/Shared/Components/Temp/YearlySalaryInputField";
import axios from "axios";
import * as yup from "yup";
import PopupContainer from "src/Modules/Shared/Components/Temp/PopupContainer";

const Container = styled.div`
  max-width: 640px;
`;

const HalfSizeWidth = 286;

const ArrowRightIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  width: 20px;
  height: 20px;
`;

const ArrowLeftIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width: 20px;
  height: 20px;
`;

const ButtonGroup = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ButtonGroupSpace = styled.div`
  width: auto;
  height: 48px;
  margin-left: auto;
`;

const BlockOptionContainer = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.03em;
  color: #1c274a;

  span {
    color: #858b9d;
  }
`;

const FormSpacer = styled.div`
  display: block;
  width: 100%;
  height: 24px;
`;

const InfoIcon = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;
  margin-left: 4px;
  margin-bottom: 8px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const InfoPopup = styled.div`
  position: absolute;
  width: 260px;
  background: white;
  border: 1px solid #ededf0;
  box-shadow: 0px 12px 32px rgba(28, 39, 74, 0.08);
  border-radius: 6px;
  display: flex;
  flex-direction: column;

  font-family: Inter;
  font-size: 12px;
  padding: 16px;
  line-height: 1.5;

  color: #1c274a;
  bottom: 40px;
  left: -110px;
`;

type Props = {
  setStep: (step: number) => void;
  setData: any;
  data: any;
};

const extraInfoValues = ["customer", "talent", "referred", "partner", "other"];

const extraInfoLabel: any = {
  customer: "Name of Company",
  talent: "Name of Talent",
  referred: "Referrer",
  partner: "Notch partner",
  other: "Please share where you heard of us",
};

const formSchema = yup.object({
  job_type: yup.string().required(),
  Time_commitment_preferred: yup.string().required(),
  ready_to_work: yup.string().required(),
  search_status: yup.string().required(),
  yearly_salary: yup.number().required().min(1),
  findOut: yup.object(),
  extra: yup.string().when(["findOut"], {
    is: (value: any) => extraInfoValues.includes(value?.value),
    then: (schema: any) => schema.object().required(),
    otherwise: (schema: any) => schema.object().notRequired(),
  }),
});

const ApplicationFlowStepWorkPreference: React.FC<Props> = (props) => {
  return (
    <Container>
      <Form
        onSubmit={(values) => {
          axios
            .post("/api/customers/v1/application-flow", values)
            .then((response) => {
              props.setData(response.data);
            });
        }}
        initialValues={props.data}
        render={({ handleSubmit, values, submitting }) => (
          <form onSubmit={handleSubmit}>
            <FormTitle>Work Preferences</FormTitle>
            <FormSubtitle>
              Let us know exactly what you’re looking for so we can match the
              best remote jobs for you.
            </FormSubtitle>
            <FormCard>
              <FormGroupDynamic width={640}>
                <FormLabel>Types of jobs</FormLabel>
                <Field
                  name="job_type"
                  component={BlockSelectField}
                  spacing="fixed"
                  options={[
                    { label: "Contractor", value: "a" },
                    { label: "Employee", value: "b" },
                    { label: "Open to either", value: "c" },
                  ]}
                />
              </FormGroupDynamic>

              <FormGroupDynamic width={640}>
                <FormLabel>Time commitment preferred</FormLabel>
                <Field
                  name="Time_commitment_preferred"
                  component={BlockSelectField}
                  spacing="full"
                  options={[
                    {
                      label: (
                        <BlockOptionContainer>
                          Full-time <span>(40 hrs/ week)</span>
                        </BlockOptionContainer>
                      ),
                      value: "a",
                    },
                    {
                      label: (
                        <BlockOptionContainer>
                          Part-time <span>(20 hrs/ week)</span>
                        </BlockOptionContainer>
                      ),
                      value: "b",
                    },
                    {
                      label: (
                        <BlockOptionContainer>
                          Open to either
                        </BlockOptionContainer>
                      ),
                      value: "c",
                    },
                  ]}
                />
              </FormGroupDynamic>

              <FormGroupDynamic width={640}>
                <FormLabel>Your job search status</FormLabel>
                <Field
                  name="search_status"
                  component={BlockSelectField}
                  spacing="fixed"
                  options={[
                    { label: "Actively Looking", value: "a" },
                    { label: "Open to Offers", value: "b" },
                    { label: "Not Available", value: "c" },
                  ]}
                />
              </FormGroupDynamic>

              <FormGroupDynamic width={640}>
                <FormLabel>Once hired, ready to work in</FormLabel>
                <Field
                  name="ready_to_work"
                  component={BlockSelectField}
                  spacing="fixed"
                  options={[
                    { label: "Immediately", value: "a" },
                    { label: "1 week", value: "b" },
                    { label: "2+ week", value: "c" },
                    { label: "More than a month", value: "d" },
                  ]}
                />
              </FormGroupDynamic>

              <FormGroupDynamic width={270}>
                <FlexRow>
                  <FormLabel>Expected yearly salary</FormLabel>
                  <PopupContainer
                    popup={
                      <InfoPopup>
                        Let us know the salary you're looking for in your next
                        role. Make sure to be accurate as we will match roles
                        that meet or exceed your expectations. You can update
                        your salary at any time.
                      </InfoPopup>
                    }
                  >
                    <InfoIcon>
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.99935 17.3334C4.39685 17.3334 0.666016 13.6026 0.666016 9.00008C0.666016 4.39758 4.39685 0.666748 8.99935 0.666748C13.6018 0.666748 17.3327 4.39758 17.3327 9.00008C17.3327 13.6026 13.6018 17.3334 8.99935 17.3334ZM8.99935 15.6667C10.7675 15.6667 12.4632 14.9644 13.7134 13.7141C14.9636 12.4639 15.666 10.7682 15.666 9.00008C15.666 7.23197 14.9636 5.53628 13.7134 4.28604C12.4632 3.03579 10.7675 2.33341 8.99935 2.33341C7.23124 2.33341 5.53555 3.03579 4.2853 4.28604C3.03506 5.53628 2.33268 7.23197 2.33268 9.00008C2.33268 10.7682 3.03506 12.4639 4.2853 13.7141C5.53555 14.9644 7.23124 15.6667 8.99935 15.6667ZM8.16602 4.83341H9.83268V6.50008H8.16602V4.83341ZM8.16602 8.16675H9.83268V13.1667H8.16602V8.16675Z"
                          fill="#B8BCC7"
                        />
                      </svg>
                    </InfoIcon>
                  </PopupContainer>
                </FlexRow>
                <Field
                  name="yearly_salary"
                  component={YearlySalaryInputField}
                />
              </FormGroupDynamic>
            </FormCard>
            <FormSpacer />
            <FormCard>
              <FormGroupDynamic width={300}>
                <FormLabel>How did you find out about Notch</FormLabel>
                <Field
                  name="findOut"
                  component={SelectField}
                  options={[
                    { value: "customer", label: "Customer / Company" },
                    { value: "talent", label: "Talent / Community" },
                    { value: "referred", label: "Referred / Recommended" },
                    { value: "email", label: "Email invite" },
                    { value: "partner", label: "Notch partner" },
                    { value: "google", label: "Google" },
                    { value: "facebook", label: "Facebook" },
                    { value: "twitter", label: "Twitter" },
                    { value: "linkedin", label: "Linkedin" },
                    { value: "friend", label: "Friend / Word of mouth" },
                    { value: "none", label: "I hadn't heard of Notch before" },
                    { value: "other", label: "Other" },
                  ]}
                />
              </FormGroupDynamic>
              {extraInfoValues.includes(values.findOut?.value) && (
                <FormGroupDynamic width={300}>
                  <FormLabel>{extraInfoLabel[values.findOut.value]}</FormLabel>
                  <Field name="extra" component={TextInputField} />
                </FormGroupDynamic>
              )}
            </FormCard>

            <ButtonGroup>
              <BackButton
                onClick={(event) => {
                  axios
                    .post("/api/customers/v1/application-flow-back", values)
                    .then((response) => {
                      props.setData(response.data);
                    });
                  event.preventDefault();
                }}
              >
                <ArrowLeftIcon>
                  <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.02331 6.16664H14.1666V7.83331H4.02331L8.49331 12.3033L7.31498 13.4816L0.833313 6.99998L7.31498 0.518311L8.49331 1.69664L4.02331 6.16664Z"
                      fill="#105CF7"
                    />
                  </svg>
                </ArrowLeftIcon>
                Back
              </BackButton>
              <ButtonGroupSpace />
              <PrimaryButton
                type="submit"
                disabled={!formSchema.isValidSync(values)}
              >
                Continue{" "}
                <ArrowRightIcon>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.4767 6.16664L6.00668 1.69664L7.18501 0.518311L13.6667 6.99998L7.18501 13.4816L6.00668 12.3033L10.4767 7.83331H0.333344V6.16664H10.4767Z" />
                  </svg>
                </ArrowRightIcon>
              </PrimaryButton>
            </ButtonGroup>
          </form>
        )}
      />
    </Container>
  );
};

export default ApplicationFlowStepWorkPreference;
