import React from "react";
import styled from "styled-components";
import CandidateCardContainer from "src/Modules/Shared/Components/Shortlist/CandidateCard/CandidateCardContainer";
import {
  ProfileCard,
  UpperPart,
} from "src/Modules/Shared/Components/Shortlist/CandidateCard/CandidateCardParts";
import CandidateCardProfileCard from "src/Modules/Shared/Components/Shortlist/CandidateCard/CandidateCardProfileCard";
import CandidateDataSection from "src/Modules/Shared/Components/Shortlist/CandidateCard/CandidateDataSection";
import CandidateCardFooter from "src/Modules/Shared/Components/Shortlist/CandidateCard/CandidateCardFooter";

const Container = styled.div``;

const borderColor = "#EDEDF0";

const GrayLine = styled.div`
  border-top: 1px solid ${borderColor};
  width: 100%;
  margin: 16px 0;
`;

const AdminCandidateCard: React.FC = (props) => {
  return (
    <CandidateCardContainer>
      <UpperPart>
        <ProfileCard>
          <CandidateCardProfileCard {...props} />
        </ProfileCard>
        <CandidateDataSection {...props} />
      </UpperPart>
      <GrayLine />
      <CandidateCardFooter {...props} />
    </CandidateCardContainer>
  );
};

export default AdminCandidateCard;
